<template>
  <b-modal
    id="detailedInformationModal"
    v-model="modalVisible"
    size="lg"
    cancel-disabled
    ok-disabled
  >
    <b-nav
      slot="modal-header"
      v-b-scrollspy:detailed-info-body
      pills
    >
      <b-nav-item
        href="#publications"
        @click.prevent="scrollIntoView"
      >
        Scholarly Works
      </b-nav-item>
      <b-nav-item
        href="#projects"
        @click.prevent="scrollIntoView"
      >
        Projects
      </b-nav-item>
      <b-nav-item
        href="#patents"
        @click.prevent="scrollIntoView"
      >
        Patents
      </b-nav-item>
      <b-nav-item
        href="#clinicalTrials"
        @click.prevent="scrollIntoView"
      >
        Clinical Trials
      </b-nav-item>
      <b-nav-item
        href="#researchers"
        @click.prevent="scrollIntoView"
      >
        Researchers
      </b-nav-item>
    </b-nav>

    <div slot="modal-footer" />

    <div
      id="detailed-info-body"
      ref="detailedInfoContent"
      style="position: relative; height: 300px; overflow-y: scroll;"
    >
      <h5 id="publications">
        Exemplar Scholarly Works
      </h5>
      <p v-if="publications.length === 0">
        No scholarly works information to show, sorry.
      </p>
      <b-table
        v-else
        id="detailed-publications-table"
        :items="publications"
        :fields="publicationFields"
        sort-by="publication_date"
        :sort-desc="true"
        per-page="5"
      >
        <template
          v-slot:cell(publication_date)="data"
        >
          {{ Number(String(data.item.publication_date).substring(0, 4)) }}
        </template>
      </b-table>
      <h5 id="projects">
        High Profile Projects
      </h5>
      <p v-if="projects.length === 0">
        No projects information to show, sorry.
      </p>
      <b-table
        v-else
        id="detailed-projects-table"
        :items="projects"
        :fields="projectFields"
        sort-by="funding_aud"
        :sort-desc="true"
        per-page="5"
      >
        <template
          v-slot:cell(funding_aud)="data"
        >
          {{
            (data.item.funding_aud ? Number(data.item.funding_aud) : 'N/A')
              .toLocaleString(
                'en-AU',
                { style: 'currency', currency: 'AUD', minimumFractionDigits: 0 }
              )
          }}
        </template>
      </b-table>
      <h5 id="patents">
        Registered Patents
      </h5>
      <p v-if="patents.length === 0">
        No patents information to show, sorry.
      </p>
      <b-table
        v-else
        id="detailed-patents-table"
        :items="patents"
        :fields="patentFields"
        sort-by="publication_date"
        :sort-desc="true"
        per-page="5"
      >
        <template
          v-slot:cell(publication_date)="data"
        >
          {{ Number(String(data.item.publication_date).substring(0, 4)) }}
        </template>
      </b-table>
      <h5 id="clinicalTrials">
        Clinical Trials
      </h5>
      <p v-if="clinicalTrials.length === 0">
        No clinical trials information to show, sorry.
      </p>
      <b-table
        v-else
        id="detailed-clinicalTrials-table"
        :items="clinicalTrials"
        :fields="clinicalTrialsFields"
        sort-by="start_date"
        :sort-desc="true"
        per-page="5"
      >
        <template
          v-slot:cell(start_date)="data"
        >
          {{ Number(String(data.item.start_date).substring(0, 4)) }}
        </template>
      </b-table>
      <h5 id="researchers">
        Prominent Researchers
      </h5>
      <p v-if="researchers.length === 0">
        No researchers to show, sorry.
      </p>
      <b-table
        v-else
        id="detailed-researchers-table"
        :items="researchers"
        :fields="researcherFields"
        per-page="5"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DetailedInformationModal',

  components: {},

  data() {
    return {
      modalVisible: false,
      modalMode: 'capability',

      publications: [],
      projects: [],
      patents: [],
      clinicalTrials: [],
      researchers: [],

      publicationFields: [
        { key: 'title', label: 'Title' },
        { key: 'publication_date', label: 'Year', sortable: true },
      ],

      projectFields: [
        { key: 'title', label: 'Title' },
        { key: 'funding_aud', label: 'Funding' },
      ],

      patentFields: [
        { key: 'title', label: 'Title' },
        { key: 'publication_date', label: 'Year', sortable: true },
      ],

      clinicalTrialsFields: [
        { key: 'title', label: 'Title' },
        { key: 'start_date', label: 'Year', sortable: true },
      ],

      researcherFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'topic_research_activity_count', label: 'Click', sortable: true },
        { key: 'research_activity_count', label: 'Pubs', sortable: true },
        { key: 'total_research_activity_count', label: 'All', sortable: true },
      ],
    };
  },

  computed: {
    ...mapGetters('capability', {
      capabilityPublications: 'filteredPublications',
      capabilityProjects: 'filteredProjects',
      capabilityPatents: 'filteredPatents',
      capabilityClinicalTrials: 'filteredClinicalTrials',
      capabilityTopResearchers: 'filteredTopResearchers',
    }),

    ...mapGetters('topic', {
      topicPublications: 'filteredPublications',
      topicProjects: 'filteredProjects',
      topicPatents: 'filteredPatents',
      topicClinicalTrials: 'filteredClinicalTrials',
      topicTopResearchers: 'filteredTopResearchers',
    }),

    ...mapState('misc', [
      'capabilityPaneSelected',
      'topicPaneSelected',
      'detailedInformationOverlayVisible',
    ]),
  },

  watch: {
    capabilityPaneSelected(value) {
      if (value) {
        this.modalMode = 'capability';
      }
    },

    topicPaneSelected(value) {
      if (value) {
        this.modalMode = 'topic';
      }
    },
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.publications.length = 0;
      this.projects.length = 0;
      this.patents.length = 0;
      this.clinicalTrials.length = 0;
      this.researchers.length = 0;
    });

    this.$eventBus.$on('show-detailed-information-overlay', () => {
      if (this.modalMode === 'capability') {
        this.publications = this.capabilityPublications;
        this.projects = this.capabilityProjects;
        this.patents = this.capabilityPatents;
        this.clinicalTrials = this.capabilityClinicalTrials;
        this.researchers = this.capabilityTopResearchers;
      } else {
        this.publications = this.topicPublications;
        this.projects = this.topicProjects;
        this.patents = this.topicPatents;
        this.clinicalTrials = this.topicClinicalTrials;
        this.researchers = this.topicTopResearchers;
      }

      this.modalVisible = true;
    });

    this.$eventBus.$on('hide-detailed-information-overlay', () => {
      this.modalVisible = false;
    });
  },

  methods: {
    // Convenience method to scroll a heading into view
    // Not required for scrollspy to work
    scrollIntoView(evt) {
      const href = evt.target.getAttribute('href');
      const el = href ? document.querySelector(href) : null;
      if (el) {
        this.$refs.detailedInfoContent.scrollTop = el.offsetTop;
      }
    },
  },
};
</script>
