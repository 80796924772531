<template>
  <b-card
    id="topicOrganisations"
    no-body
  >
    <div
      v-show="topicTopOrganisations.length"
      class="overflow-auto"
    >
      <br>
      <b-pagination
        v-model="currentPage"
        :total-rows="topicTopOrganisations.length"
        :per-page="perPage"
        aria-controls="topic-researchers-table"
        align="center"
      />

      <p class="text-center">
        Top <b>{{ topicTopOrganisations.length }}</b> from total
        <b>{{ topicOrganisations.length }}</b> Organisations
      </p>
      <b-table
        id="topic-researchers-table"
        striped
        selectable
        select-mode="single"
        sort-by="topic_organisation_activity_count"
        :sort-desc="true"
        :items="topicTopOrganisations"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
        @row-clicked="highlightSelectedResearchActivities"
        @row-hovered="highlightHoveredResearchActivities"
        @row-unhovered="unhighlightHoveredResearchActivities"
      >
        <template v-slot:cell(add)="data">
          <b-btn
            variant="light"
            size="sm"
            @click.stop="addOrganisationToQuery(data.item)"
          >
            +
          </b-btn>
        </template>
        <template v-slot:cell(name)="data">
          {{ data.item.name }}
          <b-link
            v-if="data.item.links"
            class="right"
            :href="data.item.links[0]"
            target="_blank"
          >
            URL
          </b-link>
          <div
            v-if="data.item.types"
            class="department-text"
          >
            {{ data.item.types[0] }}
          </div>
        </template>
        <!--<template v-slot:cell(research_activity_count)="data">
          {{ data.item.research_activity_count }}
        </template>-->
      </b-table>
    </div>
  </b-card>
</template>

<style>
table th {
  display: table-cell;
}

.btn-sm {
  padding: 0.05rem 0.1rem;
}

.employment-status-text {
  font-size: 12px;
}

.department-text {
  font-size: 10px;
}

.right {
  float: right;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TopicOrganisations',

  data() {
    return {
      perPage: 15,
      currentPage: 1,
      fields: [
        { key: 'add', label: '', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'topic_organisation_activity_count', label: 'Click', sortable: true },
        { key: 'organisation_activity_count', label: 'Map', sortable: true },
        // { key: 'total_organisation_activity_count', label: 'All', sortable: true },
      ],
    };
  },

  computed: {
    ...mapState('topic', { topicOrganisations: 'organisations' }),
    ...mapGetters('topic', { topicTopOrganisations: 'filteredTopOrganisations' }),
  },

  methods: {

    addOrganisationToQuery(organisation) {
      const thing = { type: 'organisation', value: organisation.rcm_id, text: organisation.name };
      this.$eventBus.$emit('add-thing-to-query', thing, 'TopicOrganisations');
    },

    highlightSelectedResearchActivities(item) {
      this.$eventBus.$emit('organisation-selection', item.combinedRcmIds, item, false);
    },

    highlightHoveredResearchActivities(item) {
      this.$eventBus.$emit('organisation-hover', item.combinedRcmIds, item, false);
    },

    unhighlightHoveredResearchActivities() {
      this.$eventBus.$emit('organisation-unhover');
    },
  },
};
</script>
