<template>
  <b-card
    id="fieldsOfResearch"
    no-body
  >
    <h6
      slot="header"
      v-b-modal.forCodeFilterModal
      class="mb-0"
    >
      FoR Code Filter
      <b-form-checkbox
        id="fieldsOfResearchIndicator"
        v-model="selectedItems"
        switch
        style="float: right;"
        @change="handleManualSwitchToggle"
      />
    </h6>
    <b-modal
      id="forCodeFilterModal"
      size="lg"
      title="FoR Code Filter"
      ok-only
    >
      <DualListFilter
        id="for-code-filter-list"
        :options="options"
        @selected-items="handleSelectedItems"
      />
    </b-modal>
  </b-card>
</template>

<style>
#forCodeFilterList {
  height: 100px;
  overflow: auto;
}
</style>

<script>
import axios from 'axios';
import DualListFilter from '@/components/common/DualListFilter.vue';
import { mapState } from 'vuex';

export default {
  name: 'FoRCodeFilter',

  components: {
    DualListFilter,
  },

  data() {
    return {
      options: {
        label: 'Code Search',
        inputOptions: { uppercase: false, isRequred: false },
        buttonOptions: { textLeft: 'Add All', textRight: 'Remove All' },
        resizeBox: 'md',
        items: [],
        colorItems: '#1E90FF',
        selectedItems: [],
        forcodesParameters: new Map(),
        leftDisplayField: 'text',
        rightDisplayField: 'text',
        leftSortField: 'level',
        rightSortField: 'level',
        searchAttribute: 'text',
        displayLimit: 10,
      },
      selectedItems: false,
    };
  },

  computed: {
    ...mapState('user', ['apiAuthenticated']),
  },

  mounted() {
    this.populateData();
  },

  methods: {
    populateData() {
      // Wait until we're authenticated
      if (this.apiAuthenticated) {
        // This is a no-op until we need to support parameters here
        const parameters = [];
        const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
        const url = `${process.env.RCM_API}/api/v1/data/forcodes${paramString}`;

        axios
          .get(url, { withCredentials: true })
          .then((response) => { this.options.items = response.data.forcodes; })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      } else {
        setTimeout(this.populateData, 1000);
      }
    },

    handleManualSwitchToggle() {
      this.$nextTick(() => {
        this.selectedItems = this.options.selectedItems.length > 0;
      });
    },

    handleSelectedItems(selected) {
      let item;

      if (selected.length > 0) {
        item = {
          comparison: 'not_contains',
          items: selected.map((i) => (i.text)),
        };
        this.selectedItems = true;
      } else {
        item = null;
        this.selectedItems = false;
      }

      this.$eventBus.$emit(
        'update-filter',
        {
          filter: 'for_codes_negative',
          action: 'add',
          item,
        },
      );
    },
  },
};
</script>
