import Vue from 'vue';
import Vuex from 'vuex';

import query from './modules/query';
import result from './modules/result';
import map from './modules/map';
import capability from './modules/capability';
import topic from './modules/topic';
import request from './modules/request';
import misc from './modules/misc';
import user from './modules/user';
import ui from './modules/ui';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    query,
    result,
    map,
    capability,
    topic,
    request,
    misc,
    user,
    ui,
  },

  actions: {
    reset(context) {
      context.commit('topic/reset');
      context.commit('capability/reset');
      context.commit('map/reset');
      context.commit('result/reset');
      context.commit('request/reset');
      context.commit('query/reset');
      context.commit('misc/reset');
      context.commit('ui/reset');

      // User is ignored as this would 'log the user out'
      // context.commit('user/reset');
    },
  },

  strict: debug,
});

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept([
    './modules/query',
    './modules/result',
    './modules/capability',
    './modules/topic',
    './modules/request',
    './modules/misc',
    './modules/user',
    './modules/ui',
  ], () => {
    store.hotUpdate({
      modules: {
        query,
        result,
        map,
        capability,
        topic,
        request,
        misc,
        user,
        ui,
      },
    });
  });
}

export default store;
