<template>
  <div v-show="topicLabel">
    <div class="topic-title">
      <b>{{ topicLabel.text }} <span
        v-if="topicLabel.df !== -1"
        class="topic-count"
      > ({{ topicLabel.df }})</span> </b>
      <b-button-group>
        <b-button
          type="submit"
          variant="light"
          :disabled="topicLabelClicked"
          @click="onSelectTopic('more')"
        >
          +
        </b-button>
      </b-button-group>
    </div>
    <div>
      <md-chips
        v-model="labels"
        md-static
        @md-click="onAddSubtopic"
      >
        <template
          slot="md-chip"
          slot-scope="{ chip }"
        >
          <font-awesome-icon :icon="keywordIcon" />&nbsp;
          {{ chip.text }}&nbsp;
          <font-awesome-icon
            :icon="documentIcon"
            class="document-icon"
          />
          <span class="topic-count">{{ chip.df }}</span>
          <span v-show="chip.count">
            <font-awesome-icon
              :icon="newResearchActivityIcon"
              class="new-document-icon"
            />
            <span class="topic-count">{{ chip.count }}</span>
          </span>
        </template>
      </md-chips>
    </div>
  </div>
</template>

<style>
.topic-title {
  padding: 5px;
}
.topic-count{
  color: rgba(0, 0, 0, 0.5);
}
.document-icon{
  color: rgb(64, 116, 178);
  font-size: smaller;
}
.new-document-icon{
  color: rgb(238, 136, 137);
  font-size: smaller;
}
</style>

<script>
import axios from 'axios';

import {
  faEdit, faFileAlt, faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'TopicLabels',

  props: {
    labelCluster: {
      type: Object,
      default: () => null,
    },
    latestQuery: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      topicLabel: {},
      labels: [],

      keywordIcon: faEdit,
      documentIcon: faFileAlt,
      newResearchActivityIcon: faPlusCircle,

      topicLabelClicked: false,
    };
  },

  watch: {
    labelCluster(newLabelCluster) {
      this.showLabels(newLabelCluster);
      this.topicLabelClicked = false;
    },

    latestQuery() {
      this.showLabels(this.labelCluster);
    },
  },

  mounted() {
    this.showLabels(this.labelCluster);
    this.topicLabelClicked = false;
  },

  methods: {
    showLabels(newLabelCluster) {
      this.topicLabel = newLabelCluster.exemplar;
      this.labels = [];
      // Sorting labels based on document frequency
      const allLabels = newLabelCluster.labels.sort((a, b) => b.df - a.df);
      // Show top 10 labels
      const maxIndex = Math.min(allLabels.length, 10);
      this.labels = allLabels.slice(0, maxIndex);
      // If it is not labled as 'Unclustered' that is, df=-1
      if (newLabelCluster.exemplar.df !== -1) {
        // Add topic label to the list of sub-topics
        this.labels.unshift(newLabelCluster.exemplar);
      }

      // Getting the number of new researchActivities each label can add to current query
      this.getLabelCounts(this.labels);
    },

    getLabelCounts(labels) {
      const parameters = [];
      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/thingcount${paramString}`;

      labels.forEach((label) => {
        const thing = {
          type: 'keyword',
          value: label.text,
        };

        axios
          .post(url, { thing, query: this.latestQuery }, { withCredentials: true })
          .then((response) => {
            const thingCount = response.data.count;
            this.$set(label, 'count', thingCount);
          })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      });
    },

    onSelectTopic(action) {
      const topicData = {
        action,
        labels: [this.topicLabel.text],
        docs: null,
      };

      if (!this.isKeywordInQuery(topicData.labels[0])) {
        this.$eventBus.$emit('topic-filter', topicData);
      }

      this.topicLabelClicked = true;
    },

    onAddSubtopic(selectedChip) {
      const topicData = {
        action: 'more',
        labels: [selectedChip.text],
        docs: null,
      };

      if (!this.isKeywordInQuery(topicData.labels[0])) {
        this.$eventBus.$emit('topic-filter', topicData);
      }
    },

    isKeywordInQuery(keyword) {
      return this.latestQuery.groups.some(
        (group) => group.items.some((item) => item.text === keyword),
      );
    },
  },
};
</script>
