/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import _ from 'lodash';

const defaultQueryFilters = {
  researchers: {
    name: {
      map: 'uom_researcher_ids',
      capability: 'rcm_id',
    },
    type: 'client',
    item: null,
  },
  organisations: {
    name: {
      map: 'organisation_ids',
      capability: 'rcm_id',
    },
    type: 'client',
    item: null,
  },
  for_codes_positive: {
    name: {
      map: 'for_code_ids',
      capability: 'category_for',
    },
    type: 'client',
    item: null,
  },
  for_codes_negative: {
    name: {
      map: 'for_code_ids',
      capability: 'category_for',
    },
    type: 'client',
    item: null,
  },
  departments: {
    name: null,
    type: 'client',
    item: null,
  },
  currentuom: {
    name: {
      map: 'is_current_uom',
      capability: 'is_current_uom',
    },
    type: 'client',
    item: true,
  },
  activityType: {
    name: {
      map: 'doc_type',
      capability: 'doc_type',
    },
    type: 'client',
    item: null,
  },
  timerange: {
    name: {
      map: 'date',
      capability: 'publication_date',
    },
    type: 'client',
    item: null,
  },
  researcherCount: {
    name: {
      map: 'total_researchers',
      capability: 'total_researchers',
    },
    type: 'client',
    item: null,
  },
  research_activity_ids: {
    name: {
      map: 'research_activity_ids',
      capability: 'research_activity_ids',
    },
    type: 'server',
    item: null,
  },
};

const defaultQueryParameters = {
  lingo4g: {
    overlay: {},
  },
};

// initial state
const state = {
  queryGroups: [],
  queryFilters: _.cloneDeep(defaultQueryFilters),
  queryParameters: _.cloneDeep(defaultQueryParameters),

  combineResearchActivityCountsCache: [],

  previousQuery: null,
};

// getters
const getters = {
  query: (state) => ({
    groups: state.queryGroups,
    filters: state.queryFilters,
    parameters: state.queryParameters,
  }),
  queryWithoutResearchActivityIds: (state, queryGetters) => ({
    groups: queryGetters.queryGroupsWithoutResearchActivityIds,
    filters: state.queryFilters,
    parameters: state.queryParameters,
  }),

  queryGroups: (state) => (state.queryGroups),
  queryGroupsWithoutResearchActivityIds: (state) => {
    const queryGroupsWithoutResearchActivityIds = [];

    state.queryGroups.forEach((group) => {
      const groupWithoutResearchActivityIds = { ...group };
      delete groupWithoutResearchActivityIds.researchActivityIds;
      queryGroupsWithoutResearchActivityIds.push(groupWithoutResearchActivityIds);
    });

    return queryGroupsWithoutResearchActivityIds;
  },

  queryFilters: (state) => (state.queryFilters),
  queryFilterItem: (state) => (name) => (state.queryFilters[name].item),

  queryParameters: (state) => (state.queryParameters),

  queryGroup: (state) => (index) => (state.queryGroups[index]),
  queryGroupItems: (state) => (index) => _.cloneDeep(state.queryGroups[index].items),

  hasNewQueryGroupItems: (state) => (index) => state.queryGroups[index].items.some((item) => item.status === 'new'),

  // eslint-disable-next-line max-len
  groupResearchActivitiesCount: (state) => (index) => (state.queryGroups[index].researchActivityIds.length),

  combineResearchActivityCounts: (state) => (groupIndex) => {
    let researchActivityIds = new Set();

    if (state.combineResearchActivityCountsCache[groupIndex] !== undefined) {
      return state.combineResearchActivityCountsCache[groupIndex];
    }

    state.queryGroups
      .slice(0, groupIndex)
      .forEach((group, index) => {
        if (index === 0) {
          group.researchActivityIds.forEach((id) => researchActivityIds.add(id));
        } else if (group.operator === 'OR') {
          group.researchActivityIds.forEach((id) => researchActivityIds.add(id));
        } else if (group.operator === 'AND') {
          researchActivityIds = new Set(
            group.researchActivityIds.filter((id) => researchActivityIds.has(id)),
          );
        } else if (group.operator === 'NOT') {
          group.researchActivityIds.forEach((id) => researchActivityIds.delete(id));
        } else {
          // eslint-disable-next-line no-console
          console.log(`ERROR: unsupported operator '${group.operator}'`);
        }
      });

    state.combineResearchActivityCountsCache[groupIndex] = researchActivityIds;

    return researchActivityIds;
  },

  // The actual excluded research activities can be a subset of the list from "See less"
  // This happens when the query group is modifed after "See less" has been clicked
  excludedResearchActivityIds: (state, queryGetters) => {
    const excludedActivityIds = [];
    const ativityIds = queryGetters.combineResearchActivityCounts(state.queryGroups.length);

    // All research activities ids that have been sent from "See Less"
    const allExcludingResearchActivities = state.queryFilters.research_activity_ids.item;

    if (allExcludingResearchActivities) {
      allExcludingResearchActivities.items.map(Number).forEach((id) => {
        if (ativityIds.has(id)) {
          excludedActivityIds.push(id);
        }
      });
    }

    return excludedActivityIds;
  },

  // the unique list of rcm_id, contains both publication and project
  totalResearchActivityIds: (state, queryGetters) => {
    const activityIds = queryGetters.combineResearchActivityCounts(state.queryGroups.length);

    queryGetters.excludedResearchActivityIds.forEach((id) => activityIds.delete(id));

    return activityIds;
  },

  totalResearchActivitiesCount: (state, queryGetters) => queryGetters
    .totalResearchActivityIds
    .size,

  distinctResearchActivitiesCount: (state, queryGetters) => (groupIndex) => {
    // Find all researchActivities 'previous' to our group
    const researchActivityIds = queryGetters.combineResearchActivityCounts(groupIndex);

    const thisGroup = state.queryGroups[groupIndex];

    if (thisGroup.operator === 'OR') {
      return thisGroup.researchActivityIds.filter((id) => !researchActivityIds.has(id)).length;
    }

    if (thisGroup.operator === 'AND') {
      const intersection = thisGroup.researchActivityIds.filter(
        (id) => researchActivityIds.has(id),
      );
      return intersection.length;
    }

    if (thisGroup.operator === 'NOT') {
      const intersection = thisGroup.researchActivityIds.filter(
        (id) => researchActivityIds.has(id),
      );
      return intersection.length * -1;
    }

    // eslint-disable-next-line no-console
    console.log(`ERROR: unsupported operator '${thisGroup.operator}'`);
    return 0;
  },

  removingResearchActivitiesCount: (state, queryGetters) => (groupIndex) => {
    const thisGroup = state.queryGroups[groupIndex];

    if (thisGroup.operator === 'OR') {
      return 0;
    }

    const thisGroupIds = new Set(thisGroup.researchActivityIds);
    const researchActivityIds = queryGetters.combineResearchActivityCounts(groupIndex);

    if (thisGroup.operator === 'AND') {
      return [...researchActivityIds].filter((id) => !thisGroupIds.has(id)).length;
    }

    if (thisGroup.operator === 'NOT') {
      return [...researchActivityIds].filter((id) => thisGroupIds.has(id)).length;
    }

    // eslint-disable-next-line no-console
    console.log(`ERROR: unsupported operator '${thisGroup.operator}'`);
    return 0;
  },

  newQueryItemCount: (state) => {
    let count = 0;

    state.queryGroups.forEach((group) => {
      group.items.forEach((item) => {
        if (item.status === 'new' || typeof item.status === 'undefined') {
          count += 1;
        }
      });
    });

    return count;
  },

  previousQuery: (state) => (state.previousQuery),
  previousQuerySet: (state) => (state.previousQuery !== null),

  clientMapQueryFilters: (state) => {
    const filters = new Map();

    _.values(state.queryFilters).forEach((filter) => {
      if (filter.item !== null && filter.name !== null && filter.type === 'client') {
        if (filters.has(filter.name.map)) {
          filters.get(filter.name.map).push(filter.item);
        } else {
          filters.set(filter.name.map, [filter.item]);
        }
      }
    });

    return filters;
  },

  clientCapabilityQueryFilters: (state) => {
    const filters = new Map();

    _.values(state.queryFilters).forEach((filter) => {
      if (filter.item !== null && filter.name !== null && filter.type === 'client') {
        if (filters.has(filter.name.capability)) {
          filters.get(filter.name.capability).push(filter.item);
        } else {
          filters.set(filter.name.capability, [filter.item]);
        }
      }
    });

    return filters;
  },
};

// actions
const actions = {
  setRecursiveParameterValues({ commit, state }, parameters) {
    // Create the recursive function
    const deepMerge = (params, objLevel, keyPath) => {
      Object.entries(params).forEach(([key, value]) => {
        const thisKeyPath = keyPath.concat([key]);

        if (typeof value === 'object' && value.constructor === Object) {
          if (!_.has(objLevel, key)) {
            commit('setParameterValue', { key, value, keyPath: thisKeyPath });
          }

          deepMerge(value, objLevel[key], thisKeyPath);
        } else {
          commit('setParameterValue', { key, value, keyPath: thisKeyPath });
        }
      });
    };

    // Invoke the recursive function
    deepMerge(parameters, state.queryParameters, []);
  },

  setPreviousQuery(context) {
    context.commit('setPreviousQuery', { previousQuery: context.getters.query });
  },
};

// mutations
const mutations = {
  addGroup(state, group) {
    state.queryGroups.push(group);
  },

  deleteGroup(state, index) {
    state.queryGroups.splice(index, 1);

    // Invalidate the cache of everything after the index we changed
    state.combineResearchActivityCountsCache.splice(index);
  },

  deleteGroupItem(state, payload) {
    state.queryGroups[payload.groupIndex].items.splice(payload.itemIndex, 1);

    // Invalidate the cache of everything after the index we changed
    state.combineResearchActivityCountsCache.splice(payload.groupIndex);
  },

  spliceGroupItem(state, payload) {
    state.queryGroups[payload.groupIndex].items.splice(payload.itemIndex, 1, payload.item);

    // Invalidate the cache of everything after the index we changed
    state.combineResearchActivityCountsCache.splice(payload.groupIndex);
  },

  setAllQueryGroupsItemStatus(state, status) {
    state.queryGroups.forEach((group) => {
      // eslint-disable-next-line no-param-reassign
      group.items.forEach((item) => { item.status = status; });
    });
  },

  setQueryGroupPublicationIds(state, payload) {
    state.queryGroups[payload.index].researchActivityIds = payload.researchActivityIds;

    // Invalidate the cache of everything after the index we changed
    state.combineResearchActivityCountsCache.splice(payload.index);
  },

  updateFilter(state, filter) {
    state.queryFilters[filter.filter].item = filter.item;
  },

  overwriteQueryFilters(state, queryFilters) {
    state.queryFilters = _.cloneDeep(queryFilters);
  },

  overwriteQueryParameters(state, queryParameters) {
    state.queryParameters = _.cloneDeep(queryParameters);
  },

  setParameterValue(state, payload) {
    let correctMapLevel = state.queryParameters;

    payload.keyPath.forEach((keyLevel) => {
      correctMapLevel = correctMapLevel[keyLevel];
    });

    correctMapLevel[payload.key] = payload.value;
  },

  setPreviousQuery(state, payload) {
    state.previousQuery = payload.previousQuery;
  },

  setGroupRowOperator(state, payload) {
    state.queryGroups[payload.groupIndex].operator = payload.operator;

    // Invalidate the cache of everything after the index we changed
    state.combineResearchActivityCountsCache.splice(payload.groupIndex);
  },

  reset(state) {
    state.queryGroups = [];
    state.queryFilters = _.cloneDeep(defaultQueryFilters);
    state.queryParameters = _.cloneDeep(defaultQueryParameters);

    state.combineResearchActivityCountsCache = [];

    state.previousQuery = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
