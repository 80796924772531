<template>
  <CollapsableCard
    id="activityTypes"
    :visible="!!researchActivities.length"
    header="Research Activity Types"
  >
    <div v-show="researchActivities.length">
      <b-form-select
        v-model="selectedItem"
        :options="options"
        size="sm"
        @change="updateFilter"
      />
    </div>
  </CollapsableCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CollapsableCard from '@/components/common/CollapsableCard.vue';

export default {
  name: 'ResearchActivityTypeFilter',
  components: {
    CollapsableCard,
  },

  data() {
    return {
      options: [
        { text: 'All', value: null },
        { text: 'Scholarly Works', value: 'publication' },
        { text: 'Projects', value: 'project' },
        { text: 'Patents', value: 'patent' },
        { text: 'Clinical Trials', value: 'clinical_trial' },
      ],
      selectedItem: null,
    };
  },

  computed: {
    ...mapState('capability', ['researchActivities']),
    ...mapGetters('query', ['queryFilterItem']),
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.selectedItem = null;
    });

    // Set the exiting filter if it's saved
    const existingFilter = this.queryFilterItem('activityType');

    if (existingFilter !== null) {
      this.selectedItem = existingFilter;
    }
  },

  methods: {
    updateFilter() {
      let item;

      if (this.selectedItem !== null) {
        item = this.selectedItem;
      } else {
        item = null;
      }

      // Deselect topic as it may disapper after this update
      this.$eventBus.$emit('topic-deselection');
      this.$eventBus.$emit(
        'update-filter',
        {
          filter: 'activityType',
          action: 'add',
          item,
        },
      );
    },
  },
};
</script>
