<template>
  <b-card id="capabilityInsights">
    <div v-show="!numActivities">
      <font-awesome-icon
        :icon="spinnerIcon"
        style="color: #4074B2;"
        class="fa-2x fa-refresh fa-spin"
      />
    </div>
    <div v-show="numActivities">
      <h6 v-show="numResearchers">
        <font-awesome-icon
          :icon="researcherIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numResearchers }}</span>&nbsp;
        <span class="brag-text">Researchers</span>
      </h6>
      <h6 v-show="numPublications">
        <font-awesome-icon
          :icon="publicationIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numPublications }}</span>&nbsp;
        <span class="brag-text">Scholarly Works</span>
      </h6>
      <h6 v-show="numCitations && numCitations!=='0'">
        <font-awesome-icon
          :icon="citationIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numCitations }}</span>&nbsp;
        <span class="brag-text">Citations</span>
      </h6>
      <h6 v-show="numPolicies && numPolicies!=='0'">
        <font-awesome-icon
          :icon="publicationIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numPolicies }}</span>&nbsp;
        <span class="brag-text">Policies</span>
      </h6>
      <h6 v-show="numPatents">
        <br>
        <font-awesome-icon
          :icon="patentIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numPatents }}</span>&nbsp;
        <span class="brag-text">Patents</span>
      </h6>
      <h6 v-show="numClinicalTrials">
        <br>
        <font-awesome-icon
          :icon="clinicalTrialIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numClinicalTrials }}</span>&nbsp;
        <span class="brag-text">Clinical Trials</span>
      </h6>
      <div v-show="numProjects">
        <br>
        <h6>
          <font-awesome-icon
            :icon="projectCountIcon"
            style="color: #4074B2;"
          />&nbsp;
          <span class="brag-number">{{ numProjects }}</span>&nbsp;
          <span class="brag-text">Projects*</span>
        </h6>
        <h6 v-show="funding !== '$0'">
          <font-awesome-icon
            :icon="projectAmountIcon"
            style="color: #4074B2;"
          />&nbsp;
          <span class="brag-number">{{ funding }}</span>&nbsp;
          <span class="brag-text">Funding (AUD)*</span>
        </h6>
        <div style="font-size: 0.75rem;">
          * Approximation and from a internal & third party sources
        </div>
      </div>
    </div>
    <br>
  </b-card>
</template>

<style>
.brag-number {
  /* color: #094183; */
  font-size: 0.9em;
  font-weight: bolder;
}

.brag-text {
  /* color: #4074B2; */
  font-size: 0.9em;
  /* font-weight: bold; */
}

.inline-title {
  display: inline-block;
}

.horizontal-padded {
  padding: 0px 20px 0px 20px
}
</style>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import {
  faFileAlt,
  faUser,
  faComments,
  faMoneyCheckAlt,
  faHandHoldingUsd,
  faCertificate,
  faVial,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CapabilityInsights',

  data() {
    return {
      numResearchers: 0,
      numActivities: 0,

      numPublications: 0,
      numCitations: 0,
      numPolicies: 0,

      numProjects: 0,
      funding: 0,

      numPatents: 0,
      numClinicalTrials: 0,

      researcherIcon: faUser,
      publicationIcon: faFileAlt,
      citationIcon: faComments,
      projectCountIcon: faMoneyCheckAlt,
      projectAmountIcon: faHandHoldingUsd,
      patentIcon: faCertificate,
      clinicalTrialIcon: faVial,
      spinnerIcon: faSpinner,
    };
  },

  computed: {
    ...mapGetters('capability', {
      capabilityResearchActivities: 'filteredResearchActivities',
      capabilityPublications: 'filteredPublications',
      capabilityProjectsSummary: 'filteredProjectsSummary',
      capabilityPatents: 'filteredPatents',
      capabilityClinicalTrials: 'filteredClinicalTrials',
      capabilityResearchers: 'filteredResearchers',
      capabilityOrganisations: 'filteredOrganisations',
    }),
  },

  watch: {
    capabilityResearchActivities() { this.updateResearchActivitySummary(); },
    capabilityPublications() { this.updatePublicationsSummary(); },
    capabilityProjectsSummary() { this.updateProjectsSummary(); },
    capabilityPatents() { this.updatePatentsSummary(); },
    capabilityClinicalTrials() { this.updateClinicalTrialsSummary(); },
    capabilityResearchers() { this.updateResearchersSummary(); },
  },

  methods: {
    updateResearchActivitySummary() {
      this.numActivities = this.capabilityResearchActivities.length;
    },

    updatePublicationsSummary() {
      this.numPublications = this.capabilityPublications.length;

      const rawCitationCount = this.capabilityPublications
        .filter((publication) => _.has(publication, 'citations'))
        .reduce((count, publication) => count + publication.citations, 0);

      this.numCitations = Number(rawCitationCount).toLocaleString('en-AU');

      const rawPolicyCount = this.capabilityPublications
        .filter((publication) => _.has(publication, 'policy_ids'))
        .reduce((count, publication) => count + (publication.policy_ids).length, 0);

      this.numPolicies = Number(rawPolicyCount).toLocaleString('en-AU');
    },

    updateResearchersSummary() {
      this.numResearchers = this.capabilityResearchers.length;
    },

    updateProjectsSummary() {
      this.numProjects = this.capabilityProjectsSummary.numProjects;
      this.funding = '$'.concat(this.capabilityProjectsSummary.funding.toLocaleString('en-AU'));
    },

    updatePatentsSummary() {
      this.numPatents = this.capabilityPatents.length;
    },

    updateClinicalTrialsSummary() {
      this.numClinicalTrials = this.capabilityClinicalTrials.length;
    },
  },
};
</script>
