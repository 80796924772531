<template>
  <b-card
    id="currentUoMFilter"
    no-body
  >
    <h6
      slot="header"
      class="mb-0"
    >
      Current UoM Affiliation
      <b-form-checkbox
        id="currentuom"
        switch
        :checked="checked"
        style="float: right;"
        @change="clickAction"
      />
    </h6>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CurrentUoMFilter',

  computed: {
    ...mapState('query', {
      checked: (state) => state.queryFilters.currentuom.item,
    }),
  },

  methods: {
    clickAction(item) {
      this.$eventBus.$emit('update-filter', {
        filter: 'currentuom',
        action: 'add',
        item: item ? true : null,
      });
    },
  },
};
</script>
