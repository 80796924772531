<template>
  <CollapsableCard
    id="DebugParameters"
    :visible="false"
    header="Settings"
  >
    <b-button v-b-modal.debugModal>
      Open Debug Panel
    </b-button>
    <b-modal
      id="debugModal"
      size="lg"
      title="Debug Panel"
      ok-only
    >
      <b-form-group label="Analysis Request Flags">
        <b-form-checkbox
          v-for="parameter in requestFlagParameters"
          :key="parameter.value"
          v-model="selectedFlagParameters"
          :value="parameter.value"
          plain
          @change="(i) => {emitKeyBoolean(i, parameter.value)}"
        >
          {{ parameter.text }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Analysis Request Overlay">
        JSON Initial Overlay:
        <b-form-textarea
          id="analysisOverlayText"
          v-model="analysisOverlayText"
          size="sm"
          rows="3"
          max-rows="6"
          trim
          :state="validJson(analysisOverlayText)"
          @input="updateAnalysisOverlayText"
        />

        Thread Count:
        <b-form-select
          v-model="analysisOverlayThreadSelected"
          :options="analysisOverlayThreadOptions"
          @change="updateAnalysisOverlayThread"
        />

        Min ResearchActivity Labels:
        <b-form-select
          v-model="analysisOverlayMinResearchActivityLabelsSelected"
          :options="analysisOverlayMinResearchActivityLabelsOptions"
          @change="updateAnalysisOverlayMinResearchActivityLabels"
        />

        Max Labels:
        <b-form-select
          v-model="analysisOverlayMaxLabelsSelected"
          :options="analysisOverlayMaxLabelsOptions"
          @change="updateAnalysisOverlayMaxLabels"
        />
      </b-form-group>

      <b-form-group label="Suggested Keywords Mode">
        <b-form-radio-group
          id="suggestedThingsKeywordMode"
          v-model="selectedThingsKeywordMode"
          buttons
          button-variant="outline-primary"
          :options="suggestedThingsKeywordModes"
          @input="emitKeyValue('suggested-things-parameters', 'keywordMode', selectedMode)"
        />
      </b-form-group>

      <b-form-group label="Latest Lingo4G Query">
        <b-textarea
          v-model="latestLingo4GQuery"
          rows="2"
          max-rows="10"
          readonly
        />
      </b-form-group>
    </b-modal>
  </CollapsableCard>
</template>

<script>
import { mapState } from 'vuex';
import CollapsableCard from '@/components/common/CollapsableCard.vue';

export default {
  name: 'DebugParameters',

  components: {
    CollapsableCard,
  },

  data() {
    return {
      requestFlagParameters: [
        { text: 'Keyword Variations', value: 'variations' },
        { text: 'Keyword Expansion', value: 'expansion' },
      ],
      selectedFlagParameters: ['variations', 'expansion'],

      suggestedThingsKeywordModes: [
        { text: 'Gensim', value: 'gensim' },
        { text: 'ConceptNet', value: 'conceptnet', disabled: true },
        { text: 'Lingo4G', value: 'lingo4g', disabled: true },
      ],
      selectedThingsKeywordMode: 'gensim',

      analysisOverlayText: '',

      analysisOverlayThreadOptions: [
        { value: 'auto', text: 'Default' },
        { value: '8', text: '8 Threads' },
        { value: '6', text: '6 Threads' },
        { value: '4', text: '4 Threads' },
        { value: '2', text: '2 Threads' },
      ],
      analysisOverlayThreadSelected: 'auto',

      analysisOverlayMaxLabelsOptions: [
        { value: '0.05', text: '5%' },
        { value: '0.1', text: '10%' },
        { value: '0.15', text: '15%' },
        { value: '0.2', text: '20%' },
        { value: '0.25', text: '25%' },
      ],
      analysisOverlayMaxLabelsSelected: '0.2',

      analysisOverlayMinResearchActivityLabelsOptions: [
        { value: '', text: 'Default' },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
      ],
      analysisOverlayMinResearchActivityLabelsSelected: '',
    };
  },

  computed: {
    ...mapState('map', ['result']),

    latestLingo4GQuery() {
      if (this.result === null) {
        return 'No requests have been run yet';
      }

      const l4gResponse = this.result.rcm_meta.request_data;

      if (l4gResponse.scope.selector.type === 'byQuery') {
        const lingo4gQuery = l4gResponse.scope.selector.query;

        // Send Lingo4G query to Google Analytics
        this.$ga.event({
          eventCategory: 'Lingo4G Query',
          eventAction: 'Get Requested Lingo4G Query',
          eventLabel: JSON.stringify(lingo4gQuery),
          eventValue: 1,
        });
        return lingo4gQuery;
      }

      return 'Unsupported Lingo4G query type';
    },
  },

  mounted() {
    this.$eventBus.$on('parameters-lingo4g-cleared', () => {
      this.analysisOverlayText = '';
      this.analysisOverlayThreadSelected = 'auto';
    });
  },

  methods: {
    validJson(text) {
      if (text === '') {
        return null;
      }

      try {
        const json = JSON.parse(text);
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
    },

    emitKeyBoolean(checked, value) {
      this.$nextTick(() => {
        this.$eventBus.$emit('debug-update', {
          name: value,
          value: this.selectedFlagParameters.includes(value),
        });
      });
    },

    emitKeyValue(eventName, key, value) {
      this.$nextTick(() => {
        this.$eventBus.$emit(eventName, {
          name: key,
          value,
        });
      });
    },

    updateAnalysisOverlayText(value) {
      if (value === '') {
        this.$eventBus.$emit('parameters-lingo4g', null);
      } else if (this.validJson(value)) {
        this.$eventBus.$emit('parameters-lingo4g', new Map([['overlay', JSON.parse(value)]]));
      }
    },

    updateAnalysisOverlayMinResearchActivityLabels(value) {
      if (value === '') {
        this.$eventBus.$emit('parameters-lingo4g', null);
      }
      this.$eventBus.$emit('parameters-lingo4g', new Map([['researchActivities', JSON.parse(`{"arrangement": { "relationship": { "type":"mlt", "mlt": { "minResearchActivityLabels": ${value} }}}}`)]]));
    },

    updateAnalysisOverlayThread(value) {
      this.$eventBus.$emit('parameters-lingo4g', new Map([['performance', new Map([['threads', value]])]]));
    },

    updateAnalysisOverlayMaxLabels(value) {
      this.$nextTick(() => {
        this.$eventBus.$emit('debug-update', {
          name: 'max_labels_percentage',
          value,
        });
      });
    },
  },
};
</script>
