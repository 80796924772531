/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import axios from 'axios';
import _ from 'lodash';

import { getTopicsFromResearchActivityIds } from '@/helpers/utils';

// initial state
const state = {
  top: 50,
  researchActivityIds: [],
  researchers: [],
  topResearcherTotalCounts: {},
  organisations: [],
  topOrganisationTotalCounts: {},

  similarResearchActivityIds: [],
  similarResearchActivityLabels: [],

  topics: [],
};

const getters = {
  filteredResearchActivities: (state, topicGetters, rootState, rootGetters) => {
    const filteredResearchActivities = rootGetters['capability/filteredResearchActivities'];
    return filteredResearchActivities
      .filter((document) => state.researchActivityIds.includes(document.rcm_id));
  },

  // eslint-disable-next-line max-len
  filteredResearchActivityIds: (state, topicGetters) => topicGetters.filteredResearchActivities
    .map((document) => document.rcm_id),

  filteredPublications: (state, topicGetters) => topicGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'publication')),

  filteredProjects: (state, topicGetters) => topicGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'project')),

  filteredPatents: (state, topicGetters) => topicGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'patent')),

  filteredClinicalTrials: (state, topicGetters) => topicGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'clinical_trial')),

  filteredPublicationIds: (state, topicGetters) => topicGetters.filteredPublications
    .map((publication) => publication.rcm_id),

  filteredProjectIds: (state, topicGetters) => topicGetters.filteredProjects
    .map((project) => project.rcm_id),

  filteredPatentIds: (state, topicGetters) => topicGetters.filteredPatents
    .map((patent) => patent.rcm_id),

  filteredClinicalTrialIds: (state, topicGetters) => topicGetters.filteredClinicalTrials
    .map((clinicalTrial) => clinicalTrial.rcm_id),

  filteredTopResearchers: (state) => {
    const { researchers } = state;

    const researchersWithUpdatedTotalDoc = researchers.slice(0, state.top).map((researcher) => {
      const withTotalDocs = state.topResearcherTotalCounts[researcher.rcm_id];
      if (withTotalDocs !== undefined) {
        return {
          ...researcher,
          ...{
            total_research_activity_count: withTotalDocs.research_activity_count,
          },
        };
      }

      return researcher;
    });
    return researchersWithUpdatedTotalDoc;
  },

  filteredTopOrganisations: (state) => {
    const { organisations } = state;

    const organisationsWithUpdatedTotalDoc = organisations.slice(0, state.top)
      .map((organisation) => {
        const withTotalDocs = state.topOrganisationTotalCounts[organisation.rcm_id];
        if (withTotalDocs !== undefined) {
          return {
            ...organisation,
            ...{
              total_organisation_activity_count: withTotalDocs.organisation_activity_count,
            },
          };
        }

        return organisation;
      });
    return organisationsWithUpdatedTotalDoc;
  },

  filteredProjectsSummary: (state, topicGetters) => {
    const { filteredProjects } = topicGetters;

    const numProjects = filteredProjects.length;
    let funding;

    if (numProjects > 0) {
      funding = filteredProjects.reduce(
        (funds, project) => funds + (project.funding_aud ? project.funding_aud : 0), 0,
      );
    } else {
      funding = 0;
    }

    return { numProjects, funding };
  },

};

const actions = {
  topicSelection(context, payload) {
    context.commit('setResearchActivityIds', payload.rcmIds);
    context.dispatch('extractResearchers');
    context.dispatch('extractOrganisations');

    const { rcm2l4g } = context.rootState.result;
    const lingo4gIds = payload.rcmIds.map((rcmId) => rcm2l4g.get(rcmId));

    getTopicsFromResearchActivityIds(lingo4gIds)
      .then((response) => {
        context.commit('setTopics', response.data.topics);
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },

  extractResearchers(context) {
    const capabilityResearchers = context.rootGetters['capability/filteredResearchers'];

    const topicResearchers = capabilityResearchers
      .filter((researcher) => researcher.combinedRcmIds
        .some((rcmId) => context.state.researchActivityIds.includes(rcmId)))
      .map((researcher) => {
        // eslint-disable-next-line max-len
        const topicCount = _.intersection(researcher.combinedRcmIds, context.state.researchActivityIds).length;

        const newResearcher = researcher;
        newResearcher.topic_research_activity_count = topicCount;
        return newResearcher;
      });

    // Sort the Researchers by document count desc
    context.commit('setResearchers', _.orderBy(topicResearchers, ['research_activity_count'], 'desc'));
    context.dispatch('getTopResearcherTotalCounts');
  },

  /**
   * Get researcher total researchActivities with filters applied
   */
  getTopResearcherTotalCounts(context) {
    const queryFilters = context.rootGetters['query/queryFilters'];
    const parameters = ['summary=true'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/researcher/researchactivities${paramString}`;

    // The state researchers have been sorted by document count
    // so a simple slice can give us top researchers
    const topResearchers = context.state.researchers.slice(0, context.state.top);

    // Simplify the researchers with the search fields only
    // This significantly reduces the data size to be transmitted
    const researcherRcmIds = topResearchers.map((researcher) => researcher.rcm_id);

    const payload = {
      researchers: researcherRcmIds,
      filters: queryFilters,
    };

    axios
      .post(url, payload, { withCredentials: true })
      .then((response) => {
        context.commit('setTopResearcherTotalCounts', response.data.researchers);
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },

  extractOrganisations(context) {
    const capabilityOrganisations = context.rootGetters['capability/filteredOrganisations'];

    const topicOrganisations = capabilityOrganisations
      .filter((organisation) => organisation.combinedRcmIds
        .some((rcmId) => context.state.researchActivityIds.includes(rcmId)))
      .map((organisation) => {
        // eslint-disable-next-line max-len
        const topicCount = _.intersection(organisation.combinedRcmIds, context.state.researchActivityIds).length;

        const newOrganisation = organisation;
        newOrganisation.topic_organisation_activity_count = topicCount;
        return newOrganisation;
      });

    // Sort the organisation by document count desc
    context.commit('setOrganisations', _.orderBy(topicOrganisations, ['organisation_activity_count'], 'desc'));
    // context.dispatch('getTopOrganisationTotalCounts');
  },

  /**
   * Get organisation total researchActivities with filters applied
   */
  getTopOrganisationTotalCounts(context) {
    const queryFilters = context.rootGetters['query/queryFilters'];
    const parameters = ['summary=true'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/organisation/researchactivities${paramString}`;

    // The state organisations have been sorted by document count
    // so a simple slice can give us top organisations
    const topOrganisations = context.state.organisations.slice(0, context.state.top);

    // Simplify the organisations with the search fields only
    // This significantly reduces the data size to be transmitted
    const organisationRcmIds = topOrganisations.map((researcher) => researcher.rcm_id);

    const payload = {
      organisations: organisationRcmIds,
      filters: queryFilters,
    };

    axios
      .post(url, payload, { withCredentials: true })
      .then((response) => {
        context.commit('setTopOrganisationTotalCounts', response.data.organisations);
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },

  topicDeselection(context) {
    context.commit('misc/showCapabilityPaneIfTopicVisible', null, { root: true });
  },
};

const mutations = {
  setResearchActivityIds(state, rcmIds) {
    state.researchActivityIds = rcmIds;
  },

  setTopics(state, topics) {
    state.topics = topics;
  },

  setResearchers(state, researchers) {
    state.researchers = researchers;
  },

  setOrganisations(state, organisations) {
    state.organisations = organisations;
  },

  setTopResearcherTotalCounts(state, value) {
    state.topResearcherTotalCounts = value;
  },

  setTopOrganisationTotalCounts(state, value) {
    state.topOrganisationTotalCounts = value;
  },

  setSimilarResearchActivityIds(state, similarResearchActivityIds) {
    state.similarResearchActivityIds = similarResearchActivityIds;
  },

  setSimilarResearchActivityLabels(state, similarResearchActivityLabels) {
    state.similarResearchActivityLabels = similarResearchActivityLabels;
  },

  reset(state) {
    state.researchActivityIds = [];

    state.similarResearchActivityIds = [];
    state.similarResearchActivityLabels = [];

    state.topics = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
