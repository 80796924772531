<template>
  <b-card id="topicSelection">
    <div v-show="topicLabel">
      <div class="main-topic-title">
        <b>{{ topicLabel.toUpperCase() }}</b>
      </div>
      <TopicLabels
        v-for="(cluster, index) of topicClusters"
        :key="index"
        :label-cluster="cluster"
        :latest-query="query"
      />
    </div>
  </b-card>
</template>

<style>
.main-topic-title {
  padding-bottom: 5px;
}
</style>

<script>
import axios from 'axios';
import {
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

import TopicLabels from '@/components/topic_pane/TopicLabels.vue';

export default {
  name: 'TopicSelection',

  components: {
    TopicLabels,
  },

  data() {
    return {
      topicClusters: [],
      topicLabel: '', // The label with the highest priority in the cluster shown as cluster topic
      seeMoreDocsIcon: faSearchPlus,
      similarDocsInProgress: false,
    };
  },

  computed: {
    ...mapGetters('query', ['query']),
    ...mapState('result', ['rcm2l4g']),
    ...mapState('topic', ['publicationIds']),
  },

  mounted() {
    this.$eventBus.$on('topic-selection', (publicationIds, selectedCluster, fromMap) => {
      this.topicLabel = '';

      // List of researchActivities in the selected cluster
      const lingo4gIds = publicationIds.map((publicationId) => this.rcm2l4g.get(publicationId));

      // If the topic-selection event is raised from clicking a cluster on the map
      // Try to extract the main topic label from the cluster labels
      if (fromMap) {
        // Labels in cluster
        const labels = selectedCluster.filter((point) => point.label);
        const sortedLabels = labels.sort(
          (a, b) => b.labelPriority - a.labelPriority,
        );
        if (sortedLabels.length > 0) {
          this.topicLabel = sortedLabels[0].label;
        }
      }

      const url = `${process.env.RCM_API}/api/v1/topic/topics?mode=topic`;

      axios
        .post(url, { docs: lingo4gIds }, { withCredentials: true })
        .then((response) => this.showTopics(response.data))
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    });
  },

  methods: {
    ...mapMutations('topic', [
      'setSimilarResearchActivityLabels',
    ]),

    showTopics(result) {
      const exemplarTopicLabels = [];
      this.topicClusters = result.topics.sort((a, b) => b.exemplar.df - a.exemplar.df);

      // Use the exmeplar label received from backend
      if (result.topics[0].exemplar.df !== -1) {
        this.topicLabel = result.topics[0].exemplar.text;

        result.topics.forEach((topic) => {
          exemplarTopicLabels.push(topic.exemplar.text);
        });
      }

      this.setSimilarResearchActivityLabels(exemplarTopicLabels);
    },
  },
};
</script>
