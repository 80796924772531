/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import _ from 'lodash';
import axios from 'axios';
import parse from 'url-parse';
import { CognitoAuth } from 'amazon-cognito-auth-js';

// initial state
const state = {
  csrfKey: 'cognitoCSRF',

  userInfo: null,
  apiAuthenticated: false,
};

const getters = {};

const actions = {
  authenticateCognito(context) {
    const authData = {
      UserPoolId: process.env.COGNITO_USER_POOL_ID,
      ClientId: process.env.COGNITO_CLIENT_ID,
      AppWebDomain: process.env.COGNITO_WEB_DOMAIN,
      TokenScopesArray: ['openid', 'email', 'profile'],

      RedirectUriSignIn: window.location.href,
      RedirectUriSignOut: process.env.UOM_HOME,
    };

    const auth = new CognitoAuth(authData);

    // Create the CSRF nonce
    if (!_.has(sessionStorage, context.state.csrfKey)) {
      const nonce = auth.generateRandomString(
        auth.getCognitoConstants().STATELENGTH,
        auth.getCognitoConstants().STATEORIGINSTRING,
      );

      sessionStorage.setItem(context.state.csrfKey, nonce);
    }

    auth.setState(sessionStorage.getItem(context.state.csrfKey));

    auth.userhandler = {
      onSuccess: (session) => {
        // Check the CSRF nonce matches
        if (auth.getState() !== sessionStorage.getItem(context.state.csrfKey)) {
          throw new Error('CSRF Token Invalid');
        }

        // Remove Cognito parameters from URL
        window.history.replaceState({}, document.title, _.head(window.location.href.split('#')));

        // Commit the session for later
        context.dispatch('getCognitoUserInfo', { auth, session })
          .then(() => context.dispatch('authenticateRcmApi', auth, session))
          .catch(() => auth.getSession());
      },
      onFailure: () => auth.getSession(),
    };

    const currentUrl = window.location.href;
    auth.parseCognitoWebResponse(currentUrl);

    setTimeout(() => {
      if (!context.state.apiAuthenticated) {
        auth.getSession();
      }
    }, 4000);
  },

  getCognitoUserInfo(context, { auth, session }) {
    // Get the athenticated user's info from Cognito (provided by SAML)
    const url = `https://${auth.getAppWebDomain()}/oauth2/userInfo`;
    const requestData = {
      headers: {
        Authorization: `Bearer ${session.getAccessToken().jwtToken}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios.get(url, requestData, { withCredentials: true })
        .then((response) => {
          context.commit('setUserInfo', response.data);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },

  authenticateRcmApi(context) {
    // Authenticate the user to the RCM API
    // This assumes you have a valid Cognito session on the Cognito server
    // As we rely on the ALB to perform the Cognito authentication
    const url = `${process.env.RCM_API}/api/v1/authenticate`;

    return new Promise((resolve) => {
      axios.get(`${url}?check=true`, { withCredentials: true })
        .then(() => {
          context.commit('setApiAuthenticated', true);

          // eslint-disable-next-line no-underscore-dangle
          this._vm.$ga.event({
            eventCategory: 'Authentication',
            eventAction: 'UserLoginSuccess',
            eventLabel: context.state.userInfo.person_id,
          });

          // eslint-disable-next-line no-underscore-dangle
          this._vm.$ga.event({
            eventCategory: 'Authentication',
            eventAction: 'DepartmentLoginSuccess',
            eventLabel: context.state.userInfo['custom:department_code'],
          });

          context.dispatch('loadSavedQuery');
          resolve();
        })
        .catch(() => {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$ga.event({
            eventCategory: 'Authentication',
            eventAction: 'UserLoginFailure',
            eventLabel: context.state.userInfo.person_id,
          });

          window.location.replace(url);
        });
    });
  },

  loadSavedQuery(context) {
    if (!_.has(sessionStorage, 'queryId')) {
      return null;
    }

    const queryId = sessionStorage.getItem('queryId');
    sessionStorage.removeItem('queryId');

    const url = `${process.env.RCM_API}/api/v1/user/queries?id=${queryId}`;

    return axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const { query } = response.data;

        context.commit('query/overwriteQueryFilters', query.filters, { root: true });
        context.commit('query/overwriteQueryParameters', query.parameters, { root: true });

        function sleep(ms) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }

        const that = this;
        query.groups.forEach((group) => {
          // eslint-disable-next-line no-underscore-dangle
          sleep(1000).then(() => that._vm.$eventBus.$emit('add-query-group', group));
        });
      })
      .catch(() => {
        // Remove the bad Query ID from the URL
        const currentUrl = parse(window.location.href, true);
        delete currentUrl.query.query;

        window.history.replaceState({}, document.title, currentUrl.toString());
      });
  },
};

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },

  setApiAuthenticated(state, status) {
    state.apiAuthenticated = status;
  },

  reset(state) {
    state.userInfo = null;
    state.apiAuthenticated = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
