<template>
  <b-card
    id="capabilityPane"
    no-body
  >
    <b-card-header>
      <b-nav
        id="capability-nav"
        v-b-scrollspy:capability-nav-scroller
        pills
        justified
      >
        <b-nav-item
          link-classes="small-padded-link"
          href="#capabilityInsights"
          @click.prevent="scrollIntoView"
        >
          Insights
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#capabilityBenchmarks"
          @click.prevent="scrollIntoView"
        >
          Benchmarks
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#capabilityTopics"
          @click.prevent="scrollIntoView"
        >
          Topics
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#capabilityResearchers"
          @click.prevent="scrollIntoView"
        >
          Researchers
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#capabilityOrganisations"
          @click.prevent="scrollIntoView"
        >
          Organisations
        </b-nav-item>
      </b-nav>
    </b-card-header>
    <b-card-body
      id="capability-nav-scroller"
      ref="content"
      style="position:relative; overflow-y:scroll;"
    >
      <h5 id="capabilityInsights">
        Capability Insights
      </h5>
      <CapabilityInsights />
      <h5 id="capabilityBenchmarks">
        <br>
        Capability Benchmarks
      </h5>
      <CapabilityBenchmarks />
      <h5 id="capabilityTopics">
        <br>
        Capability Topics
      </h5>
      <CapabilityTopicSelection />
      <h5 id="capabilityResearchers">
        <br>
        Capability Researchers
      </h5>
      <CapabilityResearchers />
      <h5 id="capabilityOrganisations">
        <br>
        Capability Organisations
      </h5>
      <CapabilityOrganisations />
      <div class="extra-space" />
    </b-card-body>
  </b-card>
</template>

<style>
#capabilityPane {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#capability-nav {
  padding: 5px 0px 5px 0px;
}

#capability-nav-scroller {
  padding: 5px 20px 5px 20px;
}

.extra-space {
  min-height: 50vh;
}

.small-padded-link {
  padding: 3px 4px 3px 4px;
}
</style>

<script>
import CapabilityInsights from '@/components/capability_pane/CapabilityInsights.vue';
import CapabilityBenchmarks from '@/components/capability_pane/CapabilityBenchmarks.vue';
import CapabilityResearchers from '@/components/capability_pane/CapabilityResearchers.vue';
import CapabilityTopicSelection from '@/components/capability_pane/CapabilityTopicSelection.vue';
import CapabilityOrganisations from '@/components/capability_pane/CapabilityOrganisations.vue';

export default {
  name: 'CapbilityPane',

  components: {
    CapabilityTopicSelection,
    CapabilityResearchers,
    CapabilityBenchmarks,
    CapabilityInsights,
    CapabilityOrganisations,
  },

  methods: {
    // Convenience method to scroll a heading into view.
    // Not required for scrollspy to work
    scrollIntoView(evt) {
      const href = evt.target.getAttribute('href');
      const el = href ? document.querySelector(href) : null;
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop;
      }
    },
  },
};
</script>
