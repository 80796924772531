<template>
  <div id="queryPane">
    <b-card body-class="query-builder-body">
      <h6
        slot="header"
        class="mb-0"
      >
        What would you like to find?
        <div
          id="miniCollapseArrow"
          @click="collapseQueryPanel"
        >
          <font-awesome-icon :icon="collapseArrow" />
        </div>
      </h6>
      <SearchBox />
      <br>
      <QueryBuilder />
      <div id="explore">
        <span class="float-right">
          <b-button
            type="submit"
            variant="light"
            @click.prevent="onSubmitClearSearch"
          >Clear Search</b-button>
          <b-button
            type="submit"
            variant="primary"
            @click.prevent="onSubmitExplore"
          >
            {{ (addKeywordOnSubmit) ? 'Add & Explore' : 'Explore' }}
            <b-badge
              v-if="newQueryItemCount"
              variant="light"
            >
              {{ newQueryItemCount }}
              <span class="sr-only">
                new query items
              </span>
            </b-badge>
          </b-button>
        </span>
        <div v-show="$store.state.request.mapRequestInProgress">
          <pulse-loader style="display:inline">
            Analysis in progress...
          </pulse-loader>
        </div>
      </div>
    </b-card>
    <SuggestedThings />
    <CurrentUoMFilter />
    <ResearchActivityTypeFilter />
    <TimeRangeFilter />
    <ResearcherCountFilter />
    <FoRCodeFilter />
    <DebugParameters />
  </div>
</template>

<style>
#explore {
  padding-top: 15px;
}

#queryPane {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#miniCollapseArrow {
  float: right;
}

.query-builder-body {
  padding: 1em 0.75em 1em 0.75em;
}
</style>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import SearchBox from '@/components/query_pane/SearchBox.vue';
import QueryBuilder from '@/components/query_pane/QueryBuilder.vue';
import SuggestedThings from '@/components/query_pane/SuggestedThings.vue';

import FoRCodeFilter from '@/components/query_pane/FoRCodeFilter.vue';
import TimeRangeFilter from '@/components/query_pane/TimeRangeFilter.vue';
import CurrentUoMFilter from '@/components/query_pane/CurrentUoMFilter.vue';
import ResearchActivityTypeFilter from '@/components/query_pane/ResearchActivityTypeFilter.vue';
import ResearcherCountFilter from '@/components/query_pane/ResearcherCountFilter.vue';

import DebugParameters from '@/components/query_pane/DebugParameters.vue';

export default {
  name: 'QueryPane',

  components: {
    SearchBox,
    QueryBuilder,
    SuggestedThings,

    FoRCodeFilter,
    CurrentUoMFilter,
    ResearchActivityTypeFilter,
    TimeRangeFilter,
    ResearcherCountFilter,
    DebugParameters,

    PulseLoader,
  },

  data() {
    return {
      collapseArrow: faChevronLeft,
    };
  },

  computed: {
    ...mapState('ui', ['addKeywordOnSubmit']),
    ...mapGetters(
      'query',
      [
        'query',
        'queryWithoutResearchActivityIds',
        'queryGroupsWithoutResearchActivityIds',
        'newQueryItemCount',
      ],
    ),
  },

  mounted() {
    this.$eventBus.$on('update-filter', (filter) => {
      this.updateFilter(filter);
      this.$eventBus.$emit('filter-analysis', filter.filter);
    });

    this.$eventBus.$on('parameters-lingo4g', (parameters) => {
      if (parameters === null) {
        this.setParameterValue({
          key: 'lingo4g',
          value: { overlay: {} },
          keyPath: [],
        });

        this.$eventBus.$emit('parameters-lingo4g-cleared');
      } else {
        this.setRecursiveParameterValues(parameters);
      }
    });
  },

  methods: {
    ...mapActions('query', ['setRecursiveParameterValues']),
    ...mapMutations('query', ['updateFilter', 'setParameterValue']),

    onSubmitExplore() {
      // Send the requested query object to Google Analytics
      this.$ga.event({
        eventCategory: 'Query',
        eventAction: 'Submit Query Object',
        eventLabel: JSON.stringify(this.queryWithoutResearchActivityIds),
        eventValue: 1,
      });

      // Send the query groups to Google Analytics
      this.$ga.event({
        eventCategory: 'Query',
        eventAction: 'Submit Query Groups',
        eventLabel: JSON.stringify(this.queryGroupsWithoutResearchActivityIds),
      });

      this.$eventBus.$emit('submit-button-clicked');
      this.$eventBus.$emit('both-pane-deselection');

      this.$nextTick(() => {
        this.$eventBus.$emit('request-analysis');
      });
    },

    onSubmitClearSearch() {
      this.$eventBus.$emit('both-pane-deselection');
      this.$store.dispatch('reset');
      this.$eventBus.$emit('reset');
    },

    collapseQueryPanel() {
      this.$eventBus.$emit('toggle-query-pane');
    },
  },
};
</script>
