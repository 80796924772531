<template>
  <b-row>
    <b-col cols="9">
      <div id="miniQueryPane">
        <b-card body-class="card-body-less-padding">
          <b-button
            type="submit"
            variant="primary"
            size="sm"
            block
            style="white-space: nowrap;"
            :disabled="$store.state.request.mapRequestInProgress"
            @click.prevent="onSubmitExplore"
          >
            <font-awesome-icon :icon="exploreCompass" />
            <b-badge
              v-if="newQueryItemCount"
              variant="light"
            >
              {{ newQueryItemCount }}
            </b-badge>
          </b-button>
          <br>
          <MiniQueryBuilder />
        </b-card>
        <div
          id="miniExpandArrow"
          @click="expandQueryPanel"
        >
          <font-awesome-icon :icon="collapseArrow" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style>
#miniQueryPane {
  max-height: 90vh;
  position: relative;
}

#miniExpandArrow {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translate(0, -50%);
}

.card-body-less-padding {
  padding: 5px 5px 0px 5px;
}
</style>

<script>
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { mapGetters } from 'vuex';

import MiniQueryBuilder from '@/components/mini_query_pane/MiniQueryBuilder.vue';

export default {
  name: 'MiniQueryPane',

  components: {
    MiniQueryBuilder,
  },

  data() {
    return {
      exploreCompass: faCompass,
      collapseArrow: faChevronRight,
    };
  },

  computed: {
    ...mapGetters(
      'query',
      [
        'query',
        'queryWithoutResearchActivityIds',
        'queryGroupsWithoutResearchActivityIds',
        'newQueryItemCount',
      ],
    ),
  },

  methods: {
    onSubmitExplore() {
      // Send the requested query object to Google Analytics
      this.$ga.event({
        eventCategory: 'Query',
        eventAction: 'Submit Query Object',
        eventLabel: JSON.stringify(this.queryWithoutResearchActivityIds),
        eventValue: 1,
      });

      // Send the query groups to Google Analytics
      this.$ga.event({
        eventCategory: 'Query',
        eventAction: 'Submit Query Groups',
        eventLabel: JSON.stringify(this.queryGroupsWithoutResearchActivityIds),
      });

      this.$eventBus.$emit('submit-button-clicked');
      this.$eventBus.$emit('topic-deselection');

      this.$nextTick(() => {
        this.$eventBus.$emit('request-analysis');
      });
    },

    expandQueryPanel() {
      this.$eventBus.$emit('toggle-query-pane');
    },
  },
};
</script>
