<template>
  <CollapsableCard
    id="SuggestedThings"
    :visible="!!pools.length"
  >
    <template v-slot:header>
      Suggestions
      <div
        class="float-right"
        @click.stop="progressPoolOffset"
      >
        <font-awesome-icon
          v-show="selectedPool.length"
          :icon="syncIcon"
        />
        &nbsp;
      </div>
    </template>

    <b-tabs pills>
      <b-tab
        v-for="currentType in suggestedThingsTypes"
        :key="currentType.value"
        title-link-class="minimalPadding"
        :title="currentType.text"
        @click="updateSelectPoolType(currentType.value)"
      />
    </b-tabs>
    <div v-show="!selectedPool.length">
      <br>
      Nothing to see here yet!
    </div>
    <md-chips
      v-show="selectedPool.length"
      v-model="selectedPool"
      md-static
      @md-click="addThing"
    >
      <template
        slot="md-chip"
        slot-scope="{ chip }"
      >
        <span v-if="chip.type === 'keyword'">
          <font-awesome-icon :icon="keywordIcon" />&nbsp;
        </span>
        <span v-else-if="chip.type === 'country'">
          <font-awesome-icon :icon="countryIcon" />&nbsp;
        </span>
        <span v-else-if="chip.type === 'forcode'">
          <font-awesome-icon :icon="forIcon" />&nbsp;
        </span>
        <span v-else-if="chip.type === 'researcher'">
          <font-awesome-icon :icon="researcherIcon" />&nbsp;
        </span>
        <span v-else-if="chip.type === 'department'">
          <font-awesome-icon :icon="departmentIcon" />&nbsp;
        </span>
        <span v-else-if="chip.type === 'organisation'">
          <font-awesome-icon :icon="organisationIcon" />&nbsp;
        </span>
        <span v-else>
          <font-awesome-icon :icon="unknownTypeIcon" />&nbsp;
        </span>
        {{ chip.text.length > 33 ? chip.text.substring(0, 30) + '...' : chip.text }}&nbsp;
        <span v-if="chip.count === undefined" />
        <span v-else>
          <font-awesome-icon
            :icon="newResearchActivityIcon"
            class="new-document-icon"
          />
          <span style="color:gray;">{{ chip.count }}</span>
        </span>
      </template>
    </md-chips>
  </CollapsableCard>
</template>

<style>
table th {
  display: none;
}

.minimalPadding {
  padding: 2px 6px 3px 6px;
}
</style>

<script>
import {
  faEdit,
  faGlobeAsia,
  faFlask,
  faUser,
  faBuilding,
  faUniversity,
  faQuestion,
  faSync,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';

import CollapsableCard from '@/components/common/CollapsableCard.vue';

export default {
  name: 'SuggestedThings',

  components: {
    CollapsableCard,
  },

  data() {
    return {
      suggestedThingsParameters: {},

      pools: [],
      selectedPoolOptions: {
        index: null,
        offset: 0,
        size: 12,
        type: 'all',
        sortBy: 'count',
        updateCounter: 0,
      },
      latestSelectedPoolLength: 0,
      addedThings: [],

      suggestedThingsTypes: [
        { value: 'all', text: 'All' },
        { value: 'keyword', text: 'Keyword' },
        { value: 'forcode', text: 'FoR Code' },
        { value: 'researcher', text: 'Researcher' },
        { value: 'department', text: 'Department' },
      ],

      keywordIcon: faEdit,
      countryIcon: faGlobeAsia,
      forIcon: faFlask,
      researcherIcon: faUser,
      departmentIcon: faBuilding,
      organisationIcon: faUniversity,
      unknownTypeIcon: faQuestion,
      syncIcon: faSync,
      newResearchActivityIcon: faPlusCircle,
    };
  },

  computed: {
    ...mapGetters('query', ['query']),

    selectedPool() {
      const options = this.selectedPoolOptions;

      if (options.updateCounter === 0) {
        return [];
      }

      let pool;

      if (options.index === null) {
        if (this.pools.length === 0) {
          return [];
        }

        // Combine all the pools together
        pool = this.pools.reduce((accum, cur) => accum.concat(cur));

        // Remove duplicates
        pool = pool.filter(
          (thing, index, self) => self.findIndex(
            (t) => t.type === thing.type && t.value === thing.value,
          ) === index,
        );
      } else {
        pool = this.pools[options.index];
      }

      if (typeof pool === 'undefined') {
        return [];
      }

      // Sort the pool by count
      pool.sort((a, b) => (b[options.sortBy] - a[options.sortBy]));

      // Find the maximum number of things we'll show per type
      const thingTypeSet = new Set(pool.map((thing) => thing.type));
      let maxThingTypeCount = options.size;

      if (thingTypeSet.size > 1 && options.type === 'all') {
        maxThingTypeCount = options.size / thingTypeSet.size;
      }

      // Keep track how how many times we've shown a thing type
      const displayedCount = new Map();
      thingTypeSet.forEach((typeName) => displayedCount.set(typeName, 0));

      const selectedPool = [];

      const filteredPool = pool
        .filter((thing) => (options.type === 'all' ? true : thing.type === options.type))
        .filter(
          (thing) => !this.addedThings.some(
            (aThing) => aThing.type === thing.type && aThing.value === thing.value,
          ),
        );

      // We use this to remember the size of the underlying selected pool
      // Not the limited one by size/filter/etc
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.latestSelectedPoolLength = filteredPool.length;

      filteredPool.some((thing, index) => {
        // Skip forward to the start of our slice
        if (index < options.offset) { return false; }

        // Skip if we've displayed enough of this type
        if (displayedCount.get(thing.type) >= maxThingTypeCount) {
          return false;
        }

        // Exit after we've pushed enough
        if (selectedPool.length >= options.size) { return true; }

        selectedPool.push(thing);
        displayedCount.set(thing.type, displayedCount.get(thing.type) + 1);

        return false;
      });

      return selectedPool;
    },
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.suggestedThingsParameters = {};

      this.pools.length = 0;
      this.selectedPoolOptions.index = null;
      this.selectedPoolOptions.offset = 0;
      this.selectedPoolOptions.updateCounter = 0;

      this.latestSelectedPoolLength = 0;
      this.addedThings.length = 0;
    });

    this.$eventBus.$on('add-thing-to-query', (thing, source) => {
      if (source !== 'SuggestedThings') {
        this.addedThings.push(thing);
      }
    });

    this.$eventBus.$on('suggested-things-parameters', (parameter) => {
      if (parameter.value === null) {
        delete this.suggestedThingsParameters[parameter.name];
      } else {
        this.$set(this.suggestedThingsParameters, parameter.name, parameter.value);
      }
    });

    this.$eventBus.$on(['added-query-group', 'updated-query-group'], (queryGroupIndex) => {
      // Fast suggestions, which may not be as accurate
      this.getSuggestedThings(this.query, queryGroupIndex, 'fast')
        .then((response) => {
          const pool = response.data.things;

          pool.indexOf = function thingIndexOf(value) {
            let foundIndex = -1;

            this.some((thing, index) => {
              if (thing.type === value.type && thing.value === value.value) {
                foundIndex = index;
                return true;
              }
              return false;
            });

            return foundIndex;
          };

          if (this.pools.length < queryGroupIndex) {
            // This is bad, it means we have desynced with the query groups list
            // Both lists should stay at equal length
            console.log('ERROR: Suggested Pools have desynced from Query Groups'); // eslint-disable-line no-console
            return false;
          // eslint-disable-next-line no-else-return
          } else if (this.pools.length === queryGroupIndex) {
            // Index is a new element on the end
            this.pools.push(pool);
          } else if (this.pools.length > queryGroupIndex) {
            // Index is within the pools, splice it in deleting the old one
            this.pools.splice(queryGroupIndex, 1, pool);
          }

          this.updatePoolCounts(this.pools[queryGroupIndex]);

          // Slow suggestions, which are more accurate
          this.getSuggestedThings(this.query, queryGroupIndex, 'slow')
            .then((slowResponse) => {
              const slowPool = slowResponse.data.things;

              // Update existing pool with new suggestions
              const newThings = [];

              // Remove duplicated values that are already contained in fast suggestions
              slowPool.forEach((thing) => {
                if (this.pools[queryGroupIndex].some((item) => item.value === thing.value)) return;

                newThings.push(thing);
              });

              // Get the old pool, and add our new suggestions onto the end
              const oldPool = this.pools[queryGroupIndex];
              oldPool.push(...newThings);

              this.updatePoolCounts(this.pools[queryGroupIndex]);

              return true;
            })
            .catch((error) => console.log(error)); // eslint-disable-line no-console

          // Return true on fast path, slow path is currently executing now
          return true;
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    });

    this.$eventBus.$on('deleted-query-group', (queryGroupIndex) => {
      this.pools.splice(queryGroupIndex, 1);

      // Reset back to the 'overall' pool
      this.selectedPoolOptions.offset = 0;
      this.selectedPoolOptions.index = null;
    });

    this.$eventBus.$on('selected-query-group', (queryGroupIndex) => {
      if (this.selectedPoolOptions.index === queryGroupIndex) {
        // Query Group Row deselection
        this.selectedPoolOptions.index = null;
      } else {
        // Query Group Row selection
        this.selectedPoolOptions.index = queryGroupIndex;
      }
    });
  },

  methods: {
    // eslint-disable-next-line max-len
    getSuggestedThings(query, queryGroupIndex, querySpeed) {
      const parameters = [];
      parameters.push(`queryGroupIndex=${queryGroupIndex}`);
      parameters.push(`querySpeed=${querySpeed}`);

      if (_.has(this.suggestedThingsParameters, 'keywordMode')) {
        parameters.push(`keywordMode=${this.suggestedThingsParameters.keywordMode}`);
      }

      if (_.has(this.suggestedThingsParameters, 'forcodeMode')) {
        parameters.push(`forcodeMode=${this.suggestedThingsParameters.forcodeMode}`);
      }

      if (_.has(this.suggestedThingsParameters, 'researcherMode')) {
        parameters.push(`researcherMode=${this.suggestedThingsParameters.researcherMode}`);
      }

      if (_.has(this.suggestedThingsParameters, 'departmentMode')) {
        parameters.push(`departmentMode=${this.suggestedThingsParameters.departmentMode}`);
      }

      if (_.has(this.suggestedThingsParameters, 'organisationMode')) {
        parameters.push(`organisationMode=${this.suggestedThingsParameters.organisationMode}`);
      }

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/suggestedthings${paramString}`;

      return axios.post(url, query, { withCredentials: true });
    },

    updatePoolCounts(pool) {
      const parameters = [];

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/thingcount${paramString}`;

      pool.forEach((thing) => {
        axios
          .post(url, { thing, query: this.query }, { withCredentials: true })
          .then((response) => {
            this.$set(thing, 'count', response.data.count);
            this.selectedPoolOptions.updateCounter += 1;
          })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      });
    },

    updateSelectPoolType(type) {
      this.selectedPoolOptions.offset = 0;
      this.selectedPoolOptions.type = type;
    },

    addThing(thing) {
      let poolIndexList;

      if (this.selectedPoolOptions.index === null) {
        poolIndexList = this.pools.map((value, index) => index);
      } else {
        poolIndexList = [this.selectedPoolOptions.index];
      }

      poolIndexList.forEach((poolIndex) => {
        // Remove the clicked on thing from the list
        // Assumption is 'value' is always unique across things, text may not be
        const filteredPool = this.pools[poolIndex].filter(
          (poolThing) => poolThing.value !== thing.value,
        );

        this.pools.splice(poolIndex, 1, filteredPool);
      });

      this.addedThings.push(thing);
      this.$eventBus.$emit('add-thing-to-query', thing, 'SuggestedThings');
    },

    progressPoolOffset() {
      const options = this.selectedPoolOptions;
      const maxPoolOffset = this.latestSelectedPoolLength - options.offset;

      if (options.offset > maxPoolOffset) {
        // We've reached the end of the pool, go back to the start
        options.offset = 0;
      } else {
        options.offset += options.size;
      }
    },
  },
};
</script>
