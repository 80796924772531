<template>
  <b-modal
    id="savedQueriesModal"
    v-model="modalVisible"
    size="lg"
    scrollable
    cancel-disabled
    ok-disabled
  >
    <div slot="modal-footer" />

    <h5>Saved Queries</h5>
    <p v-if="queries.length === 0">
      You don't have any saved queries.
    </p>
    <b-table
      v-else
      id="saved-queries-table"
      :items="queries"
      :fields="queriesFields"
      sort-by="created_on"
      :sort-desc="true"
      per-page="20"
    >
      <template
        v-slot:cell(title)="data"
      >
        <a :href="generateQueryLoadUrl(data.item.id)">{{ data.item.title }}</a>
      </template>
      <template
        v-slot:cell(tags)="data"
      >
        {{ data.item.tags.join(", ") }}
      </template>
      <template
        v-slot:cell(delete)="data"
      >
        <b-button
          type="button"
          variant="primary"
          size="sm"
          @click.prevent="deleteQuery(data.item.id)"
        >
          Delete
        </b-button>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import axios from 'axios';

/**
 * Modal that shows a user their own saved queries
 * @class
 */
export default {
  name: 'SavedQueriesModal',

  components: {},

  data() {
    return {
      modalVisible: false,

      queries: [],

      queriesFields: [
        { key: 'title', label: 'Title & Link' },
        { key: 'created_on', label: 'Created On', sortable: true },
        { key: 'delete', label: 'Delete' },
      ],
    };
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.queries.length = 0;
    });

    this.$eventBus.$on('show-saved-queries-overlay', () => {
      this.getSavedQueries();
    });
  },

  methods: {
    generateQueryLoadUrl(id) {
      return `${window.location.pathname}?query=${id}`;
    },

    getSavedQueries() {
      const savedQueriesUrl = `${process.env.RCM_API}/api/v1/user/queries`;

      return axios
        .get(savedQueriesUrl, { withCredentials: true })
        .then((response) => {
          this.queries = response.data.queries;
          this.modalVisible = true;
        })
        .catch((error) => console.error(error)); // eslint-disable-line no-console
    },

    deleteQuery(queryId) {
      const url = `${process.env.RCM_API}/api/v1/user/delete-query?id=${queryId}`;

      return axios
        .get(url, { withCredentials: true })
        .then(() => {
          this.modalVisible = false;
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    },
  },
};
</script>
