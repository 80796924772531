<template>
  <b-card id="capabilityTopicSelection">
    <div v-show="topicLabel">
      <div class="main-topic-title">
        <b>{{ topicLabel.toUpperCase() }}</b>
      </div>
      <TopicLabels
        v-for="(cluster, index) of topicClusters"
        :key="index"
        :label-cluster="cluster"
        :latest-query="query"
      />
    </div>
  </b-card>
</template>

<style>
.main-topic-title {
  padding-bottom: 5px;
}
</style>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';
import axios from 'axios';

import TopicLabels from '@/components/topic_pane/TopicLabels.vue';

export default {
  name: 'CapabilityTopicSelection',

  components: {
    TopicLabels,
  },

  data() {
    return {
      topicClusters: [],
      topicLabel: '', // The label with the highest priority in the cluster shown as cluster topic
    };
  },

  computed: {
    ...mapGetters('query', ['query']),
  },

  mounted() {
    this.$eventBus.$on('request-analysis', () => {
      const url = `${process.env.RCM_API}/api/v1/capability/topics?mode=capability`;

      axios
        .post(url, this.query, { withCredentials: true })
        .then((response) => this.showTopics(response.data))
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    });
  },

  methods: {
    ...mapMutations('capability', ['setSimilarResearchActivityLabels']),

    onSelectTopic(action) {
      const topicData = {
        action,
        labels: [this.topicLabel],
        // Only 'See More' action is available for whole query
        // It doesn't make sense to have 'See Less' and remove all researchActivities in the query!
        docs: null,
      };

      if (!this.isKeywordInQuery(topicData.label)) {
        this.$eventBus.$emit('topic-filter', topicData);
      }
    },

    showTopics(result) {
      const exemplarTopicLabels = [];
      this.topicClusters = result.topics.sort((a, b) => b.exemplar.df - a.exemplar.df);

      // Use the exmeplar labels received from backend
      if (result.topics.length && result.topics[0].exemplar.df !== -1) {
        this.topicLabel = result.topics[0].exemplar.text;

        result.topics.forEach((topic) => {
          exemplarTopicLabels.push(topic.exemplar.text);
        });
      } else {
        this.topicLabel = '';
      }

      this.setSimilarResearchActivityLabels(exemplarTopicLabels);
    },

    isKeywordInQuery(keyword) {
      return this.query.groups.some((group) => group.items.some((item) => item.text === keyword));
    },

  },
};
</script>
