import Vue from 'vue';
import VueMaterial from 'vue-material';
import BootstrapVue from 'bootstrap-vue';
import VueAnalytics from 'vue-analytics';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './components/App.vue';
import store from './store';
import EventBus from './event-bus';

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(VueMaterial);
Vue.use(VueAnalytics, {
  id: 'UA-142383978-1',
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production',
  },
});

// Create the div to mount the application into
const body = document.getElementsByTagName('body')[0];
const appDiv = document.createElement('div');
appDiv.id = 'app';
body.appendChild(appDiv);

Vue.prototype.$eventBus = EventBus;

new Vue({ // eslint-disable-line no-new
  el: '#app',
  store,
  render: (h) => h(App),
});
