/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

// initial state
const state = {
  rcm2l4g: new Map(),
  l4g2rcm: new Map(),
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  updateIdTranslations(state, payload) {
    state.rcm2l4g.set('a', 1);
    state.l4g2rcm.set('a', 1);
    payload.rcm2l4g.set('a', 1);
    payload.l4g2rcm.set('a', 1);

    // We only ever 'append' to the translations with new mappings, they'll override old ones
    // This is dangerous if the user never refreshes their web page in 'some time'
    // As this will grow to the size of the underlying dataset

    state.rcm2l4g = new Map([...state.rcm2l4g, ...payload.rcm2l4g]);
    state.l4g2rcm = new Map([...state.l4g2rcm, ...payload.l4g2rcm]);

    state.rcm2l4g.delete('a');
    state.l4g2rcm.delete('a');
  },

  reset(state) {
    state.rcm2l4g = new Map();
    state.l4g2rcm = new Map();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
