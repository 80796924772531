<template>
  <b-card
    header-tag="header"
    no-body
  >
    <h6
      slot="header"
      class="mb-0"
    >
      <a
        id="headeranchor"
        href="#"
        class="text-dark"
        @click.prevent="toggleCollapse"
      >
        <div class="float-right card-chevron">
          <font-awesome-icon
            :icon="chevronDownIcon"
            style="color: #9ca4ab;"
            :class="{rotated: !showContent}"
          />
        </div>
        <slot name="header">
          {{ header }}
        </slot>
      </a>
    </h6>
    <b-collapse
      :id="id"
      v-model="showContent"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<style>
#headeranchor {
  display: block;
  text-decoration: none;
  cursor: default;
}

.card-chevron {
  transition: 0.3s transform ease-in-out;
}

.card-chevron .rotated{
  transform: rotate(90deg);
}
</style>

<script>
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CollapsableCard',

  props: {
    id: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showContent: true,
      chevronDownIcon: faChevronDown,
    };
  },

  watch: {
    visible(val) {
      this.showContent = val;
    },
  },

  created() {
    this.showContent = this.visible;
  },

  methods: {
    toggleCollapse() {
      this.showContent = !this.showContent;
    },
  },
};
</script>
