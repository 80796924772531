<template>
  <div id="rcmApp">
    <IndexPage />
  </div>
</template>

<style>
html {
  overflow-y: hidden;
}

#rcmApp {
  height: 100%;
  width: 100%;
}
</style>

<script>
import IndexPage from '@/pages/IndexPage.vue';

export default {
  name: 'App',
  components: {
    IndexPage,
  },
};
</script>
