<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <vue-bootstrap-typeahead
        v-model="keyword"
        :data="suggestions"
        :serializer="thing => thing.text"
        disable-sort
        show-all-results
        placeholder="Search"
        :max-matches="15"
        @hit="onSubmit"
        @input="updateSearchboxState"
      >
        <template
          slot="suggestion"
          slot-scope="{ data, htmlText }"
        >
          <div class="d-flex align-items-center">
            <span v-if="data.type === 'keyword'">
              <font-awesome-icon :icon="keywordTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'country'">
              <font-awesome-icon :icon="countryTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'forcode'">
              <font-awesome-icon :icon="forcodeTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'seocode'">
              <font-awesome-icon :icon="seocodeTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'sdgcode'">
              <font-awesome-icon :icon="sdgcodeTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'researcher'">
              <font-awesome-icon :icon="researcherTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'department'">
              <font-awesome-icon :icon="departmentTypeIcon" />&nbsp;
            </span>
            <span v-else-if="data.type === 'organisation'">
              <font-awesome-icon :icon="organisationTypeIcon" />&nbsp;
            </span>
            <span v-else>
              <font-awesome-icon :icon="unknownTypeIcon" />&nbsp;
            </span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="ml-4"
              v-html="htmlText"
            />
            <!-- eslint-enable -->
          </div>
        </template>
      </vue-bootstrap-typeahead>
    </b-form>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap';

import { mapState, mapMutations } from 'vuex';

import axios from 'axios';
import _ from 'lodash';

import {
  faEdit,
  faGlobeAsia,
  faFlask,
  faUser,
  faBuilding,
  faUniversity,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SearchBox',

  components: {
    VueBootstrapTypeahead,
  },

  data() {
    return {
      keyword: '',
      content: false,
      suggestions: [],
      suggestionsParameters: {},

      keywordTypeIcon: faEdit,
      countryTypeIcon: faGlobeAsia,
      forcodeTypeIcon: faFlask,
      seocodeTypeIcon: faFlask,
      sdgcodeTypeIcon: faFlask,
      researcherTypeIcon: faUser,
      departmentTypeIcon: faBuilding,
      organisationTypeIcon: faUniversity,
      unknownTypeIcon: faQuestion,
    };
  },

  computed: {
    ...mapState('ui', ['addKeywordOnSubmit']),
  },

  watch: {
    keyword(text) { this.populateSuggestions(text); },
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.keyword = '';
      this.suggestions.length = 0;
    });

    this.$eventBus.$on('submit-button-clicked', () => {
      if (this.addKeywordOnSubmit) {
        this.onSubmit();
        this.updateSearchboxState();
      }
    });

    this.$eventBus.$on('suggestions-parameters', (parameter) => {
      if (parameter.value === null) {
        delete this.suggestionsParameters[parameter.name];
      } else {
        this.$set(this.suggestionsParameters, parameter.name, parameter.value);
      }
    });

    this.$eventBus.$on('update-filter', (payload) => {
      if (payload.filter === 'currentuom') {
        if (payload.item === true) {
          this.$set(this.suggestionsParameters, 'isCurrentUoM', '1');
        } else {
          this.$set(this.suggestionsParameters, 'isCurrentUoM', '0');
        }
      }
    });
  },

  methods: {
    ...mapMutations('ui', ['setAddKeywordOnSubmit']),

    updateSearchboxState() {
      if (this.keyword !== '' && !this.content) {
        this.setAddKeywordOnSubmit(true);
        this.content = true;
      } else if (this.keyword === '' && this.content) {
        this.setAddKeywordOnSubmit(false);
        this.content = false;
      }

      return null;
    },

    populateSuggestions(text) {
      if (text !== '') {
        this.getAutocompleteSuggestions(text)
          .then((response) => {
            this.suggestions = response.data.suggestions;
          })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      }
    },

    getAutocompleteSuggestions(text, mode = 'mixed', top = 15) {
      const parameters = [`q=${text}`, `mode=${mode}`, `top=${top}`];

      if (_.has(this.suggestionsParameters, 'mode')) {
        parameters.push(`mode=${this.suggestionsParameters.mode}`);
      }

      if (_.has(this.suggestionsParameters, 'isCurrentUoM')) {
        parameters.push(`is_current_uom=${this.suggestionsParameters.isCurrentUoM}`);
      }

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/autocomplete${paramString}`;

      return axios.get(url, { withCredentials: true });
    },

    onSubmit(data) {
      if (this.keyword !== '') {
        let thing;

        if (data !== undefined && data.constructor === Object) {
          // The user clicked on an autocomplete item
          // Data is the object from the list
          thing = { type: data.type, value: data.value, text: data.text };
        } else {
          // The user hit 'enter' key
          // Data is the event that triggered the onSubmit
          thing = { type: 'keyword', value: this.keyword, text: this.keyword };
        }

        this.$eventBus.$emit('add-thing-to-query', thing, 'SearchBox');
        this.keyword = '';
        this.suggestions.length = 0;

        this.setAddKeywordOnSubmit(false);
        this.content = false;
      }
    },
  },
};
</script>
