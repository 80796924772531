<template>
  <b-modal
    id="saveQueryModal"
    v-model="modalVisible"
    size="lg"
    cancel-disabled
    ok-disabled
  >
    <div slot="modal-footer" />

    <h5>Save Query</h5>
    <b-form
      v-if="formVisible"
      @submit.prevent="onSaveSubmit"
      @reset.prevent="onSaveReset"
    >
      <b-form-group
        label="Query Title"
        label-for="query-title"
        description="Title of your saved query"
      >
        <b-form-input
          id="query-title"
          v-model="form.title"
          type="text"
          required
        />
      </b-form-group>
      <b-form-group
        label="Query Tags"
        label-for="query-tags"
        description="Tags describing your saved query"
      >
        <b-form-tags
          id="query-title"
          v-model="form.tags"
        />
      </b-form-group>
      <b-button
        id="btn-submit"
        type="submit"
        variant="primary"
      >
        Submit
      </b-button>
      <b-button
        id="btn-reset"
        type="reset"
        variant="danger"
      >
        Reset
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

/**
 * Modal that allows a user to save their 'current' query
 * @class
 */
export default {
  name: 'SaveQueryModal',

  components: {},

  data() {
    return {
      modalVisible: false,
      formVisible: true,

      form: {
        title: '',
        tags: [],
      },
    };
  },

  computed: {
    ...mapGetters('query', ['query']),
  },

  mounted() {
    this.$eventBus.$on('show-save-query-overlay', () => {
      this.modalVisible = true;
    });

    this.$eventBus.$on('hide-save-query-overlay', () => {
      this.modalVisible = false;
    });
  },

  methods: {
    onSaveSubmit() {
      const result = this.saveQuery(
        this.form.title,
        this.query,
        this.form.tags,
      );

      this.form.title = '';
      this.form.tags = [];

      return result;
    },

    onSaveReset() {
      this.form.title = '';
      this.form.tags = [];

      this.formVisible = false;
      this.$nextTick(() => {
        this.formVisible = true;
      });
    },

    saveQuery(title, query, tags) {
      const saveQueryUrl = `${process.env.RCM_API}/api/v1/user/save-query`;
      const payload = { title, query, tags };

      return axios
        .post(saveQueryUrl, payload, { withCredentials: true })
        .then(() => {
          this.modalVisible = false;
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    },
  },
};
</script>
