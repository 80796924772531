/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import _ from 'lodash';

// initial state
const state = {
  result: null,
  originalResult: null,

  researchActivities: [],
};

// getters
const getters = {
  filteredResearchActivities: (state, resultGetters, rootState, rootGetters) => {
    const queryFilters = rootGetters['query/clientMapQueryFilters'];

    const result = state.researchActivities.filter((document) => {
      // Iterate over all the document attributes
      // Filter to just attributes with matching query filters
      // Any 'return true' below will short circuit and skip this document

      const attrsFailed = _
        .toPairs(document)
        .filter((attr) => queryFilters.has(attr[0]))
        .some((attr) => {
          // Go through each query filter item
          // If it fails, it will short circuit and return early with true
          // Otherwise it will go through all items and then return false

          const attrFailed = queryFilters.get(attr[0]).some((item) => {
            if (typeof item === 'string') {
              if (attr[1] === item) {
                return false;
              }
            } else if (typeof item === 'boolean') {
              if (attr[1] === item) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'range'
            ) {
              const value = Number(attr[1]);

              if (item.from <= value && value <= item.to) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'contains'
            ) {
              // Only includes the document if it's values *are* in the item.items
              if (item.items.filter((value) => attr[1].indexOf(value) !== -1).length > 0) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'not_contains'
            ) {
              // Only includes the document if it's values *are not* in the item.items
              if (item.items.filter((value) => attr[1].indexOf(value) !== -1).length === 0) {
                return false;
              }
            } else {
              // eslint-disable-next-line no-console
              console.log(`UNSUPPORTED DATA TYPE DETECTED FOR ${attr[0]}: ${typeof item}`);
              return true;
            }

            // Filters matched, but didn't pass, document isn't valid
            return true;
          });

          return attrFailed;
        });

      return !attrsFailed;
    });

    return result;
  },

  filteredLingo4gIds: (state, mapGetters) => mapGetters.filteredResearchActivities
    .map((document) => document.lingo4gId),

  filteredMapData: (state, mapGetters) => {
    const copiedResult = JSON.parse(state.originalResult);

    if (copiedResult === null) {
      return null;
    }

    const lingo4gIds = mapGetters.filteredLingo4gIds;

    if (lingo4gIds.length === 0) {
      return null;
    }

    // Filter the embedding documents
    copiedResult.documents.embedding.documents = copiedResult.documents.embedding.documents
      .filter((i) => lingo4gIds.includes(i.id));

    // Filter the list documents
    copiedResult.documents.list = copiedResult.documents.list
      .filter((i) => lingo4gIds.includes(i.id));

    // Filter the clusters' documents
    const filterRecursiveClusters = (clusters, ids) => {
      const newClusters = [];
      // TODO replace with map instead of forEach
      clusters.forEach((cluster) => {
        const newCluster = { ...cluster };

        if (cluster.clusters.length > 0) {
          // Go another level deeper if this cluster containers clusters
          newCluster.clusters = filterRecursiveClusters(newCluster.clusters, ids);

          // Remove empty clusters
          newCluster.clusters = cluster.clusters.filter((i) => i.documents.length > 0);
        }

        // Filter *this* clusters documents
        newCluster.documents = newCluster.documents.filter((i) => ids.includes(i.id));

        newClusters.push(newCluster);
      });

      return newClusters;
    };

    copiedResult.documents.arrangement.clusters = filterRecursiveClusters(
      copiedResult.documents.arrangement.clusters, lingo4gIds,
    );

    return copiedResult;
  },
};

// actions
const actions = {
  extractResultData(context) {
    return new Promise((resolve) => {
      const singleValueKeys = ['rcm_id', 'is_uom', 'is_current_uom', 'date', 'size', 'doc_type', 'total_researchers'];
      const multiValueKeys = ['researcher_ids', 'uom_researcher_ids', 'for_code_ids', 'seo_code_ids', 'sdg_code_ids'];

      const researchActivities = state.result.documents.list.map((document) => {
        const resultResearchActivity = {};
        resultResearchActivity.lingo4gId = document.id;

        document.content.forEach((content) => {
          if (singleValueKeys.includes(content.name)) {
            if (content.values.length > 0) {
              if (content.name === 'is_current_uom') {
                resultResearchActivity[content.name] = content.values[0] === 'true';
              } else if (content.name === 'rcm_id') {
                resultResearchActivity[content.name] = Number(content.values[0]);
              } else {
                [resultResearchActivity[content.name]] = content.values;
              }
            }
          } else if (multiValueKeys.includes(content.name)) {
            resultResearchActivity[content.name] = content.values.map((value) => Number(value));
          } else {
            // eslint-disable-next-line no-console
            console.log(`error: extractResultData => Unsupported key found => ${content.name}`);
          }
        });

        return resultResearchActivity;
      });

      // Save to state.researchActivities
      context.commit('setResearchActivities', researchActivities);
      resolve();
    });
  },
};

// mutations
const mutations = {
  setResult(state, result) {
    // Only to be used to set a *new* result object
    state.result = result;
    state.originalResult = JSON.stringify(result);
  },

  setResearchActivities(state, researchActivities) {
    state.researchActivities = researchActivities;
  },

  reset(state) {
    state.result = null;
    state.originalResult = null;

    state.researchActivities = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
