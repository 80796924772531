<template>
  <CollapsableCard
    id="TimeRangeFilter"
    :visible="!!data.length"
    header="Time Range"
  >
    <div v-show="data.length">
      <BarChart
        :chart-data="dataCollection"
        :options="chartOptions"
        :height="200"
      />
      <div style="padding-top: 0px; padding-bottom: 20px;">
        <vue-slider
          v-model="value"
          :data="data"
          :tooltip="'always'"
          :tooltip-placement="'bottom'"
          @drag-start="startedSliding"
          @drag-end="updateFilter(false)"
          @change="updateFilter(true)"
        />
      </div>
    </div>
  </CollapsableCard>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';

import BarChart from '@/components/charts/BarChart.vue';
import CollapsableCard from '@/components/common/CollapsableCard.vue';

import 'vue-slider-component/theme/default.css';

export default {
  name: 'TimeRangeFilter',

  components: {
    VueSlider,
    BarChart,
    CollapsableCard,
  },

  data() {
    return {
      isSliding: false,

      // Start and end year of selected part of slider
      value: [],

      // All years (slider steps)
      data: [],
      dataCollection: {},
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              offset: true,
              ticks: { display: false },
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: { min: 0, beginAtZero: true, display: false },
              gridLines: { display: false },
              display: false,
            },
          ],
        },
      },
    };
  },

  computed: {
    ...mapState('capability', { rawResearchActivities: 'researchActivities' }),
    ...mapGetters('query', ['queryFilterItem']),
  },

  watch: {
    rawResearchActivities() { this.updateData(); },
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.isSliding = false;
      this.value = [];
      this.data = [];
      this.dataCollection = {};
    });
  },

  methods: {
    updateData() {
      const counts = new Map();

      // Update the counts from our researchActivities
      this.rawResearchActivities
        .filter((document) => document.publication_date)
        .forEach((document) => {
          let rawYear = String(document.publication_date).substring(0, 4);

          if (Number(rawYear) <= 1990) {
            rawYear = '<1990';
          }

          if (!counts.has(rawYear)) {
            counts.set(rawYear, 0);
          }

          counts.set(rawYear, counts.get(rawYear) + 1);
        });

      // List of counts in sorted order
      const sorted = Array.from(counts.keys()).sort();

      // Fix the sorting of our 'old bucket' of counts
      // If the last element is '<1990', move it to the front of the array
      // This is a side effect of the above sorting
      if (_.last(sorted) === '<1990') {
        sorted.splice(0, 0, sorted.pop());
      }

      // Setting slider data if it isn't set already
      if (this.value.length === 0 || _.first(this.value) === undefined) {
        let from;
        let to;

        const existingFilter = this.queryFilterItem('timerange');

        if (existingFilter !== null) {
          from = (existingFilter.from === 10000101) ? '<1990' : String(existingFilter.from).substring(0, 4);
          to = (existingFilter.to === 29991231) ? '<1990' : String(existingFilter.to).substring(0, 4);
        } else {
          from = _.first(sorted);
          to = _.last(sorted);
        }

        if (from !== undefined && to !== undefined) {
          this.value = [from, to];
        }
      }

      this.data = sorted;

      // Setting bar chart data
      this.dataCollection = {
        labels: sorted,
        datasets: [
          {
            label: 'Activity count',
            backgroundColor: this.getChartColours(),
            data: sorted.map((year) => counts.get(year)),
            categoryPercentage: 1.0,
            barPercentage: 1.0,
          },
        ],
      };
    },

    getChartColours() {
      // Get the 'bounds' of the blue part of the chart from the slider values
      const start = this.data.indexOf(this.value[0]);
      const end = this.data.indexOf(this.value[1]);

      // Initialise with all blue and change start and end sections to gray
      const colours = Array(this.data.length)
        .fill('#4286f4')
        .fill('#a2a6ad', 0, start)
        .fill('#a2a6ad', end + 1);

      return colours;
    },

    updateFilter(changeTrigger) {
      if (this.isSliding && changeTrigger) {
        // We're sliding the bar, and the source is the 'change' event
        return;
      }

      // Update the charts backgroundColour (bar colours)
      this.dataCollection.datasets[0].backgroundColor = this.getChartColours();

      // Force a new object to be created for reactivity
      this.dataCollection = { ...this.dataCollection };

      // Deselect topic as it may disapper after this update
      this.$eventBus.$emit('topic-deselection');

      this.$eventBus.$emit('update-filter', {
        filter: 'timerange',
        action: 'add',
        item: {
          comparison: 'range',
          from: (this.value[0] === '<1990') ? 10000101 : Number(`${this.value[0]}0101`),
          to: (this.value[1] === '<1990') ? 29991231 : Number(`${this.value[1]}1231`),
        },
      });

      if (this.isSliding) {
        this.isSliding = false;
      }
    },

    startedSliding() {
      this.isSliding = true;
    },
  },
};
</script>
