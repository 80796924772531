/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import axios from 'axios';

// initial state
const state = {
  mapRequestInProgress: false,
  capabilityRequestInProgress: false,
  similarPublicationIdsInProgress: false,
};

const getters = {};

const actions = {
  requestAnalysis(context, payload) {
    context.dispatch('query/setPreviousQuery', null, { root: true });
    context.commit('mapRequestInProgress');
    context.commit('capabilityRequestInProgress');

    const query = context.rootGetters['query/query'];

    // Request and process Map result
    const mapParamString = payload.mapParameters.length > 0 ? `?${payload.mapParameters.join('&')}` : '';
    const mapUrl = `${process.env.RCM_API}/api/v1/analysis${mapParamString}`;

    axios
      .post(mapUrl, query, { withCredentials: true })
      .then((response) => {
        context.commit('map/setResult', response.data, { root: true });
        context.dispatch('map/extractResultData', null, { root: true })
          .then(() => context.commit('mapRequestCompleted'))
          .catch(() => context.commit('mapRequestFailed'));
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        context.commit('mapRequestFailed');
      });

    // Request and process Capability result
    payload.capabilityParameters.push('outputFields=rcm_id,doc_type');
    payload.capabilityParameters.push('esIgnoreFields=abstract,copyrighted_abstract,funding_acknowledgements,keywords');
    const capabilityParamString = payload.capabilityParameters.length > 0 ? `?${payload.capabilityParameters.join('&')}` : '';
    const capabilityUrl = `${process.env.RCM_API}/api/v1/data/enrichactivities${capabilityParamString}`;

    axios
      .post(capabilityUrl, { query }, { withCredentials: true })
      .then((response) => {
        context.commit('capability/setResult', response.data, { root: true });
        context.dispatch('capability/extractResultData', null, { root: true })
          .then(() => context.commit('capabilityRequestCompleted'))
          .catch(() => context.commit('capabilityRequestFailed'));
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        context.commit('capabilityRequestFailed');
      });
  },

  requestSimilarActivities(context, documentPayload) {
    context.commit('requestSimilarActivitiesInProgress');

    const { requestType } = documentPayload;
    const url = `${process.env.RCM_API}/api/v1/query/similaractivities`;

    const payload = {
      mode: 'esmlt',
      type: 'id',
      query: documentPayload.activityIds,
    };

    axios
      .post(url, payload, { withCredentials: true })
      .then((response) => {
        context.commit(`${requestType}/setSimilarResearchActivityIds`, response.data.rcm_ids, { root: true });
        context.commit('requestSimilarActivitiesComplete');
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        context.commit('requestSimilarActivitiesFailed');
      });
  },
};

const mutations = {
  mapRequestInProgress(state) {
    state.mapRequestInProgress = true;
  },

  mapRequestCompleted(state) {
    state.mapRequestInProgress = false;
  },

  mapRequestFailed(state) {
    state.mapRequestInProgress = false;
  },

  capabilityRequestInProgress(state) {
    state.capabilityRequestInProgress = true;
  },

  capabilityRequestCompleted(state) {
    state.capabilityRequestInProgress = false;
  },

  capabilityRequestFailed(state) {
    state.capabilityRequestInProgress = false;
  },

  requestSimilarActivitiesInProgress(state) {
    state.similarActivitiesInProgress = true;
  },

  requestSimilarActivitiesComplete(state) {
    state.similarActivitiesInProgress = false;
  },

  requestSimilarActivitiesFailed(state) {
    state.similarActivitiesInProgress = false;
  },

  reset(state) {
    state.mapRequestInProgress = false;
    state.capabilityRequestInProgress = false;
    state.similarPublicationIdsInProgress = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
