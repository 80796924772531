<template>
  <b-card>
    <b-form @submit.prevent="onSubmit">
      <vue-bootstrap-typeahead
        v-model="inputOrganisationName"
        :data="suggestions"
        :serializer="s => s.text"
        disable-sort
        show-all-results
        placeholder="Organisation Search"
        @hit="onSubmit"
      />
    </b-form>
    <HorizontalBarChart
      :chart-data="chartData"
      :options="chartOptions"
    />
    <b-form-select
      v-model="benchmarkingMeasure"
      :options="measureOptions"
      @change="updateChartMeasure"
    />
  </b-card>
</template>

<script>
import axios from 'axios';
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap';
import { mapGetters } from 'vuex';

import HorizontalBarChart from '@/components/charts/HorizontalBarChart.vue';

export default {
  name: 'CapabilityPairwiseBenchmarking',

  components: {
    HorizontalBarChart,
    VueBootstrapTypeahead,
  },

  data() {
    return {
      results: null,
      inputOrganisationName: '',
      suggestions: [],

      benchmarkSupportedFilters: ['timerange'],

      benchmarkingMeasure: 'count',
      measureOptions: [
        { value: 'count', text: 'Scholarly Works Count' },
        { value: 'citations_total', text: 'Total Citations' },
        { value: 'fcr_gavg', text: 'Field Citation Ratio' },
      ],

      ourOrg: { text: 'University of Melbourne', country: 'Australia' },
      selectedOrg: { text: 'University of Oxford', country: 'United Kingdom' },

      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
          },
        },
        scales: {
          xAxes: [
            {
              offset: true,
              ticks: {
                // min: 0,
                autoSkip: false,
                display: false,
              },
              gridLines: { display: false },
              display: false,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                mirror: true,
                fontColor: 'black',
                labelOffset: -20,
              },
              gridLines: { display: false, drawBorder: false },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              // Show positive value for negative numbers
              label += Math.abs(tooltipItem.xLabel);
              return label;
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('query', ['query']),
  },

  watch: {
    inputOrganisationName(text) { this.populateSuggestions(text); },
  },

  mounted() {
    this.$eventBus.$on('request-analysis', () => this.getBenchmarkData());

    this.$eventBus.$on('filter-analysis', (filtername) => {
      if (this.benchmarkSupportedFilters.includes(filtername)) {
        this.getBenchmarkData(this.selectedOrg);
      }
    });
  },

  methods: {
    getBenchmarkData() {
      const url = `${process.env.RCM_API}/api/v1/capability/pairwisebenchmarking?mode=capability`;

      axios
        .post(url, {
          query: this.query,
          selected_org: this.selectedOrg,
          our_org: this.ourOrg,
        }, { withCredentials: true })
        .then((response) => this.showBenchmarks(
          response.data,
          this.benchmarkingMeasure,
        ))
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    },

    showBenchmarks(results, measure = 'count') {
      this.results = results;

      const dataCollection = {
        labels: [],
        datasets: [
          {
            label: this.ourOrg.text,
            backgroundColor: '#2E5BFF',
            data: [],
            barPercentage: 0.5,
            maxBarThickness: 25,
          },
          {
            label: this.selectedOrg.text,
            backgroundColor: '#EA4F62',
            data: [],
            barPercentage: 0.5,
            maxBarThickness: 25,
          },
        ],
      };

      // Sorting results based on comparison measure
      results.our_org.sort((a, b) => b[measure] - a[measure]);
      results.selected_org.sort((a, b) => b[measure] - a[measure]);

      // Our organisation
      results.our_org.forEach((element) => {
        dataCollection.labels.push(element.name);
        dataCollection.datasets[0].data.push(
          -Math.round(element[measure] * 100) / 100,
        );
      });

      // Selected organisation
      results.selected_org.forEach((element) => {
        dataCollection.datasets[1].data.push(
          Math.round(element[measure] * 100) / 100,
        );
      });

      // New chart data
      this.chartData = dataCollection;

      // Changing chart options to keep the center of the chart fixed
      const maxBarLength = Math.max(
        results.our_org[0][measure],
        results.selected_org[0][measure],
      );

      this.chartOptions.scales.xAxes[0].ticks.max = maxBarLength;
      this.chartOptions.scales.xAxes[0].ticks.min = -maxBarLength;
      this.chartOptions = { ...this.chartOptions };
    },

    updateSelectedOrg(selectedOrg) {
      this.selectedOrg = selectedOrg;
      this.$eventBus.$emit('new-selected-org', selectedOrg);
    },

    updateChartMeasure(selectedMeasure) {
      this.showBenchmarks(this.results, selectedMeasure);
    },

    populateSuggestions(text) {
      if (text !== '') {
        this.getAutocompleteSuggestions(text)
          .then((response) => {
            this.suggestions = response.data.suggestions;
          })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      }
    },

    getAutocompleteSuggestions(text, mode = 'organisations') {
      const parameters = [`q=${text}`, `mode=${mode}`];

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/autocomplete${paramString}`;

      return axios.get(url, { withCredentials: true });
    },

    onSubmit() {
      if (this.inputOrganisationName !== '') {
        this.suggestions.some((org) => {
          if (org.text === this.inputOrganisationName) {
            this.updateSelectedOrg({ text: org.text, country: org.country });
            return true;
          }

          return false;
        });

        this.getBenchmarkData();

        this.inputOrganisationName = '';
        this.organisation = '';
      }
    },
  },
};
</script>
