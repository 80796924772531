<template>
  <b-card id="topicInsights">
    <div v-show="numActivities">
      <h6 v-show="numResearchers">
        <font-awesome-icon
          :icon="researcherIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numResearchers }}</span>&nbsp;
        <span class="brag-text">Researchers</span>
      </h6>
      <h6 v-show="numPublications">
        <font-awesome-icon
          :icon="publicationIcon"
          style="color: #4074b2;"
        />&nbsp;
        <span class="brag-number">{{ numPublications }}</span>&nbsp;
        <span class="brag-text">Scholarly Works</span>
      </h6>
      <h6 v-show="numCitations && numCitations!=='0'">
        <font-awesome-icon
          :icon="citationIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numCitations }}</span>&nbsp;
        <span class="brag-text">Citations</span>
      </h6>
      <h6 v-show="numPolicies && numPolicies!=='0'">
        <font-awesome-icon
          :icon="publicationIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numPolicies }}</span>&nbsp;
        <span class="brag-text">Policies</span>
      </h6>
      <h6 v-show="numPatents">
        <br>
        <font-awesome-icon
          :icon="patentIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numPatents }}</span>&nbsp;
        <span class="brag-text">Patents</span>
      </h6>
      <h6 v-show="numClinicalTrials">
        <br>
        <font-awesome-icon
          :icon="clinicalTrialIcon"
          style="color: #4074B2;"
        />&nbsp;
        <span class="brag-number">{{ numClinicalTrials }}</span>&nbsp;
        <span class="brag-text">Clinical Trials</span>
      </h6>
      <div v-show="numProjects">
        <br>
        <h6>
          <font-awesome-icon
            :icon="projectCountIcon"
            style="color: #4074B2;"
          />&nbsp;
          <span class="brag-number">{{ numProjects }}</span>&nbsp;
          <span class="brag-text">Projects*</span>
        </h6>
        <h6 v-show="funding !== '$0'">
          <font-awesome-icon
            :icon="projectAmountIcon"
            style="color: #4074B2;"
          />&nbsp;
          <span class="brag-number">{{ funding }}</span>&nbsp;
          <span class="brag-text">Funding (USD)*</span>
        </h6>
        <div style="font-size: 0.75rem;">
          * Approximation and from a third party datasource
        </div>
      </div>
    </div>
  </b-card>
</template>

<style>
.brag-number {
  /* color: #094183; */
  font-size: .9em;
  font-weight: bolder;
}

.brag-text {
  /* color: #4074B2; */
  font-size: .9em;
  /* font-weight: bold; */
}

.inline-title {
  display: inline-block;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

import {
  faFileAlt,
  faUser,
  faComments,
  faMoneyCheckAlt,
  faHandHoldingUsd,
  faCertificate,
  faVial,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'TopicInsights',

  data() {
    return {
      numResearchers: 0,
      numActivities: 0,

      numPublications: 0,
      numCitations: 0,
      numPolicies: 0,

      numProjects: 0,
      funding: 0,

      numPatents: 0,
      numClinicalTrials: 0,

      researcherIcon: faUser,
      publicationIcon: faFileAlt,
      citationIcon: faComments,
      projectCountIcon: faMoneyCheckAlt,
      projectAmountIcon: faHandHoldingUsd,
      patentIcon: faCertificate,
      clinicalTrialIcon: faVial,
    };
  },

  computed: {
    ...mapGetters('query', ['previousQuery', 'queryGroups']),
    ...mapGetters('topic', {
      topicResearchActivities: 'filteredResearchActivities',
      topicPublications: 'filteredPublications',
      topicProjectSummary: 'filteredProjectsSummary',
      topicPatents: 'filteredPatents',
      topicClinicalTrials: 'filteredClinicalTrials',
    }),
    ...mapState('topic', { topicResearchers: 'researchers' }),
  },

  watch: {
    topicResearchActivities() { this.updateResearchActivitySummary(); },
    topicPublications() { this.updatePublicationsSummary(); },
    topicProjectSummary() { this.updateProjectsSummary(); },
    topicPatents() { this.updatePatentsSummary(); },
    topicClinicalTrials() { this.updateClinicalTrialsSummary(); },
    topicResearchers() { this.updateResearchersSummary(); },
  },

  methods: {
    updateResearchActivitySummary() {
      this.numActivities = this.topicResearchActivities.length;
    },

    updatePublicationsSummary() {
      this.numPublications = this.topicPublications.length;

      const rawCitationCount = this.topicPublications
        .filter((publication) => _.has(publication, 'citations'))
        .reduce((count, publication) => count + publication.citations, 0);

      this.numCitations = Number(rawCitationCount).toLocaleString('en-AU');

      const rawPolicyCount = this.topicPublications
        .filter((publication) => _.has(publication, 'policy_ids'))
        .reduce((count, publication) => count + (publication.policy_ids).length, 0);

      this.numPolicies = Number(rawPolicyCount).toLocaleString('en-AU');
    },

    updateResearchersSummary() {
      this.numResearchers = this.topicResearchers.length;
    },

    updateProjectsSummary() {
      this.numProjects = this.topicProjectSummary.numProjects;
      this.funding = '$'.concat(this.topicProjectSummary.funding.toLocaleString('en-AU'));
    },

    updatePatentsSummary() {
      this.numPatents = this.topicPatents.length;
    },

    updateClinicalTrialsSummary() {
      this.numClinicalTrials = this.topicClinicalTrials.length;
    },
  },
};
</script>
