<template>
  <div id="topicBenchmarks">
    <b-card no-body>
      <b-tabs content-class="mt-3">
        <b-tab :title="localCountry">
          <BarChart
            :chart-data="localChartData"
            :options="chartOptions"
          />
          <div class="mx-3 mb-3">
            <b-form-select
              v-model="localBenchmarkingMeasure"
              :options="measureOptions"
              @change="updateLocalChartMeasure"
            />
          </div>
        </b-tab>
        <b-tab title="Global">
          <BarChart
            :chart-data="globalChartData"
            :options="chartOptions"
          />
          <div class="mx-3 mb-3">
            <b-form-select
              v-model="globalBenchmarkingMeasure"
              :options="measureOptions"
              @change="updateGlobalChartMeasure"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <TopicPairwiseBenchmarking />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

import BarChart from '@/components/charts/BarChart.vue';
import TopicPairwiseBenchmarking from '@/components/topic_pane/TopicPairwiseBenchmarking.vue';
import { getTopicsFromResearchActivityIds } from '@/helpers/utils';

export default {
  name: 'TopicBenchmarks',

  components: {
    BarChart,
    TopicPairwiseBenchmarking,
  },

  data() {
    return {
      benchmarkSupportedFilters: ['timerange'],
      topics: [],
      exemplar_topics: [],

      localBenchmarks: [],
      globalBenchmarks: [],

      localCountry: 'Australia',

      selectedOrg: { text: 'University of Oxford', country: 'United Kingdom' },

      localBenchmarkingMeasure: 'citations_total',
      globalBenchmarkingMeasure: 'citations_total',

      measureOptions: [
        { value: 'count', text: 'Scholarly Works Count' },
        { value: 'citations_total', text: 'Total Citations' },
        { value: 'fcr_gavg', text: 'Field Citation Ratio' },
      ],

      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              offset: true,
              ticks: {
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    ...mapGetters('query', ['previousQuery', 'queryFilters']),
    ...mapState('result', ['rcm2l4g']),

    localChartData() {
      return this.createChartData(this.localBenchmarks, this.localCountry);
    },

    globalChartData() {
      return this.createChartData(this.globalBenchmarks, 'global');
    },
  },

  mounted() {
    this.$eventBus.$on('topic-selection', (publicationIds) => {
      getTopicsFromResearchActivityIds(
        publicationIds.map((publicationId) => this.rcm2l4g.get(publicationId)),
      )
        .then((response) => {
          this.topics = response.data.topics;
          this.exemplar_topics = response.data.exemplar_topics;

          this.getBenchmarkData(
            this.previousQuery,
            this.localCountry,
            this.localBenchmarkingMeasure,
          );

          this.getBenchmarkData(
            this.previousQuery,
            'global',
            this.globalBenchmarkingMeasure,
          );
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    });

    this.$eventBus.$on('filter-analysis', (filtername) => {
      if (this.benchmarkSupportedFilters.includes(filtername)) {
        const newQuery = this.previousQuery;
        newQuery.filters = this.queryFilters;

        this.getBenchmarkData(newQuery, this.localCountry, this.localBenchmarkingMeasure);
        this.getBenchmarkData(newQuery, 'global', this.globalBenchmarkingMeasure);
      }
    });

    this.$eventBus.$on('new-selected-org', (selectedOrg) => {
      this.selectedOrg = selectedOrg;
      this.getBenchmarkData(
        this.previousQuery,
        this.localCountry,
        this.localBenchmarkingMeasure,
      );

      this.getBenchmarkData(
        this.previousQuery,
        'global',
        this.globalBenchmarkingMeasure,
      );
    });
  },

  methods: {
    getBenchmarkData(query, country, measure) {
      const url = `${process.env.RCM_API}/api/v1/capability/benchmarking?mode=topic`;

      const payload = {
        topics: this.exemplar_topics,
        query,
        country,
        measure,
        selectedOrg: this.selectedOrg,
      };

      axios
        .post(url, payload, { withCredentials: true })
        .then((response) => {
          if (country === this.localCountry) {
            this.localBenchmarks = response.data.data;
          } else {
            this.globalBenchmarks = response.data.data;
          }
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    },

    createChartData(benchmarkData, country) {
      let measure;

      if (country === this.localCountry) {
        measure = this.localBenchmarkingMeasure;
      } else {
        measure = this.globalBenchmarkingMeasure;
      }

      const dataCollection = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      };

      // Sort research organisations by name
      const sortedBenchmarkData = benchmarkData.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 1;
      });

      // Add organisation name and average FCR to chart data
      sortedBenchmarkData.forEach((element) => {
        dataCollection.labels.push(element.name);
        dataCollection.datasets[0].data.push(element[measure]);

        // Distinctive bar color for UniMelb
        if (element.name === 'University of Melbourne') {
          dataCollection.datasets[0].backgroundColor.push(
            'rgba(9, 65, 131, 0.7)',
          );
        } else if (element.name === this.selectedOrg.text) {
          dataCollection.datasets[0].backgroundColor.push(
            'rgba(234, 79, 98, 0.7)',
          );
        } else {
          dataCollection.datasets[0].backgroundColor.push(
            'rgba(125, 170, 224, 0.7)',
          );
        }
      });
      return dataCollection;
    },

    updateLocalChartMeasure(selectedMeasure) {
      this.getBenchmarkData(
        this.previousQuery,
        this.localCountry,
        selectedMeasure,
      );
    },

    updateGlobalChartMeasure(selectedMeasure) {
      this.getBenchmarkData(
        this.previousQuery,
        'global',
        selectedMeasure,
      );
    },
  },
};
</script>
