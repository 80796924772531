<template>
  <div id="queryBuilder">
    <div
      id="rcm-query-builder-scroll"
      class="rcm-query-builder-scroll"
    >
      <div
        v-for="(group, groupIndex) of queryGroups"
        :key="groupIndex"
        style="padding-bottom: 5px;"
      >
        <b-container
          v-show="groupIndex > 0"
          fluid
        >
          <b-row
            no-gutters
            align-h="center"
          >
            <b-col cols="4">
              <div style="padding-bottom: 5px; width: 5.5em;">
                <b-form-select
                  v-model="queryGroupRowOperators[groupIndex]"
                  :options="queryGroupRowOperatorValues"
                  size="sm"
                  @change="(value) => updateQueryGroupRowOperator(value, groupIndex)"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-card body-class="rcm-query-builder-row">
          <div class="rcm-top-right rcm-row-help-text">
            <a
              href="#"
              @click.prevent="deleteQueryGroup(groupIndex)"
            >
              <font-awesome-icon :icon="timesCircleIcon" />
            </a>
          </div>
          <div class="rcm-query-builder-row-chips">
            <span
              v-for="(item, itemIndex) of queryGroupItems(groupIndex)"
              :key="itemIndex"
              style="padding-right: 5px;"
            >
              <md-chip
                md-clickable
                md-deletable
                @md-delete="(event) => deleteChipItem(event, itemIndex, groupIndex)"
              >
                <div @click="(event) => clickChipItem(event, itemIndex, groupIndex)">
                  <font-awesome-icon :icon="getThingTypeIcon(item)" />
                  &nbsp;
                  {{ (typeof item === "string") ? item : item.text }}
                </div>
              </md-chip>
            </span>
          </div>

          <div class="rcm-row-help-text">
            <a
              href="#"
              @click.prevent="toggleQueryGroupAutocomplete(groupIndex)"
            >
              <font-awesome-icon :icon="plusCircleIcon" /> <b>add term</b>
            </a>
            <!-- Distinct researchActivities that query group contributes -->
            <span style="float: right; text-align: right;">
              <font-awesome-icon
                :icon="hasNewQueryGroupItems(groupIndex) ? newActivityIcon : analysedActivityIcon"
                :style="hasNewQueryGroupItems(groupIndex) ?
                  { color: newActivityColor } : { color: analysedActivityColor}"
                size="sm"
              />

              <span v-if="removingResearchActivitiesCount(groupIndex)">
                Removing {{ removingResearchActivitiesCount(groupIndex) }} above activities
              </span>
              <span v-else>
                Contributes
                {{ distinctResearchActivitiesCount(groupIndex) }} of
                {{ groupResearchActivitiesCount(groupIndex) }} activities
              </span>
            </span>
          </div>

          <b-form @submit.prevent="(data) => addToQueryGroupRow(data, groupIndex)">
            <vue-bootstrap-typeahead
              v-show="queryGroupRowVisibility[groupIndex]"
              :ref="'query-group-row-autocomplete-' + String(groupIndex)"
              v-model="queryGroupRowKeywords[groupIndex]"
              :data="queryGroupRowSuggestions[groupIndex]"
              :serializer="thing => thing.text"
              placeholder="and/or/not ..."
              disable-sort
              show-all-results
              @hit="(data) => addToQueryGroupRow(data, groupIndex)"
              @input="(data) => populateSuggestions(data, groupIndex)"
            >
              <template
                slot="suggestion"
                slot-scope="{ data, htmlText }"
              >
                <div class="d-flex align-items-center">
                  <span v-if="data.type === 'keyword'">
                    <font-awesome-icon :icon="keywordTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'country'">
                    <font-awesome-icon :icon="countryTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'forcode'">
                    <font-awesome-icon :icon="forcodeTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'seocode'">
                    <font-awesome-icon :icon="seocodeTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'sdgcode'">
                    <font-awesome-icon :icon="sdgcodeTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'researcher'">
                    <font-awesome-icon :icon="researcherTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'department'">
                    <font-awesome-icon :icon="departmentTypeIcon" />&nbsp;
                  </span>
                  <span v-else-if="data.type === 'organisation'">
                    <font-awesome-icon :icon="organisationTypeIcon" />&nbsp;
                  </span>
                  <span v-else>
                    <font-awesome-icon :icon="unknownTypeIcon" />&nbsp;
                  </span>
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    class="ml-4"
                    v-html="htmlText"
                  />
                  <!-- eslint-enable -->
                </div>
              </template>
            </vue-bootstrap-typeahead>
          </b-form>
        </b-card>
      </div>
      <div
        v-show="excludedResearchActivityIds.length"
        style="padding-top: 10px;"
      >
        <font-awesome-icon
          :icon="excludedActivityIcon"
          class="excluded-activity-icon"
        />
        Excluding activities for:
        <br>
        <span
          v-for="(item, itemIndex) of excludedTopicLabels"
          :key="itemIndex"
          style="padding-right: 5px;"
        >
          <md-chip
            md-deletable
            @md-delete="onExcludeLabelDelete(itemIndex)"
          >
            <font-awesome-icon :icon="researchActivityIdsTypeIcon" />
            &nbsp;
            {{ getFirstTwoKeywords(item) }}
          </md-chip>
        </span>
        <div
          class="rcm-row-help-text"
          style="padding-right: 5px;"
          align="right"
        >
          Removing {{ excludedResearchActivityIds.length }} above activities
        </div>
      </div>
    </div>
    <div
      v-show="queryGroups.length && queryGroups.length > 3"
      id="rcm-query-builder-expand"
      align="middle"
    >
      <hr style="margin-top: 0.5rem; margin-bottom: 0rem;">
      <a
        href="#"
        @click.prevent="toggleQueryBuilderExpanded()"
      >
        <font-awesome-icon :icon="(queryBuilderExpanded) ? collapseCircleIcon : expandCircleIcon" />
        {{ (queryBuilderExpanded) ? 'Collapse' : 'Expand' }}
      </a>
    </div>
    <div
      v-show="!!queryGroups.length"
      class="md-helper-text document-count text-center"
    >
      <!-- Total researchActivities count -->
      <font-awesome-icon
        :icon="queryGroups.some(
          (element, groupIndex) => hasNewQueryGroupItems(groupIndex)
        ) ? activityIcon : analysedActivityIcon"
        :style="queryGroups.some(
          (element, groupIndex) => hasNewQueryGroupItems(groupIndex)
        ) ? { color: newActivityColor } : { color: analysedActivityColor}"
        size="sm"
      />
      <span :style="{color: colorTotalResearchActivitiesCount}">
        {{ totalResearchActivitiesCount }} Distinct Research Activities
        <div v-if="colorTotalResearchActivitiesCount === red">
          (Add more activities to improve the map)
        </div>
      </span>
    </div>
  </div>
</template>

<style>
.no-padding {
  padding: 0;
}

.rcm-top-right {
  position: absolute;
  top: 0px;
  right: 3px;
}

.rcm-query-builder-scroll-collapsed {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.document-count {
  color: gray;
  padding-top: 1em;
}

.excluded-activity-icon {
  color: rgb(238, 136, 137);
  font-size: smaller;
}

.rcm-row-help-text {
  font-size: smaller;
}

.rcm-query-builder-row {
  padding: 0.75em 0.5em 0.25em 0.5em;
}

.rcm-query-builder-row-chips {
  padding-bottom: 0.25em;
}
</style>

<script>
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap';
import axios from 'axios';
import _ from 'lodash';

import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

import { faTimesCircle, faCopy } from '@fortawesome/free-regular-svg-icons';

import {
  faEdit,
  faGlobeAsia,
  faFlask,
  faUser,
  faBuilding,
  faUniversity,
  faQuestion,
  faFileAlt,
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faQuestionCircle,
  faArrowCircleDown,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';

import { newQueryGroupItem, newQueryGroup } from '@/helpers/query';

export default {
  name: 'QueryBuilder',

  components: {
    VueBootstrapTypeahead,
  },

  data() {
    return {
      queryGroupRowKeywords: [],
      queryGroupRowSuggestions: [],
      queryGroupRowVisibility: [],
      queryGroupRowOperators: [],
      queryGroupRowSuggestionsParameters: {},

      defaultQueryGroupRowOperator: 'OR',
      queryGroupRowOperatorValues: [
        { value: 'OR', text: 'OR' },
        { value: 'AND', text: 'AND' },
        { value: 'NOT', text: 'NOT' },
      ],

      // The list of document ids lists to be filtered for Topics See Less
      allExcludedTopicDocIds: [],

      // A list of labels representing the topic for each cluster of publications
      excludedTopicLabels: [],

      plusCircleIcon: faPlusCircle,
      timesCircleIcon: faTimesCircle,

      queryBuilderExpanded: true,
      expandCircleIcon: faArrowCircleDown,
      collapseCircleIcon: faArrowCircleUp,

      keywordTypeIcon: faEdit,
      countryTypeIcon: faGlobeAsia,
      forcodeTypeIcon: faFlask,
      seocodeTypeIcon: faFlask,
      sdgcodeTypeIcon: faFlask,
      researcherTypeIcon: faUser,
      departmentTypeIcon: faBuilding,
      organisationTypeIcon: faUniversity,
      researchActivityIdsTypeIcon: faCopy,
      unknownTypeIcon: faQuestion,

      activityIcon: faFileAlt,
      analysedActivityIcon: faCheckCircle,
      analysedActivityColor: 'rgb(89, 177, 127)', // Green
      newActivityIcon: faQuestionCircle,
      newActivityColor: 'rgb(153, 162, 170)', // Gray
      excludedActivityIcon: faMinusCircle,

      lastSelectedChip: null,
      latestTopicSelection: false,

      red: 'rgb(238, 136, 137)',
      orange: 'rgb(244, 182, 66)',
      green: 'rgb(89, 177, 127)',
    };
  },

  computed: {
    ...mapGetters('query', [
      'query',
      'queryGroups',
      'queryGroup',
      'queryGroupItems',
      'previousQuerySet',
      'totalResearchActivitiesCount',
      'distinctResearchActivitiesCount',
      'removingResearchActivitiesCount',
      'excludedResearchActivityIds',
      'groupResearchActivitiesCount',
      'hasNewQueryGroupItems',
    ]),

    colorTotalResearchActivitiesCount() {
      if (this.totalResearchActivitiesCount < 100) {
        return this.red;
      }

      if (this.totalResearchActivitiesCount < 200) {
        return this.orange;
      }

      return this.green;
    },
  },

  watch: {
    queryGroups() {
      // Cover the case when this is updated out-of-band
      if (this.queryGroupRowKeywords.length === 0) {
        this.queryGroups.forEach(() => {
          this.queryGroupRowKeywords.push('');
          this.queryGroupRowSuggestions.push([]);
          this.queryGroupRowVisibility.push(false);
          this.queryGroupRowOperators.push(this.defaultQueryGroupRowOperator);
        });
      }
    },
  },

  mounted() {
    this.$eventBus.$on('add-thing-to-query', (thing) => this.addThingAsQueryGroup(thing));
    this.$eventBus.$on('add-query-group', (group) => this.addQueryGroup(group));

    this.$eventBus.$on('topic-filter', (filter) => {
      if (filter.action === 'more') {
        const thing = {
          type: 'keyword',
          value: filter.labels[0],
          text: filter.labels[0],
        };
        this.addThingAsQueryGroup(thing);
      } else if (filter.action === 'less') {
        // Show the label of the topic that is going to be filtered
        this.excludedTopicLabels.push(filter.labels);

        // Keep researchActivities Ids as sub-list (for future use when label is deleted)
        this.allExcludedTopicDocIds.push(filter.docs);

        this.updateQueryFilter();
      }
    });

    this.$eventBus.$on('request-analysis', () => {
      this.setAllQueryGroupsItemStatus('added');
      this.toggleQueryBuilderExpanded(false);
      this.hideAllQueryBuilderAutocomplete();
    });

    this.$eventBus.$on('topic-selection', () => {
      this.latestTopicSelection = false;
    });
    this.$eventBus.$on('topic-deselection', () => {
      this.lastSelectedChip = null;
    });
    this.$eventBus.$on('researcher-selection', () => {
      this.lastSelectedChip = null;
      this.latestTopicSelection = false;
    });

    this.$eventBus.$on('update-filter', (payload) => {
      if (payload.filter === 'currentuom') {
        if (payload.item === true) {
          this.$set(this.queryGroupRowSuggestionsParameters, 'isCurrentUoM', '1');
        } else {
          this.$set(this.queryGroupRowSuggestionsParameters, 'isCurrentUoM', '0');
        }
      }
    });
  },

  created() {
    if (this.queryGroupRowKeywords.length === 0) {
      this.queryGroups.forEach(() => {
        this.queryGroupRowKeywords.push('');
        this.queryGroupRowSuggestions.push([]);
        this.queryGroupRowVisibility.push(false);
        this.queryGroupRowOperators.push(this.defaultQueryGroupRowOperator);
      });
    }
  },

  methods: {
    ...mapMutations('query', [
      'setAllQueryGroupsItemStatus',
      'addGroup',
      'deleteGroup',
      'deleteGroupItem',
      'setQueryGroupPublicationIds',
      'spliceGroupItem',
      'setGroupRowOperator',
    ]),
    ...mapMutations('result', ['updateIdTranslations']),
    ...mapActions('topic', ['topicSelection']),

    getThingTypeIcon(thing) {
      switch (thing.type) {
        case 'keyword':
          return this.keywordTypeIcon;
        case 'country':
          return this.countryTypeIcon;
        case 'forcode':
          return this.forcodeTypeIcon;
        case 'seocode':
          return this.seocodeTypeIcon;
        case 'sdgcode':
          return this.sdgcodeTypeIcon;
        case 'researcher':
          return this.researcherTypeIcon;
        case 'department':
          return this.departmentTypeIcon;
        case 'organisation':
          return this.organisationTypeIcon;
        case 'rcmIds':
          return this.researchActivityIdsTypeIcon;
        default:
          return this.unknownTypeIcon;
      }
    },

    // Query Group Handling
    addThingAsQueryGroup(thing) {
      const queryGroupItem = newQueryGroupItem(thing);
      const queryGroup = newQueryGroup([queryGroupItem]);
      this.addQueryGroup(queryGroup);
    },

    addQueryGroup(queryGroup) {
      // Add the Query Group and get its index
      this.addGroup(queryGroup);
      const queryGroupIndex = this.queryGroups.length - 1;

      this.queryGroupRowKeywords.push('');
      this.queryGroupRowSuggestions.push([]);
      this.queryGroupRowVisibility.push(false);
      this.queryGroupRowOperators.push(queryGroup.operator);

      // Tell everyone of the update
      this.$eventBus.$emit('added-query-group', queryGroupIndex);

      // Populate document ids
      this.$nextTick(() => this.refreshResearchActivityIdCounts(queryGroupIndex));
    },

    refreshResearchActivityIdCounts(queryGroupIndex) {
      this.getResearchActivityIdsFromQueryGroupIndex(queryGroupIndex)
        .then((response) => {
          const researchActivities = response.data.research_activities;

          const researchActivityIds = [];

          const rcm2l4g = new Map();
          const l4g2rcm = new Map();

          researchActivities.forEach((document) => {
            document.content.some((content) => {
              if (content.name !== 'rcm_id') {
                return false;
              }

              const rcmId = Number(content.values[0]);
              const l4gId = Number(document.id);

              rcm2l4g.set(rcmId, l4gId);
              l4g2rcm.set(l4gId, rcmId);

              researchActivityIds.push(rcmId);

              return true;
            });
          });

          // Set this query group's rcmIds
          this.setQueryGroupPublicationIds({
            index: queryGroupIndex,
            researchActivityIds,
          });

          // Update the translations in result store
          this.updateIdTranslations({ rcm2l4g, l4g2rcm });
        })
        .catch((error) => console.log(error)); // eslint-disable-line no-console
    },

    getResearchActivityIdsFromQueryGroupIndex(queryGroupIndex) {
      const parameters = [
        'queryType=group',
        `queryGroupIndex=${queryGroupIndex}`,
        'mode=query',
        'outputFields=rcm_id,doc_type',
      ];

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/researchactivities${paramString}`;

      return axios.post(url, this.query, { withCredentials: true });
    },

    deleteQueryGroup(queryGroupIndex) {
      this.deleteGroup(queryGroupIndex);

      this.queryGroupRowKeywords.splice(queryGroupIndex, 1);
      this.queryGroupRowSuggestions.splice(queryGroupIndex, 1);
      this.queryGroupRowVisibility.splice(queryGroupIndex, 1);
      this.queryGroupRowOperators.splice(queryGroupIndex, 1);

      this.$eventBus.$emit('deleted-query-group', queryGroupIndex);
    },

    addQueryGroupItemToQueryGroup(queryGroupItem, queryGroupIndex) {
      // Add to queryGroups
      const queryGroupItemIndex = this.queryGroup(queryGroupIndex).items.length;
      this.spliceGroupItem({
        groupIndex: queryGroupIndex,
        itemIndex: queryGroupItemIndex,
        item: queryGroupItem,
      });

      this.$eventBus.$emit(
        'added-query-group-item',
        queryGroupIndex,
        queryGroupItemIndex,
      );
      this.$eventBus.$emit('updated-query-group', queryGroupIndex);

      // Refresh document ids
      this.$nextTick(() => this.refreshResearchActivityIdCounts(queryGroupIndex));
    },

    // Chip Handling
    getChipKeywordData(text, defaultOperator = 'AND') {
      if (text === '') {
        return ['', defaultOperator, true];
      }

      let regex = null;
      let operator = null;
      let defaultUsed = false;
      const textLower = text.toLowerCase();

      // Parse the Boolean operator off the front
      // Words have to have a space, symbols the space is optional
      if (textLower.startsWith('and ') || text.startsWith('+')) {
        regex = /(and |\+)\s*/i;
        operator = 'AND';
      } else if (textLower.startsWith('not ') || text.startsWith('-')) {
        regex = /(not |-)\s*/i;
        operator = 'NOT';
      } else if (textLower.startsWith('or ') || text.startsWith('|')) {
        regex = /(or |\|)\s*/i;
        operator = 'OR';
      } else {
        // If the operator cannot be found, default to AND
        operator = defaultOperator;
        defaultUsed = true;
      }

      // Remove the boolean operator, sanitize the word and return with the operator
      const keyword = text.replace(regex, '').trim();

      return [keyword, operator, defaultUsed];
    },

    deleteChipItem(itemValue, itemIndex, queryGroupIndex) {
      this.deleteGroupItem({
        groupIndex: queryGroupIndex,
        itemIndex,
      });

      // Delete the Query Group if it's empty
      if (this.queryGroup(queryGroupIndex).items.length === 0) {
        this.$nextTick(() => this.deleteQueryGroup(queryGroupIndex));
      } else {
        this.$eventBus.$emit('updated-query-group', queryGroupIndex);

        // Refresh document ids
        this.$nextTick(() => this.refreshResearchActivityIdCounts(queryGroupIndex));
      }
    },

    clickChipItem(itemText, itemIndex, queryGroupIndex) {
      const queryGroup = this.queryGroup(queryGroupIndex);
      this.$eventBus.$emit('selected-query-group', queryGroupIndex);

      // Trigger topic pane only when Explore button has been clicked
      if (this.previousQuerySet) {
        if (this.lastSelectedChip === queryGroup && this.latestTopicSelection) {
          this.lastSelectedChip = null;
          this.latestTopicSelection = false;

          this.$eventBus.$emit('topic-deselection');
        } else {
          this.lastSelectedChip = queryGroup;
          // The 3rd parameter specifies that topic selection is not emitted from clicking
          // on a cluster on the map, ie. fromMap=false

          const rcmIds = queryGroup.researchActivityIds;

          this.$eventBus.$emit('topic-selection', rcmIds, queryGroup, false);
          this.topicSelection({ rcmIds, sourceObject: queryGroup, source: 'QueryBuilder' });

          this.$nextTick(() => {
            this.latestTopicSelection = true;
          });
        }
      }
    },

    // Query Filter Handling
    updateQueryFilter() {
      // When new query filter data has been added
      let excludeFilterItem = null;

      if (this.allExcludedTopicDocIds.length > 0) {
        excludeFilterItem = {
          comparison: 'not-contains',

          // Get distinct values from all excluded publications
          items: [...new Set(this.allExcludedTopicDocIds.flat())].map(String),
        };
      }

      this.$eventBus.$emit('update-filter', {
        filter: 'research_activity_ids',
        action: 'add',
        item: excludeFilterItem,
      });
    },

    onExcludeLabelDelete(index) {
      // When user removes a topic that they have already added to be filtered
      this.allExcludedTopicDocIds.splice(index, 1);
      this.excludedTopicLabels.splice(index, 1);

      this.updateQueryFilter();
    },

    // Query Autocomplete
    addToQueryGroupRow(data, queryGroupIndex) {
      let operator;
      let thing;

      let text;

      if (data !== undefined && data.constructor === Object) {
        // The user clicked on an autocomplete item
        // Data is the object from the list
        [text, operator] = this.getChipKeywordData(data.text);

        thing = { type: data.type, value: data.value, text };
      } else {
        // The user hit 'enter' key
        // Data is the event that triggered the onSubmit
        const keywordText = this.queryGroupRowKeywords[queryGroupIndex];
        [text, operator] = this.getChipKeywordData(keywordText);

        thing = { type: 'keyword', value: text, text };
      }

      // Create the query group item and add into the query group
      const queryGroupItem = newQueryGroupItem(thing, operator);
      this.addQueryGroupItemToQueryGroup(queryGroupItem, queryGroupIndex);

      // Clean up
      this.queryGroupRowKeywords.splice(queryGroupIndex, 1, '');
      this.queryGroupRowSuggestions.splice(queryGroupIndex, 1, []);
      this.queryGroupRowVisibility.splice(queryGroupIndex, 1, false);
    },

    populateSuggestions(text, queryGroupIndex) {
      const [keywordText, operator, defaultUsed] = this.getChipKeywordData(text);

      if (keywordText !== '') {
        this.getAutocompleteSuggestions(keywordText)
          .then((response) => {
            const suggestions = response.data.suggestions.map((suggestion) => {
              const newSuggestion = suggestion;

              if (!defaultUsed) {
                newSuggestion.text = `${operator} ${suggestion.text}`;
              }

              return newSuggestion;
            });

            this.queryGroupRowSuggestions.splice(queryGroupIndex, 1, suggestions);
          })
          .catch((error) => console.log(error)); // eslint-disable-line no-console
      }
    },

    getAutocompleteSuggestions(text, mode = 'mixed', top = 15) {
      const parameters = [`q=${text}`, `mode=${mode}`, `top=${top}`];

      if (_.has(this.queryGroupRowSuggestionsParameters, 'mode')) {
        parameters.push(`mode=${this.queryGroupRowSuggestionsParameters.mode}`);
      }

      if (_.has(this.queryGroupRowSuggestionsParameters, 'isCurrentUoM')) {
        parameters.push(`is_current_uom=${this.queryGroupRowSuggestionsParameters.isCurrentUoM}`);
      }

      const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
      const url = `${process.env.RCM_API}/api/v1/query/autocomplete${paramString}`;

      return axios.get(url, { withCredentials: true });
    },

    toggleQueryGroupAutocomplete(groupIndex) {
      const newValue = !this.queryGroupRowVisibility[groupIndex];

      this.queryGroupRowVisibility.splice(groupIndex, 1, newValue);

      if (newValue) {
        const thisAutocomplete = this.$refs[`query-group-row-autocomplete-${groupIndex}`][0];
        this.$nextTick(() => thisAutocomplete.$el.childNodes[0].children[0].focus());

        // Always expand the query builder on open autocomplete input
        this.toggleQueryBuilderExpanded(true);
      } else {
        // Clean up
        this.queryGroupRowKeywords.splice(groupIndex, 1, '');
        this.queryGroupRowSuggestions.splice(groupIndex, 1, []);
      }
    },

    hideAllQueryBuilderAutocomplete() {
      this.queryGroupRowVisibility.fill(false);
    },

    toggleQueryBuilderExpanded(override) {
      const target = document.getElementById('rcm-query-builder-scroll');

      if (override !== undefined) {
        this.queryBuilderExpanded = override;

        if (this.queryBuilderExpanded) {
          target.classList.remove('rcm-query-builder-scroll-collapsed');
        } else {
          target.classList.add('rcm-query-builder-scroll-collapsed');
        }
      } else {
        this.queryBuilderExpanded = !this.queryBuilderExpanded;
        target.classList.toggle('rcm-query-builder-scroll-collapsed');
      }

      if (!this.queryBuilderExpanded) {
        this.hideAllQueryBuilderAutocomplete();
      }
    },

    updateQueryGroupRowOperator(value, groupIndex) {
      this.setGroupRowOperator({ groupIndex, operator: value });
    },

    getFirstTwoKeywords(keywords) {
      return (keywords.slice(0, 2).join(', ')).concat('...');
    },
  },
};
</script>
