<template>
  <div id="visualisationPane">
    <MapVisualisation
      v-show="mapVisualisationSelected"
    />
    <NetworkVisualisation
      v-show="networkVisualisationSelected"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import MapVisualisation from '@/components/visualisation_pane/MapVisualisation.vue';
import NetworkVisualisation from '@/components/visualisation_pane/NetworkVisualisation.vue';

/**
 * Container for visualisations, changing the display property of the selected
 * visualisation to control the rendering on the page
 */
export default {
  name: 'VisualisationPane',

  components: {
    NetworkVisualisation,
    MapVisualisation,
  },

  computed: {
    ...mapState('misc', ['networkVisualisationSelected', 'mapVisualisationSelected']),
  },
};
</script>
