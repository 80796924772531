/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

import axios from 'axios';
import _ from 'lodash';

// initial state
const state = {
  top: 50,
  result: null,
  originalResult: null,

  researchActivities: [],
  researchers: [],
  topResearcherTotalCounts: {},
  organisations: [],
  topOrganisationTotalCounts: {},
  totalResearchActivitiesSupportedFilters: ['timerange', 'activityType', 'researcherCount', 'currentuom'],

  similarResearchActivityIds: [],
  similarResearchActivityLabels: [],
};

// getters
const getters = {

  /**
   * The list of douments that has been enriched and filtered
   */
  filteredResearchActivities: (state, resultGetters, rootState, rootGetters) => {
    const queryFilters = rootGetters['query/clientCapabilityQueryFilters'];
    const result = state.researchActivities.filter((document) => {
      // Iterate over all the research avtivity attributes
      // Filter to just attributes with matching query filters
      // Any 'return true' below will short circuit and skip this research avtivity

      const attrsFailed = _
        .toPairs(document)
        .filter((attr) => queryFilters.has(_.first(attr)))
        .some((attr) => {
          // Go through each query filter item
          // If it fails, it will short circuit and return early with true
          // Otherwise it will go through all items and then return false

          const attrFailed = queryFilters.get(_.first(attr)).some((item) => {
            if (typeof item === 'string') {
              if (attr[1] === item) {
                return false;
              }
            } else if (typeof item === 'boolean') {
              if (attr[1] === item) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'range'
            ) {
              const value = attr[1];

              if (item.from <= value && value <= item.to) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'contains'
            ) {
              // Only includes the document if it's values *are* in the item.items
              if (item.items.filter((value) => attr[1].indexOf(value) !== -1).length > 0) {
                return false;
              }
            } else if (
              typeof item === 'object'
              && item.comparison
              && item.comparison === 'not_contains'
            ) {
              // Only includes the document if it's values *are not* in the item.items
              if (item.items.filter((value) => attr[1].indexOf(value) !== -1).length === 0) {
                return false;
              }
            } else {
              // eslint-disable-next-line no-console
              console.log(`UNSUPPORTED DATA TYPE DETECTED FOR ${attr[0]}: ${typeof item}`);
              return true;
            }

            // Filters matched, but didn't pass, document isn't valid
            return true;
          });

          return attrFailed;
        });

      return !attrsFailed;
    });
    return result;
  },

  // eslint-disable-next-line max-len
  filteredResearchActivityIds: (state, capabilityGetters) => capabilityGetters.filteredResearchActivities
    .map((document) => document.rcm_id),

  filteredPublications: (state, capabilityGetters) => capabilityGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'publication')),

  filteredProjects: (state, capabilityGetters) => capabilityGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'project')),

  filteredPatents: (state, capabilityGetters) => capabilityGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'patent')),

  filteredClinicalTrials: (state, capabilityGetters) => capabilityGetters.filteredResearchActivities
    .filter((document) => (document.doc_type === 'clinical_trial')),

  filteredPublicationIds: (state, capabilityGetters) => capabilityGetters.filteredPublications
    .map((publication) => publication.rcm_id),

  filteredProjectIds: (state, capabilityGetters) => capabilityGetters.filteredProjects
    .map((project) => project.rcm_id),

  filteredPatentIds: (state, capabilityGetters) => capabilityGetters.filteredPatents
    .map((patent) => patent.rcm_id),

  filteredClinicalTrialIds: (state, capabilityGetters) => capabilityGetters.filteredClinicalTrials
    .map((clinicalTrial) => clinicalTrial.rcm_id),

  filteredResearchers: (state, capabilityGetters, rootState, rootGetters) => {
    const queryFilters = rootGetters['query/clientCapabilityQueryFilters'];
    const researchers = state.researchers
      // Filter out not current UoM researchers when the filter is on
      // This is because `current_UoM`filter works on document level
      // This means filered docs can contain non-current UoM Researchers!
      .filter((researcher) => {
        if (queryFilters.has('is_current_uom') && queryFilters.get('is_current_uom')[0]) {
          if (researcher.is_current_uom) {
            return true;
          }

          return false;
        }

        return true;
      });

    return researchers;
  },

  filteredOrganisations: (state) => {
    const { organisations } = state;
    return organisations;
  },

  filteredTopResearchers: (state, capabilityGetters) => {
    const { filteredResearchers } = capabilityGetters;
    const researchersWithUpdatedTotalDoc = filteredResearchers.slice(0, state.top)
      .map((researcher) => {
        const withTotalDocs = state.topResearcherTotalCounts[researcher.rcm_id];
        if (withTotalDocs !== undefined) {
          return {
            ...researcher,
            ...{
              total_research_activity_count: withTotalDocs.research_activity_count,
            },
          };
        }

        return researcher;
      });
    return researchersWithUpdatedTotalDoc;
  },

  filteredTopOrganisations: (state, capabilityGetters) => {
    const { filteredOrganisations } = capabilityGetters;
    const organisationsWithUpdatedTotalDoc = filteredOrganisations.slice(0, state.top)
      .map((organisation) => {
        const withTotalDocs = state.topOrganisationTotalCounts[organisation.rcm_id];
        if (withTotalDocs !== undefined) {
          return {
            ...organisation,
            ...{
              total_organisation_activity_count: withTotalDocs.organisation_activity_count,
            },
          };
        }
        return organisation;
      });
    return organisationsWithUpdatedTotalDoc;
  },

  filteredProjectsSummary: (state, capabilityGetters) => {
    const { filteredProjects } = capabilityGetters;
    const numProjects = filteredProjects.length;
    let funding;

    if (numProjects > 0) {
      funding = filteredProjects.reduce(
        (funds, project) => funds + (project.funding_aud ? project.funding_aud : 0), 0,
      );
    } else {
      funding = 0;
    }

    return { numProjects, funding };
  },

};

// actions
const actions = {
  /**
   * This method first set researchActivities, and extract researchers
   */
  extractResultData(context) {
    context.commit('setResearchActivities', context.state.result);
    context.dispatch('extractResearchers');
    context.dispatch('extractOrganisations');
  },

  /**
   * Extract resarcher details by their ids
   */
  extractResearchers(context) {
    const researchers = new Map();

    context.getters.filteredResearchActivities.forEach((document) => {
      // Skip the document if there is no UoM researchers in
      if (!_.has(document, 'uom_researcher_ids')) {
        return;
      }

      document.uom_researcher_ids.forEach((researcherId) => {
        let researcher;

        if (researchers.has(researcherId)) {
          researcher = researchers.get(researcherId);
        } else {
          researcher = {
            rcm_id: researcherId,
            combinedRcmIds: new Set(),
            publicationRcmIds: new Set(),
            projectRcmIds: new Set(),
            patentRcmIds: new Set(),
            clinicalTrialRcmIds: new Set(),
          };
        }

        researcher.combinedRcmIds.add(document.rcm_id);

        if (document.doc_type === 'publication') {
          researcher.publicationRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'project') {
          researcher.projectRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'patent') {
          researcher.patentRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'clinical_trial') {
          researcher.clinicalTrialRcmIds.add(document.rcm_id);
        } else {
          // eslint-disable-next-line no-console
          console.log(`Unknown doc_type seen '${document.doc_type}' for ${document.rcm_id}`);
        }

        researchers.set(researcherId, researcher);
      });
    });

    // Converting the researchers dictionary to a list suitable for <b-table>
    const researchersList = Array.from(
      researchers,
      ([key, value]) => ({ // eslint-disable-line no-unused-vars
        rcm_id: value.rcm_id,
        combinedRcmIds: Array.from(value.combinedRcmIds),
        publicationRcmIds: Array.from(value.publicationRcmIds),
        projectRcmIds: Array.from(value.projectRcmIds),
        patentRcmIds: Array.from(value.patentRcmIds),
        clinicalTrialRcmIds: Array.from(value.clinicalTrialRcmIds),
      }),
    );

    // Get researcher details
    const parameters = ['esIgnoreFields=assignments,bio,qualifications,fellowships,awards'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/data/researchers${paramString}`;

    axios
      .post(url, { researchers: researchersList }, { withCredentials: true })
      .then((response) => {
        const actualResearchers = response.data.researchers
          .map((researcher) => (
            {
              ...researcher,
              research_activity_count: researcher.combinedRcmIds.length,
              name: researcher.preferred_name && researcher.preferred_name !== ''
                ? `${researcher.preferred_name} ${researcher.last_name}` : `${researcher.first_name} ${researcher.last_name}`,
            }));

        // Sort the Researchers by document count desc
        context.commit('setResearchers', _.orderBy(actualResearchers, ['research_activity_count'], 'desc'));
        context.dispatch('getTopResearcherTotalCounts');
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },

  /**
   * Get researcher total researchActivities with filters applied
   */
  getTopResearcherTotalCounts(context) {
    const queryFilters = context.rootGetters['query/queryFilters'];
    const parameters = ['summary=true'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/researcher/researchactivities${paramString}`;

    // The state researchers have been sorted by document count
    // so a simple slice can give us top researchers
    const topResearchers = context.state.researchers.slice(0, context.state.top);

    // Simplify the researchers with the search fields only
    // This significantly reduces the data size to be transmitted
    const researcherRcmIds = topResearchers.map((researcher) => researcher.rcm_id);

    const payload = {
      researchers: researcherRcmIds,
      filters: queryFilters,
    };

    axios
      .post(url, payload, { withCredentials: true })
      .then((response) => {
        context.commit('setTopResearcherTotalCounts', response.data.researchers);
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },

  /**
   * Get organisations with filters applied
   */
  extractOrganisations(context) {
    const organisations = new Map();
    context.getters.filteredResearchActivities.forEach((document) => {
      // Skip the document if there is no UoM organisations in
      if (!_.has(document, 'organisation_ids')) {
        return;
      }
      document.organisation_ids.forEach((organisationId) => {
        let organisation;
        if (organisations.has(organisationId)) {
          organisation = organisations.get(organisationId);
        } else {
          organisation = {
            rcm_id: organisationId,
            combinedRcmIds: new Set(),
            publicationRcmIds: new Set(),
            projectRcmIds: new Set(),
            patentRcmIds: new Set(),
            clinicalTrialRcmIds: new Set(),
          };
        }

        organisation.combinedRcmIds.add(document.rcm_id);

        if (document.doc_type === 'publication') {
          organisation.publicationRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'project') {
          organisation.projectRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'patent') {
          organisation.patentRcmIds.add(document.rcm_id);
        } else if (document.doc_type === 'clinical_trial') {
          organisation.clinicalTrialRcmIds.add(document.rcm_id);
        } else {
          // eslint-disable-next-line no-console
          console.log(`Unknown doc_type seen '${document.doc_type}' for ${document.rcm_id}`);
        }

        organisations.set(organisationId, organisation);
      });
    });

    // Converting the researchers dictionary to a list suitable for <b-table>
    const organisationsList = Array.from(
      organisations,
      ([key, value]) => ({ // eslint-disable-line no-unused-vars
        rcm_id: value.rcm_id,
        combinedRcmIds: Array.from(value.combinedRcmIds),
        publicationRcmIds: Array.from(value.publicationRcmIds),
        projectRcmIds: Array.from(value.projectRcmIds),
        patentRcmIds: Array.from(value.patentRcmIds),
        clinicalTrialRcmIds: Array.from(value.clinicalTrialRcmIds),
      }),
    );

    // Get organisation details
    const parameters = ['esIgnoreFields=assignments,bio,qualifications,fellowships,awards'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/data/organisations${paramString}`;

    axios
      .post(url, { organisations: organisationsList }, { withCredentials: true })
      .then((response) => {
        const actualOrganisations = response.data.organisations
          .map((organisation) => (
            {
              ...organisation,
              organisation_activity_count: organisation.combinedRcmIds.length,
              // Needs modification for organisation
              name: organisation.name,
            }));

        // Sort the organisations by document count desc
        context.commit('setOrganisations', _.orderBy(actualOrganisations, ['organisation_activity_count'], 'desc'));
        // context.dispatch('getTopOrganisationTotalCounts');
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },
  /**
   * Get organisation with filters applied
   */
  getTopOrganisationTotalCounts(context) {
    const queryFilters = context.rootGetters['query/queryFilters'];
    const parameters = ['summary=true'];
    const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
    const url = `${process.env.RCM_API}/api/v1/organisation/researchactivities${paramString}`;

    // The state orgs have been sorted by document count
    // so a simple slice can give us top researchers
    const topOrganisation = context.state.organisations.slice(0, context.state.top);

    // Simplify the orgs with the search fields only
    // This significantly reduces the data size to be transmitted
    const organisationRcmIds = topOrganisation.map((organisation) => organisation.rcm_id);

    const payload = {
      organisations: organisationRcmIds,
      filters: queryFilters,
    };

    axios
      .post(url, payload, { withCredentials: true })
      .then((response) => {
        context.commit('setTopOrganisationTotalCounts', response.data.organisations);
      })
      .catch((error) => console.log(error)); // eslint-disable-line no-console
  },
};

// mutations
const mutations = {
  setResult(state, result) {
    // Only to be used to set a *new* result object
    state.result = result.research_activities;
    state.originalResult = JSON.stringify(result.research_activities);
  },

  setResearchActivities(state, researchActivities) {
    state.researchActivities = researchActivities;
  },

  setResearchers(state, researchers) {
    state.researchers = researchers;
  },

  setOrganisations(state, organisations) {
    state.organisations = organisations;
  },

  setTopResearcherTotalCounts(state, value) {
    state.topResearcherTotalCounts = value;
  },

  setTopOrganisationTotalCounts(state, value) {
    state.topOrganisationTotalCounts = value;
  },

  setSimilarResearchActivityIds(state, similarResearchActivityIds) {
    state.similarResearchActivityIds = similarResearchActivityIds;
  },

  setSimilarResearchActivityLabels(state, similarResearchActivityLabels) {
    state.similarResearchActivityLabels = similarResearchActivityLabels;
  },

  reset(state) {
    state.result = null;
    state.originalResult = null;

    state.researchActivities = [];
    state.researchers = [];
    state.topResearcherTotalCounts = {};
    state.topOrganisationTotalCounts = {};
    state.totalResearchActivitiesSupportedFilters = ['timerange', 'activityType', 'researcherCount', 'currentuom'];

    state.similarResearchActivityIds = [];
    state.similarResearchActivityLabels = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
