/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

// initial state
const state = {
  capabilityPaneSelected: false,
  topicPaneSelected: false,
  mapVisualisationSelected: true,
  networkVisualisationSelected: false,
};

const getters = {};

const actions = {};

const mutations = {
  showCapabilityPane(state) {
    state.topicPaneSelected = false;
    state.capabilityPaneSelected = true;
  },

  showCapabilityPaneIfTopicVisible(state) {
    if (state.topicPaneSelected) {
      state.topicPaneSelected = false;
      state.capabilityPaneSelected = true;
    }
  },

  showTopicPane(state) {
    state.capabilityPaneSelected = false;
    state.topicPaneSelected = true;
  },

  hideBothPanes(state) {
    state.capabilityPaneSelected = false;
    state.topicPaneSelected = false;
  },

  hideCapabilityPane(state) {
    state.capabilityPaneSelected = false;
  },

  hideTopicPane(state) {
    state.topicPaneSelected = false;
  },

  showNetworkVisualisation(state) {
    state.mapVisualisationSelected = false;
    state.networkVisualisationSelected = true;
  },

  showMapVisualisation(state) {
    state.mapVisualisationSelected = true;
    state.networkVisualisationSelected = false;
  },

  reset(state) {
    state.capabilityPaneSelected = false;
    state.topicPaneSelected = false;
    state.mapVisualisationSelected = true;
    state.networkVisualisationSelected = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
