<template>
  <div
    id="network-visualisation"
    ref="network"
  >
    <div class="query-title">
      <!-- eslint-disable vue/no-v-html -->
      <!-- This html is hardcoded below and contains no user input -->
      <h6 v-html="queryExplanation" />
      <!-- eslint-enable -->
    </div>
    <div
      v-if="loading"
      class="network-loading"
    >
      <div
        class="loading-content"
      >
        <h2>
          <font-awesome-icon
            spin
            :icon="loadingSpinner"
          />&nbsp;Loading
        </h2>
        <br>
        {{ loadingText }}
      </div>
    </div>
    <svg
      id="d3element"
      ref="d3element"
      title="d3element"
      class="d3element"
    />
  </div>
</template>

<style>
#network-visualisation {
  position: relative;
  height: 45vw;
}

.query-title {
  min-height: 38px;
}

.researcher-image {
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}

svg.d3element {
  border: 1px dashed #CCCCCC;
  height: 100%;
  width: 100%;
}

div.loading-content {
  text-align: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: auto;
  margin: auto;
  padding: 5px;
  height: auto;
  z-index: 100;
  border-radius: 10px;
  color: grey;
  background-color: rgba(245, 245, 245, 0.8);
}

div.network-loading {
  text-align: center;
  position: absolute;
  z-index: 90;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.6);
}

.node-label {
  background-color: lightgrey;
  height: auto;
  min-height: 28px;
  border-radius: 4px;
}

circle.default {
  cursor: pointer;
  stroke-width: 1px;
  stroke: black;
  fill: black;
}

text {
  font: 10px arial;
  pointer-events: none;
  /*text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;*/
}

path.default {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
}

circle.Cluster {
  fill: hotpink;
  stroke: deeppink;
}

circle.Cluster:hover {
  fill: pink;
  stroke: hotpink;
}

circle.Researcher {
  fill: #F1948A;
  stroke: #EC7063;
  stroke-width: 4px;
}

circle.Researcher:hover {
  fill: #FADBD8;
  stroke: #F1948A;
  stroke-width: 4px;
}

circle.Researcher_TOP {
  fill: gold;
  stroke: darkgoldenrod;
  stroke-width: 4px;
}

circle.Researcher_TOP:hover {
  fill: lightyellow;
  stroke: gold;
  stroke-width: 4px;
}

circle.Publication {
  fill: white;
  stroke: lightgrey;
}

circle.Publication:hover {
  fill: black;
  stroke: white;
  stroke-width: 4px;
}

circle.Collaborations {
  fill: white;
  stroke: lightgrey;
  stroke-width: 2px;
}

circle.Collaborations:hover {
  fill: black;
  stroke: white;
  stroke-width: 2px;
}

circle.Department {
  fill: #C39BD3;
  stroke: #AF7AC5;
}

circle.Department:hover {
  fill: #D7BDE2;
  stroke: #C39BD3;
}

circle.Organisation {
  fill: #85C1E9;
  stroke: #5DADE2;
}

circle.Organisation:hover {
  fill: #AED6F1;
  stroke: #85C1E9;
}

circle.FORCode {
  fill: goldenrod;
  stroke: orange;
}

circle.FORCode:hover{
  fill: palegoldenrod;
  stroke: yellow;
}

circle.Project {
  fill: coral;
  stroke: chocolate;
}

circle.Project:hover {
  fill: sandybrown;
  stroke: lightcoral;
}

/*path.AFFILIATED_WITH {*/
/*  fill: #C0392B;*/
/*  stroke: #C0392B;*/
/*  stroke-width: 1px;*/
/*  stroke-dasharray: 5,5;*/
/*}*/

/*path.RELATED {*/
/*  fill: #3498DB;*/
/*  stroke: #3498DB;*/
/*  stroke-width: 1px;*/
/*  stroke-dasharray: 5,5;*/
/*}*/

/*path.RELATES_TO {*/
/*  fill: #3498DB;*/
/*  stroke: #3498DB;*/
/*  stroke-width: 1px;*/
/*  stroke-dasharray: 5,5;*/
/*}*/

path.AUTHORED_BY {
  fill: none;
  stroke: #a1d8ff;
  /*stroke-opacity: 0.5;*/
  stroke-width: 8px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.AUTHORED_BY_OVERLAY {
  fill: none;
  stroke: white;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.PARTICIPATED_IN {
  fill: none;
  stroke: #8fba8f;
  /*stroke-opacity: 0.5;*/
  stroke-width: 8px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.PARTICIPATED_IN_OVERLAY {
  fill: none;
  stroke: white;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.COLLABORATED_WITH {
  fill: none;
  stroke: #68cfbf;
  stroke-width: 12px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.COLLABORATED_WITH_OVERLAY {
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.default {
  fill: none;
  stroke: #68cfbf;
  stroke-width: 8px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

path.default_OVERLAY {
  fill: none;
  stroke: white;
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

#control-icon-group {
  cursor: pointer;
}

g.lockIcon path {
  stroke: #1B2631;
  stroke-width: 1px;
}

g path.overlay {
  fill: white;
  stroke: #1B2631;
  stroke-width: 1px;
  stroke-dasharray: 2,2;
}

.tooltip {
  color: #fff;
  position: absolute;
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  max-width: 300px;
}

.tooltip .tooltip-name {
  font-weight: bold;
}

.tooltip-name {
  overflow-wrap: break-word;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-description {
  overflow-wrap: break-word;
  max-width: 300px;
}

.tooltip-path {
  fill: #000;
  fill-opacity: .7;
  max-width: 305px;
}

text {
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;
}

</style>

<script>
import * as d3 from 'd3';
import * as cola from 'webcola'; // eslint-disable-line no-unused-vars
import _ from 'lodash';
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'NetworkVisualisation',

  data() {
    return {
      queryExplanation: 'Who are the <b>top</b> researchers in this query and who have they collaborated with at least <b>4</b> times?',
      graph: undefined,
      tainted: false,
      loadingSpinner: faSpinner,
      loadingText: 'Initialising network graph',
      loading: true,
      nodeInfo: undefined,
      clearExistingData: 'clear',
      apiUrl: `${process.env.RCM_API}/api/v1/query/researchernetwork`,
      d3cola: undefined,
      d3element: '#d3element',
      networkElement: '#network-visualisation',
      circleGroup: 'circle-group',
      textGroup: 'text-group',
      pathGroup: 'path-group',
      pathLabelGroup: 'path-label-group',
      tooltipGroup: 'tooltip-group',
      defs: undefined,
      graphWidth: undefined,
      graphHeight: undefined,
      circleSize: 20,
      documentNodeRadius: 8,
      researcherNodeRadius: 23,
      nodeHitMultiplier: 0.75,
      pathWidth: 12,
      collideForceSize: 50, // Determines how far apart to space nodes, calculated below
      collideMultiplier: 1.75, // Used to calculate [[collideForceSize]]
      linkForceSize: 75,
      linkDistance: 125,
      nodeItemMap: undefined,
      linkItemMap: undefined,
      d3simulation: undefined,
      circles: undefined,
      circleText: undefined,
      lines: undefined,
      linesOverlay: undefined,
      activeLines: undefined,
      lineText: undefined,
      tooltip: undefined,
      links: undefined,
      bilinks: undefined,
      nodes: undefined,
      linkLengthMaxSize: 1000,
      iterationCoeff: 30,
      top5researchers: undefined,
      color: () => {},
      zoomHandler: d3.zoom()
        .filter(() => (d3.event.type === 'wheel' || d3.event.type === 'mousedown'))
        .on('zoom', this.zoomActions),
    };
  },

  computed: {
    ...mapGetters('capability', {
      capabilityPublications: 'filteredPublications',
      capabilityProjects: 'filteredProjects',
      capabilityResearchers: 'filteredResearchers',
    }),
    ...mapState('misc', ['networkVisualisationSelected']),
  },

  watch: {
    /**
     * Watch the tab to be clicked, which informs us that this component is
     * now in view on the page
     */
    networkVisualisationSelected() {
      if (this.networkVisualisationSelected) {
        this.loadingText = 'Initialising network graph';
        this.initGraph();

        // If there already is data in the state, do nothing
        if (this.nodeItemMap && this.nodeItemMap.length > 0 && !this.tainted) {
          // Do nothing
        } else if ((this.capabilityResearchers && this.capabilityResearchers.length > 0)
          || this.tainted) {
          // Else if there is the correct map data, start the data load process
          this.loadData();
        }
      } else {
        // This will trigger if this.networkVisualisationSelected === false
        // Do something? Unmount the graph?
      }
    },

    capabilityPublications() {
      this.tainted = true;
    },

    /**
     * Watch the capability Researchers data source, triggering
     * a graph data update if the map data is updated
     */
    capabilityResearchers(newValue) {
      // this.tainted = true;
      if (this.tainted
        && newValue
        && newValue.length > 0
        && this.networkVisualisationSelected) {
        this.loadData();
      }
    },

    capabilityOrganisations(newValue) {
      // this.tainted = true;
      if (this.tainted
        && newValue
        && newValue.length > 0
        && this.networkVisualisationSelected) {
        this.loadData();
      }
    },
  },

  mounted() {
    // Dynamically calculate the collision properties using props
    this.collideForceSize = this.circleSize * this.collideMultiplier;

    this.$eventBus.$on('reset', () => {
      this.graph = undefined;
    });

    // When 'Explore' is selected, we set loading to true
    this.$eventBus.$on('request-analysis', () => {
      this.loading = true;
      this.loadingText = 'Collecting additional data';
      this.tainted = true;
    });
  },

  methods: {
    /**
     * Start the process of getting data from neo4j and building
     * the network graph
     */
    loadData() {
      this.tainted = false;
      this.loading = true;
      this.loadingText = 'Collecting additional data';

      this.getAdditionalData()
        .then(() => {
          this.loadingText = 'Generating network graph';

          setTimeout(() => {
            this.clearGraph();
            this.updateGraph();
            this.loadingText = 'Complete!';
            this.loading = false;
            this.tainted = false;
          }, 0);
        });
    },
    /**
     * Clear the graph so it can be reloaded safely with data
     */
    clearGraph() {
      const lines = d3.select(`#${this.pathGroup}`).selectAll('*');
      const circles = d3.select(`#${this.circleGroup}`).selectAll('*');
      const tooltip = d3.select(`#${this.tooltipGroup}`).selectAll('*');

      lines.remove();
      circles.remove();
      tooltip.remove();
    },

    /**
     * Collect and map data for the guided search for Researchers and Collaborators
     */
    topResearchersAndCollaborators() {
      this.top5researchers = _.chain(this.capabilityResearchers)
        .orderBy('research_activity_count', 'desc')
        .slice(0, Math.min(this.capabilityResearchers.length, 5))
        .value();

      const rcmIdList = this.top5researchers.map((r) => r.rcm_id).filter((r) => r);

      return axios
        .post(this.apiUrl, {
          query: {
            rcm_id_list: rcmIdList,
          },
        }, { withCredentials: true })
        .then((response) => {
          const queryResponse = response.data;
          // TODO: check for error messages before operating on the graph
          const graphResults = _.head(queryResponse.results).data;

          const neoLinks = _.uniqBy(graphResults.flatMap((data) => data.graph.relationships), 'id')
            .map((l) => ({
              ...l,
              source: l.startNode,
              target: l.endNode,
              value: 1,
            }));

          // Flatten the data out to more easily access the node data
          const neoNodes = _.uniqBy(graphResults.flatMap((data) => data.graph.nodes), 'id')
            .map((n) => ({
              ...n,
              height: this.getNodeHitRadius(n),
              width: this.getNodeHitRadius(n),
            }));

          this.linkItemMap = neoLinks;
          this.nodeItemMap = neoNodes;

          return neoNodes;
        })
        .then((neoNodes) => {
          // Map the nodes into ids
          const idList = neoNodes
            .filter((node) => _.head(node.labels) === 'Researcher')
            .map((node) => ({
              rcm_id: node.properties.rcm_id || '',
            }));

          // Get researcher details
          const url = `${process.env.RCM_API}/api/v1/data/researchers`;

          // Enrich the Researcher Nodes with extra data from Elasticsearch
          return axios
            .post(url, { researchers: idList }, { withCredentials: true })
            .then((response) => {
              const researcherData = response.data.researchers;

              // Get the department information from the ES Result
              const departmentInfo = researcherData.map((item) => ({
                rcm_id: item.rcm_id,
                department_name: item.department_name,
                department_code: item.department_code,
              }));

              // Merge in the department info by rcm_id, into the node info
              const updatedNodes = neoNodes.map((node) => {
                // If it is a Researcher, check for the department
                if (_.head(node.labels) === 'Researcher') {
                  // Find department information from the ES result
                  const foundDepartment = _
                    .find(departmentInfo, (i) => i.rcm_id === node.properties.rcm_id);

                  // Combine the found info with the existing info
                  return {
                    ...node,
                    properties: {
                      ...node.properties,
                      department_name: (foundDepartment && foundDepartment.department_name) || '',
                      department_code: (foundDepartment && foundDepartment.department_code) || '',
                    },
                  };
                }

                // It is not a researcher, so just return the node
                return node;
              });

              // Get the SET of departments
              const departments = _.uniq(updatedNodes
                .map((node) => node.properties.department_name)
                .filter((n) => n));

              // Use the departments to generate a colour scale
              this.color = d3.scaleOrdinal()
                .domain(departments)
                .range(d3.schemeCategory10);

              this.nodeItemMap = updatedNodes;
            })
            .catch((error) => console.log(error)); // eslint-disable-line no-console
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },

    /**
     * Get network information based on the query that has been executed
     */
    getAdditionalData() {
      return this.topResearchersAndCollaborators();
    },

    /**
     * Verify that a link has a valid source and target node
     */
    validateLinks(nodes, links) {
      return links.filter((l) => {
        const validSource = _.head(nodes.filter((n) => n.id === l.source));
        const validTarget = _.head(nodes.filter((n) => n.id === l.target));
        return validSource && validTarget;
      });
    },

    /**
     * Initialise the network graph, creating svg layers
     */
    initGraph() {
      // Dynamically get the height & width
      this.graphWidth = this.$refs.d3element && this.$refs.d3element.clientWidth;
      this.graphHeight = this.$refs.d3element && this.$refs.d3element.clientHeight;

      const svg = d3.select(this.d3element);
      const zoomGLayer = svg.append('g');

      const centerX = this.graphWidth / 2.0;
      const centerY = this.graphHeight / 2.0;

      svg.attr('width', this.graphWidth)
        .attr('height', this.graphHeight);

      zoomGLayer
        .append('g')
        .attr('id', this.pathGroup).attr('transform', `translate(${centerX},${centerY})`);
      zoomGLayer
        .append('g')
        .attr('id', this.pathLabelGroup).attr('transform', `translate(${centerX},${centerY})`);
      zoomGLayer
        .append('g')
        .attr('id', this.circleGroup).attr('transform', `translate(${centerX},${centerY})`);
      zoomGLayer
        .append('g')
        .attr('id', this.textGroup).attr('transform', `translate(${centerX},${centerY})`);
      zoomGLayer
        .append('g')
        .attr('id', this.tooltipGroup).attr('transform', `translate(${centerX},${centerY})`);

      this.zoomHandler(svg);
    },

    /**
     * Update the graph with new data
     */
    updateGraph() {
      this.nodes = _.values(_.cloneDeep(this.nodeItemMap));
      this.links = _.values(_.cloneDeep(this.linkItemMap));
      this.bilinks = [];
      const nodeById = d3.map(this.nodes, (d) => d.id);

      this.links.forEach((link) => {
        // eslint-disable-next-line no-multi-assign,no-param-reassign
        const s = link.source = nodeById.get(link.source);
        // eslint-disable-next-line no-multi-assign,no-param-reassign
        const t = link.target = nodeById.get(link.target);
        const i = {}; // intermediate node
        this.nodes.push(i);
        this.links.push({ source: s, target: i }, { source: i, target: t });
        this.bilinks.push([s, i, t]);
      });

      // const d3LinkForce = d3.forceLink()
      //   // .distance(10)
      //   // // .distance(this.linkForceSize)
      //   // .strength(0.5)
      //   .links(links)
      //   .id((d) => d.id);

      // this.d3cola = cola
      //   .d3adaptor(d3)
      //   .size([this.graphWidth / 4, this.graphHeight / 4]);

      this.d3simulation = d3.forceSimulation()
        .force('charge', d3.forceManyBody().strength(-this.linkForceSize))
        .force('collideForce', d3.forceCollide().radius((d) => this.getNodeHitRadius(d)))
        .nodes(this.nodes)
        .force('link', d3.forceLink(this.links, (d) => d.id))
        .force('center', d3.forceCenter())
        .stop()
        .tick(1000);

      // this.d3cola = cola
      //   .d3adaptor(d3)
      //   .size([this.graphWidth / 4, this.graphHeight / 4])
      //   // .linkDistance(25)
      //   .nodes(this.nodes)
      //   .links(this.links)
      //   .avoidOverlaps(true)
      //   .jaccardLinkLengths(40, 0.7)
      //   // .jaccardLinkLengths()
      //   // .symmetricDiffLinkLengths(5)
      //   .start(
      //     Math.max(Math.ceil(this.nodes.length / this.iterationCoeff), 30),
      //     Math.max(Math.ceil(this.links.length / this.iterationCoeff), 30),
      //     Math.max(Math.ceil((this.links.length + this.nodes.length) / this.iterationCoeff), 30)
      //   )
      //   .stop();

      // Work around to ensure that the text layer is completely removed
      const textRemove = d3.select(`#${this.textGroup}`).selectAll('text');
      textRemove.data([]).exit().remove();

      const lines = d3.select(`#${this.pathGroup}`).selectAll('path');
      const circles = d3.select(`#${this.circleGroup}`).selectAll('circle');
      const defs = d3.select(this.d3element).selectAll('defs');
      const text = d3.select(`#${this.textGroup}`).selectAll('text');

      text.exit().remove();
      lines.exit().remove();
      circles.exit().remove();
      defs.exit().remove();

      this.lines = this.drawLinks(lines, this.bilinks);
      this.linesOverlay = this.drawLinksOverlay(lines, this.bilinks);
      this.circles = this.drawNodes(circles, this.nodes.filter((d) => d.id));
      this.circleText = this.drawText(text, this.nodes.filter((d) => _.head(d.labels) === 'Researcher'));

      // Tick once to update the positions of everything in the graph
      this.tick();
    },

    /**
     * Handler function for mousing over a node SVG element
     */
    mouseOverNodes(node) {
      const firstLabel = _.head(node.labels);
      // Show properties for information
      if (firstLabel === 'Publication') {
        this.showPublicationTooltip(node);
      }

      if (firstLabel === 'Researcher') {
        this.showResearcherTooltip(node);
      }

      this.showProperties(node);

      // Find the links and animate the connection
      const relevantBilinks = this.bilinks
        .filter((l) => l[0].id === node.id || l[2].id === node.id);

      if (firstLabel === 'Collaborations') {
        this.showCollaborationsTooltip(node, relevantBilinks);
      }

      this.activeLines = d3
        .select(`#${this.pathLabelGroup}`)
        .selectAll('path')
        .data(relevantBilinks)
        .enter()
        .append('path')
        .attr('d', (d) => this.positionActiveLink(d, node))
        .style('stroke', '#000')
        .style('stroke-width', '1.5px')
        .style('fill', 'none')
        .style('stroke-dasharray', `0,${this.linkLengthMaxSize}`) // TODO: get the second parameter as the length of the link
        .transition()
        .ease(d3.easeCubicIn)
        .style('stroke-dasharray', `${this.linkLengthMaxSize},${this.linkLengthMaxSize}`);
    },

    /**
     * Draw an active link (animated line) between moused over elements in the graph
     */
    positionActiveLink(links, node) {
      switch (_.head(node.labels)) {
        case 'Researcher':
          if (_.head(links[2].labels) === 'Project') {
            return this.positionLink(links);
          }
          if (_.head(links[2].labels) === 'Collaborations') {
            return this.positionLink(links);
          }
          return this.reversePositionLink(links);
        case 'Organisation':
          return this.reversePositionLink(links);
        case 'Project':
          return this.reversePositionLink(links);
        case 'Collaborations':
          return this.reversePositionLink(links);
        default:
          return this.positionLink(links);
      }
    },

    /**
     * Handler function for mousing out of a graph element
     */
    mouseOutNodes() {
      this.activeLines = d3
        .select(`#${this.pathLabelGroup}`)
        .selectAll('path')
        .remove();

      if (this.tooltip) {
        this.tooltip.remove();
      }
    },

    /**
     * Draw text associated with a node
     */
    drawText(svgLayer, data) {
      return svgLayer
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'node-label')
        .attr('text-anchor', 'middle')
        .style('font-size', '18px')
        .style('font-weight', 'bold')
        .style('fill', 'darkBlue')
        .text((d) => this.getNodeText(d));
    },

    /**
     * Determine the fill style based on node type
     */
    generateNodeFill(d) {
      if (_.head(d.labels) === 'Researcher') {
        return `url(#${d.id})`;
      }

      return undefined;
    },

    /**
     * Draw the nodes in the SVG element
     */
    drawNodes(svgLayer, data) {
      return svgLayer
        .data(data)
        .enter()
        .append('circle')
        .attr('r', this.getNodeRadius)
        .attr('title', (d) => (d.labels && d.labels.join('-')) || '')
        .attr('class', (d) => this.generateNodeClass(d))
        // .call(this.d3cola.drag)
        .on('mouseover', (d) => this.mouseOverNodes(d))
        .on('mouseout', (d) => this.mouseOutNodes(d))
        .style('fill', (d) => this.generateNodeFill(d))
        .style('stroke', (d) => this.color(d.properties.department_name));

      // TODO: add code to add the border
      //
      // stroke: #EC7063;
      // stroke-width: 4px;
    },

    /**
     * Create an image definition layer with Researcher profile pictures
     */
    appendImageData(svgLayer, data) {
      return svgLayer
        .append('defs')
        .selectAll()
        .data(data)
        .enter()
        .append('pattern')
        .attr('id', (d) => d.id)
        .attr('height', '100%')
        .attr('width', '100%')
        .attr('patternContentUnits', 'objectBoundingBox')
        .append('image')
        .attr('height', 1)
        .attr('width', 1)
        .attr('preserveAspectRatio', 'none')
        .attr('href', (d) => this.avatarUrl(d))
        // Set the backup image if there is no person url
        // eslint-disable-next-line func-names
        .on('error', function () {
          this.setAttribute('href', '/favicon.ico');
        });
    },

    /**
     * Draw the links in the SVG element
     */
    drawLinks(svgLayer, data) {
      return svgLayer
        .data(data)
        .enter()
        .append('path')
        .attr('class', (d) => this.generateLinkClass(d));
    },

    /**
     * Get the node radius based on node label
     */
    getNodeRadius(d) {
      switch (_.head(d.labels)) {
        case 'Researcher':
          return this.researcherNodeRadius;
        case 'Organisation':
          return this.researcherNodeRadius;
        default:
          return this.documentNodeRadius;
      }
    },

    /**
     * Get the node radius for collisions, currently set as 10% larger than the display radius
     */
    getNodeHitRadius(d) {
      switch (_.head(d.labels)) {
        case 'Researcher':
          return this.researcherNodeRadius + (this.researcherNodeRadius * this.nodeHitMultiplier);
        case 'Organisation':
          return this.researcherNodeRadius + (this.researcherNodeRadius * this.nodeHitMultiplier);
        default:
          return this.documentNodeRadius + (this.documentNodeRadius * this.nodeHitMultiplier);
      }
    },

    /**
     * Draw the second layer of a link
     */
    drawLinksOverlay(svgLayer, data) {
      return svgLayer
        .data(data)
        .enter()
        .append('path')
        .attr('class', (d) => (`${this.generateLinkClass(d)}_OVERLAY`));
    },

    getNodeText(node) {
      switch (_.head(node.labels)) {
        case 'Researcher':
          return this.getFullName(node);
        case 'Collaborations':
          return '';
        default:
          return '';
      }
    },

    /**
     * From a Researcher node, get a full name string
     */
    getFullName(node) {
      return `${node.properties.preferred_name || node.properties.first_name} ${node.properties.last_name}`;
    },

    /**
     * Generic base function for creating a stylised tooltip
     */
    generateTooltip(d, name, description) {
      this.tooltip = d3.select(this.networkElement)
        .append('div')
        .attr('class', 'tooltip');

      const tooltipPath = this.tooltip
        .append('svg')
        .attr('class', 'tooltip-path');

      tooltipPath.append('path');

      const tooltipContent = this.tooltip
        .append('div')
        .attr('class', 'tooltip-content')
        .style('position', 'absolute')
        .style('z-index', 2)
        .style('padding', '8px')
        .style('top', 0)
        .style('left', 0);

      const tooltipName = tooltipContent.append('div')
        .attr('class', 'tooltip-name');

      const tooltipDescription = tooltipContent.append('div')
        .attr('class', 'tooltip-description');

      tooltipName
        .html(name);

      tooltipDescription
        .html(description);

      const tooltipRect = tooltipContent.node().getBoundingClientRect();

      // FILTHY HACK: Get the world position of the node so that the
      // tooltip can be centred on it.
      const docElement = document.getElementById('tooltip-group');

      const getWorldCoords = (element, coords) => {
        const ctm = element.getCTM();
        const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
        const y = ctm.f + coords.x * ctm.b + coords.y * ctm.d;
        return { x, y };
      };

      const coords = getWorldCoords(docElement, d);

      tooltipPath
        .attr('width', tooltipRect.width + 4)
        .attr('height', tooltipRect.height + 10)
        .style('margin-left', '-2px')
        .style('margin-top', null)
        .select('path')
        .attr('transform', 'translate(2,0)')
        .attr('d', `M0,6a6,6 0 0,1 6,-6H${tooltipRect.width - 6}a6,6 0 0,1 6,6`
          + `v${tooltipRect.height - 12}a6,6 0 0,1 -6,6H${tooltipRect.width / 2 + 6}l-6,6`
          + 'l-6,-6H6a6,6 0 0,1 -6,-6z');

      // TODO: make the tooltip sit 1px above the node edge
      // Need to get the transformed node raidus (e.g radius scaled by zoom)
      this.tooltip
        .style('left', `${coords.x - (tooltipRect.width / 2)}px`) // Sets the middle
        .style('top', `${coords.y - (tooltipRect.height / 1.5) - 15}px`); // Sets the offset from the node

      this.tooltip
        .style('opacity', 1.0);
    },

    /**
     * Generate a unimelb staff picture url from node information
     */
    avatarUrl(d) {
      const imageUrlPrefix = 'https://pictures.staff.unimelb.edu.au/picture/thumbnail';
      const imageUrlPostfix = 'picture.jpg';
      // This cannot be changed to rcm_id because we rely on person_id to generate the avatar
      const urlId = d.properties && d.properties.person_id && d.properties.person_id[0].replace(/[^0-9]/g, '');
      return imageUrlPrefix + urlId + imageUrlPostfix;
    },

    /**
     * Display a tooltip when hovering over a Researcher node
     */
    showResearcherTooltip(d) {
      const name = this.getFullName(d);

      const isHonorary = d.properties.honorary_vs_paid === 'honorary';
      const department = d.properties.department_name || 'Unknown department';

      const descriptionString = `${isHonorary ? '(Honorary) ' : ''}<br/>${department}`;

      this.generateTooltip(d, name, descriptionString);
    },

    /**
     * Display a tooltip when hovering over a Collaboration node
     */
    showCollaborationsTooltip(d, linkArray) {
      // Generate the title field
      const firstNode = _.head(linkArray);
      const secondNode = _.last(linkArray);
      const firstFullName = this.getFullName(_.head(firstNode));
      const secondFullName = this.getFullName(_.head(secondNode));
      const numberOfActivities = d.properties.activities.length;
      const name = `Collaborations between ${firstFullName} and ${secondFullName}`;

      // Generate the description
      const publications = d.properties.activities
        && d.properties.activities.filter((a) => a.scholarly_type);
      const projects = d.properties.activities
        && d.properties.activities.filter((a) => a.project_types);

      const publicationsString = publications && publications.length > 0
        ? `<span class="publicationt-item">${publications.length} publications</span>` : '';

      const projectsString = projects && projects.length > 0
        ? `<span class="project-item">${projects.length} projects</span><br/>` : '';

      const description = `In ${numberOfActivities} collaborations there are: <br/>`
        + `${projectsString}${publicationsString}`;

      this.generateTooltip(d, name, description);
    },

    /**
     * Display a tooltip when hovering over a Publication node
     */
    showPublicationTooltip(d) {
      const publicationDate = d.properties && d.properties.publication_date
        ? moment(d.properties.publication_date, 'YYYYMMDD').format('MMM YYYY').replace(' ', '&nbsp;')
        : '';

      const journalName = (d.properties && d.properties.journal) || 'Unknown Journal';

      const description = `${journalName} (${publicationDate})`.trim();

      const name = d.properties.title;

      this.generateTooltip(d, name, description);
    },

    /**
     * Tick function for updating the graph
     */
    tick() {
      this.lines
        .attr('d', this.positionLink);

      this.linesOverlay
        .attr('d', this.positionLink);

      this.circles
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y);

      this.circleText
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y + this.getNodeRadius(d) + 10);
    },

    /**
     * Draw a link between d[0] and d[2] through d[1]
     */
    positionLink(d) {
      return `M${d[0].x},${d[0].y}S${d[1].x},${d[1].y} ${d[2].x},${d[2].y}`;
    },

    /**
     * Draw a link between d[2] and d[0] through d[1]
     */
    reversePositionLink(d) {
      return `M${d[2].x},${d[2].y}S${d[1].x},${d[1].y} ${d[0].x},${d[0].y}`;
    },

    /**
     * Handler for transforming graph elements on tick
     * @param d d3 element
     * @returns {string} d3 transform command
     */
    transform(d) {
      return `translate(${d.x},${d.y})`;
    },

    /**
     * Clear displayed node properties in the window
     */
    clearProperties() {
      this.nodeInfo = undefined;
    },

    /**
     * Interpolate node properties into a string for
     * display in the window
     */
    showProperties(d) {
      this.clearProperties();

      let propertiesText = `id: ${d.id}`;
      // For nodes
      if (d.labels) propertiesText += `, labels: ${d.labels.join(', ')}`;
      // For links
      if (d.type) propertiesText += `, type: ${d.type}`;

      _.map(d.properties, (value, key) => {
        propertiesText += `, ${key}: ${_.truncate(value, {
          length: 50,
          separator: '&hellip;',
        })}`;
      });

      this.nodeInfo = propertiesText;
    },

    /**
     * Replace the link type by name
     * @param d d3 item
     * @returns {*} link type name
     */
    generateLinkClass(d) {
      const startLabel = _.head(_.head(d).labels);
      const endLabel = _.head(_.last(d).labels);

      if (
        (startLabel === 'Publication' && endLabel === 'Researcher')
        || (endLabel === 'Publication' && startLabel === 'Researcher')
      ) {
        return 'AUTHORED_BY';
      }

      if (
        (startLabel === 'Organisation' && endLabel === 'Researcher')
        || (endLabel === 'Organisation' && startLabel === 'Researcher')
      ) {
        return 'AFFILIATED_WITH';
      }

      if (
        (startLabel === 'Project' && endLabel === 'Researcher')
        || (endLabel === 'Project' && startLabel === 'Researcher')
      ) {
        return 'PARTICIPATED_IN';
      }

      if (
        (startLabel === 'Collaborations' && endLabel === 'Researcher')
        || (endLabel === 'Collaborations' && startLabel === 'Researcher')
      ) {
        return 'COLLABORATED_WITH';
      }

      return 'default';
    },

    /**
     * Zoom action executor
     */
    zoomActions() {
      d3.select(this.d3element).select('g').attr('transform', d3.event.transform);
    },

    /**
     * Selector for assigning class name to circles
     * @param d
     * @returns {string|*}
     */
    generateNodeClass(d) {
      if (d.labels) {
        const firstLabel = _.head(d.labels);

        // If a researcher is from the top 5 researchers, mark the circle differently
        if ((firstLabel === 'Researcher')
          && this.top5researchers.map((r) => r.rcm_id)
            .filter((r) => r).includes(d.properties.rcm_id)) {
          return `${firstLabel}_TOP`;
        }

        return firstLabel;
      }
      return 'default';
    },

    /**
     * Remove a node and associated links from the graph
     * @param d d3 element
     */
    removeNode(d) {
      delete this.nodeItemMap[d.id];
      _.map(this.linkItemMap, (value, key) => {
        if (value.startNode === d.id || value.endNode === d.id) {
          delete this.linkItemMap[key];
        }
      });
    },

    /**
     * Unfreeze all d3 elements in the graph
     * TODO: use this as a toggle button
     */
    unfreezeItems() {
      const nodeItmArray = this.d3simulation.nodes();
      if (nodeItmArray) {
        nodeItmArray.forEach((nodeItm) => {
          if (nodeItm.fx) {
            // eslint-disable-next-line no-param-reassign
            nodeItm.fx = undefined;
            // eslint-disable-next-line no-param-reassign
            nodeItm.fy = undefined;
          }
        });
      }
    },

    /**
     * Stop the d3 simulation completely, including tick updates
     * TODO: use this at unmounting / etc
     */
    stopSimulation() {
      if (this.d3simulation) {
        this.d3simulation.stop().on('tick', undefined);
        this.d3simulation = undefined;
      }
    },

  },
};
</script>
