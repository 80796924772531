<template>
  <div>
    <!-- eslint-disable -->
    <div id="helpText">
      <p>
        Welcome to the UoM <strong>Research Capability Mapping</strong> (RCM) tool. 
        Please be advised that we will decommission RCM over the coming months. We are currently exploring a suitable replacement that will have improved data capture as well as enhanced data analytics and visualisations. 
        If you have any questions about the decommissioning of RCM, please contact Adelyn Chan at <a href="mailto:adelyn.chan@unimelb.edu.au">adelyn.chan@unimelb.edu.au</a>.
      </p>
      <p>
        In the meantime, all University staff may access the Dimensions platform, which performs similarly to RCM. Access to Dimensions is open to everyone and you sign up by entering your University of Melbourne email address. 
      </p>
      <p>
        <em>Please note: You do <strong>not</strong> need permission nor special approval from Service Now to access Dimensions.</em>
      </p>
      <p>
        <a href="https://gateway.research.unimelb.edu.au/platforms-data-and-reporting/research-reporting/dimensions">Use this link to access Dimensions through the Research Gateway</a>
      </p>
    </div>
    <div id="helpImage">
      <img
        src="images/help-image.jpg"
        alt="Help Image"
      >
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'HelpText',
};
</script>
