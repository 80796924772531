/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */

// initial state
const state = {
  addKeywordOnSubmit: false,
};

const getters = {};

const actions = {};

const mutations = {
  setAddKeywordOnSubmit(state, flag) {
    state.addKeywordOnSubmit = flag;
  },

  reset(state) {
    state.addKeywordOnSubmit = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
