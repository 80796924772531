<template>
  <div id="miniQueryBuilder">
    <b-container
      fluid
      class="no-padding"
    >
      <div
        v-for="(group, index) of queryGroups"
        :key="index"
      >
        <b-row
          v-if="group.items.length > 0"
          align-v="center"
          no-gutters
        >
          <b-col>
            <div
              :id="`mini-index-${index}`"
              align="center"
              @click="(evt) => queryGroupAction(group, evt, 'click')"
            >
              <font-awesome-icon
                :icon="noNewQueryGroupItems(group) ? analysedGroupIcon : newGroupIcon"
                :style="noNewQueryGroupItems(group) ? { color: 'green' } : { color: 'gray' }"
                size="lg"
              />
            </div>
            <b-popover
              :target="`mini-index-${index}`"
              placement="left"
              triggers="focus hover"
              boundary="viewport"
              @show="(evt) => queryGroupAction(group, evt, 'show')"
              @hide="(evt) => queryGroupAction(group, evt, 'hide')"
            >
              <font-awesome-icon :icon="popoverIcon(group)" />
              &nbsp;{{ popoverContent(group) }}
            </b-popover>
          </b-col>
        </b-row>
        <br>
      </div>
    </b-container>
  </div>
</template>

<style>
#miniQueryBuilder {
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
}

.no-padding {
  padding: 0;
}
</style>

<script>
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import {
  faEdit,
  faUser,
  faFlask,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MiniQueryBuilder',

  data() {
    return {
      latestClickedGroup: null,
      latestTopicSelection: false,

      analysedGroupIcon: faCheckCircle,
      newGroupIcon: faQuestionCircle,

      keywordTypeIcon: faEdit,
      forcodeTypeIcon: faFlask,
      researcherTypeIcon: faUser,
      unknownTypeIcon: faQuestion,
    };
  },

  computed: {
    ...mapGetters('query', ['queryGroups']),
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      this.latestClickedGroup = null;
      this.latestTopicSelection = false;
    });

    this.$eventBus.$on('topic-selection', () => { this.latestTopicSelection = false; });
  },

  methods: {
    ...mapActions('topic', ['topicSelection']),

    noNewQueryGroupItems(group) {
      return group.items.filter((item) => item.status === 'new').length === 0;
    },

    queryGroupAction(group, event, action) {
      switch (action) {
        case 'click':
          if (group === this.lastestClickedGroup && this.latestTopicSelection) {
            this.latestClickedGroup = null;
            this.latestTopicSelection = false;
            this.$eventBus.$emit('topic-deselection');
          } else {
            this.latestClickedGroup = group;
            // The 3rd parameter specifies that topic selection is not emitted from clicking
            // on a cluster on the map, ie. fromMap=false

            const rcmIds = group.researchActivityIds;

            this.$eventBus.$emit('topic-selection', rcmIds, group, false);
            this.topicSelection({ rcmIds, sourceObject: group, source: 'MiniQueryBuilder' });

            this.$nextTick(() => { this.latestTopicSelection = true; });
          }
          break;

        case 'show':
          this.$eventBus.$emit('topic-hover', group.researchActivityIds);
          break;

        case 'hide':
          this.$eventBus.$emit('topic-unhover');
          break;

        default:
          console.log(`Unknown queryGroupAction action '${action}'`); // eslint-disable-line no-console
          break;
      }
    },

    popoverContent(group, cutoff = 40) {
      const groupItemValues = group.items.map((item) => item.text);
      const content = groupItemValues.join(', ');

      if (content.length > cutoff) {
        return `${content.slice(0, cutoff)}...`;
      }

      return content;
    },

    popoverIcon(group) {
      if (group.items.length === 0) {
        return null;
      }

      const firstItem = group.items[0];

      switch (firstItem.type) {
        case 'keyword':
          return this.keywordTypeIcon;
        case 'forcode':
          return this.forcodeTypeIcon;
        case 'researcher':
          return this.researcherTypeIcon;
        default:
          return this.unknownTypeIcon;
      }
    },
  },
};
</script>
