<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="searchLeft"
              type="text"
              :placeholder="options.label"
              :required="options.inputOptions.isRequired"
            />
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="searchRight"
              type="text"
              :placeholder="options.label"
              :required="options.inputOptions.isRequired"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        &nbsp;
      </b-row>
      <b-row>
        <b-col>
          <div align="center">
            <b-button
              variant="primary"
              @click.stop="transferAllToRight"
            >
              {{ options.buttonOptions.textLeft }} ({{ filteredLeft.length }})&rarr;
            </b-button>
          </div>
        </b-col>
        <b-col>
          <div align="center">
            <b-button
              variant="primary"
              @click.stop="transferAllToLeft"
            >
              &larr; {{ options.buttonOptions.textRight }} ({{ filteredRight.length }})
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        &nbsp;
      </b-row>
      <b-row>
        <b-col>
          <b-list-group>
            <b-list-group-item
              v-for="item in filteredLeftLimit"
              :key="item[options.leftDisplayField]"
              button
              :style="{ color: options.colorItems || '#1E90FF' }"
              @click.prevent="transferToRight(item)"
            >
              {{ item[options.leftDisplayField] }}&nbsp;&rarr;
            </b-list-group-item>
            <b-list-group-item
              v-if="filteredLeftOverflowCount > 0"
              :style="{ color: options.colorItems || '#1E90FF' }"
            >
              And {{ filteredLeftOverflowCount }} more!
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item
              v-for="item in filteredRightLimit"
              :key="item[options.rightDisplayField]"
              button
              :style="{ color: options.colorItems || '#1E90FF' }"
              @click.prevent="transferToLeft(item)"
            >
              &larr;&nbsp;{{ item[options.rightDisplayField] }}
            </b-list-group-item>
            <b-list-group-item
              v-if="filteredRightOverflowCount > 0"
              :style="{ color: options.colorItems || '#1E90FF' }"
            >
              And {{ filteredRightOverflowCount }} more!
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'DualListFilter',

  props: {
    options: {
      type: Object,
      default: () => null,
      required: true,
    },
  },

  data() {
    return {
      searchLeft: '',
      searchRight: '',
      defaultDisplayLimit: 50,
    };
  },

  computed: {
    filteredLeft() {
      let filtered;

      if (this.searchLeft) {
        const lowered = this.searchLeft.toLowerCase();

        filtered = this.options.items.filter(
          (item) => (item[this.options.searchAttribute].toLowerCase().indexOf(lowered) !== -1),
        );
      } else {
        filtered = this.options.items;
      }

      const field = this.options.leftSortField;

      if (typeof field === 'string') {
        return filtered.sort((a, b) => (b[field] - a[field]));
      }

      return filtered;
    },

    filteredLeftLimit() {
      const limit = this.options.displayLimit || this.defaultDisplayLimit;
      return this.filteredLeft.slice(0, limit);
    },

    filteredLeftOverflowCount() {
      return this.filteredLeft.length - this.filteredLeftLimit.length;
    },

    filteredRight() {
      let filtered;

      if (this.searchRight) {
        const lowered = this.searchRight.toLowerCase();

        filtered = this.options.selectedItems.filter(
          (item) => (item[this.options.searchAttribute].toLowerCase().indexOf(lowered) !== -1),
        );
      } else {
        filtered = this.options.selectedItems;
      }

      const field = this.options.rightSortField;

      if (typeof field === 'string') {
        return filtered.sort((a, b) => (b[field] - a[field]));
      }

      return filtered;
    },

    filteredRightLimit() {
      const limit = this.options.displayLimit || this.defaultDisplayLimit;
      return this.filteredRight.slice(0, limit);
    },

    filteredRightOverflowCount() {
      return this.filteredRight.length - this.filteredRightLimit.length;
    },
  },

  methods: {
    resortItems() {
      this.options.items.sort((a, b) => (a.code - b.code));
      this.options.selectedItems.sort((a, b) => (a.code - b.code));
    },

    transferAllToRight() {
      const toDelete = [];

      // Add all left filtered items to the right
      this.filteredLeft.forEach((item) => {
        this.options.selectedItems.push(item);
        toDelete.push({ ...item });
      });

      // Remove all left filtered items
      toDelete.forEach((item) => {
        const index = this.options.items.map((i) => (i.code)).indexOf(item.code);
        this.options.items.splice(index, 1);
      });

      if (this.filteredLeft.length === 0) {
        this.searchLeft = '';
      }

      // Clear the search, resort the lists, emit the event
      this.resortItems();
      this.$emit('selected-items', this.options.selectedItems);
    },

    transferAllToLeft() {
      const toDelete = [];

      // Add all right filtered items to the left
      this.filteredRight.forEach((item) => {
        this.options.items.push(item);
        toDelete.push({ ...item });
      });

      // Remove all right filtered items
      toDelete.forEach((item) => {
        const index = this.options.selectedItems.map((i) => (i.code)).indexOf(item.code);
        this.options.selectedItems.splice(index, 1);
      });

      // Clear the search, resort the lists, emit the event
      if (this.filteredRight.length === 0) {
        this.searchRight = '';
      }

      this.resortItems();
      this.$emit('selected-items', this.options.selectedItems);
    },

    transferToRight(item) {
      // Add the item to the right side
      this.options.selectedItems.push(item);

      // Remove the item from the left side
      const index = this.options.items.indexOf(item);
      this.options.items.splice(index, 1);

      // Clear the search, resort the lists, emit the event
      if (this.filteredLeft.length === 0) {
        this.searchLeft = '';
      }

      this.resortItems();
      this.$emit('selected-items', this.options.selectedItems);
    },

    transferToLeft(item) {
      // Add the item to the left side
      this.options.items.push(item);

      // Remove the item from the right side
      const index = this.options.selectedItems.indexOf(item);
      this.options.selectedItems.splice(index, 1);

      // Clear the search, resort the lists, emit the event
      if (this.filteredRight.length === 0) {
        this.searchRight = '';
      }

      this.resortItems();
      this.$emit('selected-items', this.options.selectedItems);
    },
  },
};
</script>
