<template>
  <b-card
    id="topicPane"
    no-body
  >
    <b-card-header>
      <b-nav
        id="topic-nav"
        v-b-scrollspy:topic-nav-scroller
        pills
        justified
      >
        <b-nav-item
          link-classes="small-padded-link"
          href="#topicInsights"
          @click.prevent="scrollIntoView"
        >
          Insights
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#topicBenchmarks"
          @click.prevent="scrollIntoView"
        >
          Benchmarks
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#topicTopics"
          @click.prevent="scrollIntoView"
        >
          Topics
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#topicResearchers"
          @click.prevent="scrollIntoView"
        >
          Researchers
        </b-nav-item>
        <b-nav-item
          link-classes="small-padded-link"
          href="#topicOrganisations"
          @click.prevent="scrollIntoView"
        >
          Organisation
        </b-nav-item>
      </b-nav>
    </b-card-header>

    <b-card-body
      id="topic-nav-scroller"
      ref="content"
      style="position:relative; overflow-y:scroll;"
    >
      <h5 id="topicInsights">
        Topic Insights
      </h5>
      <TopicInsights />
      <h5 id="topicBenchmarks">
        <br>
        Topic Benchmarks
      </h5>
      <TopicBenchmarks />
      <h5 id="topicTopics">
        <br>
        Topic Topics
      </h5>
      <TopicTopicSelection />
      <h5 id="topicResearchers">
        <br>
        Topic Researchers
      </h5>
      <TopicResearchers />
      <h5 id="topicOrganisations">
        <br>
        Topic Organisations
      </h5>
      <TopicOrganisations />
      <div class="extra-space" />
    </b-card-body>
  </b-card>
</template>

<style>
#topicPane {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.extra-space {
  min-height: 50vh;
}

#topic-nav {
  padding: 5px 0px 5px 0px;
}

#topic-nav-scroller {
  padding: 5px 20px 5px 20px;
}

.small-padded-link {
  padding: 3px 4px 3px 4px;
}
</style>

<script>
import TopicTopicSelection from '@/components/topic_pane/TopicTopicSelection.vue';
import TopicResearchers from '@/components/topic_pane/TopicResearchers.vue';
import TopicOrganisations from '@/components/topic_pane/TopicOrganisations.vue';
import TopicBenchmarks from '@/components/topic_pane/TopicBenchmarks.vue';
import TopicInsights from '@/components/topic_pane/TopicInsights.vue';

export default {
  name: 'TopicPane',

  components: {
    TopicTopicSelection,
    TopicResearchers,
    TopicBenchmarks,
    TopicInsights,
    TopicOrganisations,
  },

  methods: {
    // Convenience method to scroll a heading into view.
    // Not required for scrollspy to work
    scrollIntoView(evt) {
      const href = evt.target.getAttribute('href');
      const el = href ? document.querySelector(href) : null;
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop;
      }
    },
  },
};
</script>
