<template>
  <b-card
    id="capabilityResearchersCard"
    no-body
  >
    <div
      v-show="topResearchers.length"
      class="overflow-auto"
    >
      <br>
      <b-pagination
        v-model="currentPage"
        :total-rows="topResearchers.length"
        :per-page="perPage"
        aria-controls="capability-researchers-table"
        align="center"
      />

      <p class="text-center">
        Top <b>{{ topResearchers.length }}</b> from total
        <b>{{ capabilityResearchers.length }}</b> Researchers
      </p>
      <b-table
        id="capability-researchers-table"
        striped
        selectable
        select-mode="single"
        sort-by="research_activity_count"
        :sort-desc="true"
        :items="topResearchers"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
        @row-clicked="highlightSelectedResearchActivities"
        @row-hovered="highlightHoveredResearchActivities"
        @row-unhovered="unhighlightHoveredResearchActivities"
      >
        <template v-slot:cell(add)="data">
          <b-btn
            variant="light"
            size="sm"
            @click.stop="addResearcherToQuery(data.item)"
          >
            +
          </b-btn>
        </template>
        <template v-slot:cell(name_and_department)="data">
          {{ data.item.name }}
          <span
            v-if="data.item.honorary_vs_paid === 'honorary'"
            class="employment-status-text"
          >
            (Honorary)
          </span>
          <b-link
            class="right"
            :href="data.item.fae_url"
            target="_blank"
          >
            FAE
          </b-link>
          <div class="department-text">
            {{ data.item.department_name }}
          </div>
        </template>
        <template v-slot:cell(research_activity_count)="data">
          {{ data.item.research_activity_count }}
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<style>
table th {
  display: table-cell;
}

.btn-sm {
  padding: 0.05rem 0.1rem;
}

.employment-status-text {
  font-size: 12px;
}

.department-text {
  font-size: 10px;
}

.right {
  float: right;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'CapabilityResearchers',

  data() {
    return {
      perPage: 15,
      currentPage: 1,
      fields: [
        { key: 'add', label: '', sortable: false },
        { key: 'name_and_department', label: 'Name', sortable: false },
        { key: 'research_activity_count', label: 'Map', sortable: true },
        { key: 'total_research_activity_count', label: 'All', sortable: true },
      ],

      lastSelectedItem: null,
    };
  },

  computed: {
    ...mapState('capability', ['totalResearchActivitiesSupportedFilters']),
    ...mapGetters('capability', { capabilityResearchers: 'filteredResearchers', topResearchers: 'filteredTopResearchers' }),
  },

  mounted() {
    this.$eventBus.$on('filter-analysis', (filtername) => {
      if (this.totalResearchActivitiesSupportedFilters.includes(filtername)) {
        this.extractResearchers();
      }
    });
  },

  methods: {
    ...mapActions('capability', ['extractResearchers']),

    addResearcherToQuery(researcher) {
      const thing = { type: 'researcher', value: researcher.rcm_id, text: researcher.name };
      this.$eventBus.$emit('add-thing-to-query', thing, 'CapabilityResearchers');
    },

    highlightSelectedResearchActivities(item) {
      if (this.lastSelectedItem === item) {
        this.$eventBus.$emit('researcher-deselection');
        this.lastSelectedItem = null;
      } else {
        this.$eventBus.$emit('researcher-selection', item.combinedRcmIds);
        this.lastSelectedItem = item;
      }
    },

    highlightHoveredResearchActivities(item) {
      this.$eventBus.$emit('researcher-hover', item.combinedRcmIds, item, false);
    },

    unhighlightHoveredResearchActivities() {
      this.$eventBus.$emit('researcher-unhover');
    },
  },
};
</script>
