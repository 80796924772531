<template>
  <footer class="footer">
    <span class="text-muted">University of Melbourne &copy; 2020</span>
  </footer>
</template>

<style>
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #f5f5f5;
}
</style>

<script>
export default {
  name: 'AppFooter',
};
</script>
