import axios from 'axios';

function getTopicsFromResearchActivityIds(lingo4gIds) {
  const parameters = [];
  const paramString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
  const url = `${process.env.RCM_API}/api/v1/query/topics${paramString}`;

  return axios.post(url, { lingo4gIds }, { withCredentials: true });
}

// eslint-disable-next-line import/prefer-default-export
export { getTopicsFromResearchActivityIds };
