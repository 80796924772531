<template>
  <div id="indexPage">
    <AppHeader />
    <b-container fluid>
      <b-row>
        &nbsp;
      </b-row>
      <b-row id="contentRow">
        <b-col
          id="leftPaneColumn"
          :cols="leftPaneColumns"
        >
          <QueryPane v-show="queryPaneExpanded" />
          <MiniQueryPane v-show="!queryPaneExpanded" />
        </b-col>
        <b-col
          id="middlePaneColumn"
          :cols="middlePaneColumns"
          style="padding-left: 0px; padding-right: 0px;"
        >
          <VisualisationPane />
        </b-col>
        <b-col
          id="rightPaneColumn"
          :cols="rightPaneColumns"
        >
          <CapabilityPane v-show="capabilityPaneSelected" />
          <TopicPane v-show="topicPaneSelected" />
        </b-col>
      </b-row>
    </b-container>
    <SavedQueriesModal />
    <SaveQueryModal />
    <DetailedInformationModal />
    <AppFooter />
  </div>
</template>

<style>
#contentRow {
  height: 75vh;
}

.leftMiniPaneClass {
  padding-left: 0px;
}

#paneSelectionButtons {
  display: block;
  text-align: right;
  padding: 5px;
}
</style>

<script>
import _ from 'lodash';
import parse from 'url-parse';
import {
  mapState, mapActions, mapMutations,
} from 'vuex';

import AppHeader from '@/components/common/AppHeader.vue';
import AppFooter from '@/components/common/AppFooter.vue';

import QueryPane from '@/components/QueryPane.vue';
import MiniQueryPane from '@/components/MiniQueryPane.vue';

import VisualisationPane from '@/components/VisualisationPane.vue';
import TopicPane from '@/components/TopicPane.vue';
import CapabilityPane from '@/components/CapabilityPane.vue';

import SavedQueriesModal from '@/components/overlays/SavedQueriesModal.vue';
import SaveQueryModal from '@/components/overlays/SaveQueryModal.vue';
import DetailedInformationModal from '@/components/overlays/DetailedInformationModal.vue';

export default {
  name: 'IndexPage',

  components: {
    AppHeader,
    AppFooter,

    QueryPane,
    MiniQueryPane,

    VisualisationPane,

    TopicPane,
    CapabilityPane,

    SavedQueriesModal,
    SaveQueryModal,
    DetailedInformationModal,
  },

  data() {
    return {
      debugParameters: {},
      leftPaneColumns: 3,
      middlePaneColumns: 6,
      rightPaneColumns: 3,

      queryPaneExpanded: true,
    };
  },

  computed: {
    ...mapState('request', ['mapRequestInProgress']),
    ...mapState('misc', ['capabilityPaneSelected', 'topicPaneSelected']),
  },

  watch: {
    mapRequestInProgress(status) {
      if (status === true) {
        this.hideBothPanes();
      } else {
        this.showCapabilityPane();
      }
    },
  },

  // This area is for performing 'inital actions' when the user opens RCM
  beforeMount() {
    const url = parse(window.location.href, true);

    if (_.has(url.query, 'query')) {
      sessionStorage.setItem('queryId', url.query.query);

      delete url.query.query;
      window.history.replaceState({}, document.title, url.toString());
    }

    if (process.env.COGNITO_USER_POOL_ID !== '') {
      // User is running in AWS
      this.authenticateCognito();
    } else {
      // User is running locally, assume API is authenticated
      this.setApiAuthenticated(true);

      // Trigger loading the query
      this.loadSavedQuery();
    }
  },

  mounted() {
    this.$eventBus.$on('request-analysis', () => {
      this.triggerAnalysis();
    });

    this.$eventBus.$on('debug-update', (parameter) => {
      if (parameter.value === null) {
        delete this.debugParameters[parameter.name];
      } else {
        this.$set(this.debugParameters, parameter.name, parameter.value);
      }
    });

    this.$eventBus.$on('topic-selection', () => this.showTopicPane());

    this.$eventBus.$on('topic-deselection', () => this.topicPaneSelected && this.showCapabilityPane());
    this.$eventBus.$on('both-pane-deselection', () => this.hideBothPanes());

    this.$eventBus.$on('toggle-query-pane', () => this.toggleQueryPane());
  },

  methods: {
    ...mapActions('user', ['authenticateCognito', 'loadSavedQuery']),
    ...mapActions('request', ['requestAnalysis']),
    ...mapMutations('misc', ['showCapabilityPane', 'showTopicPane', 'hideBothPanes']),
    ...mapMutations('user', ['setApiAuthenticated']),

    triggerAnalysis() {
      const parametersList = [];
      Object.entries(this.debugParameters).forEach(([key, value]) => {
        if (key !== null && value !== null) {
          parametersList.push(`${key}=${value}`);
        }
      });

      this.requestAnalysis({
        mapParameters: parametersList,
        capabilityParameters: parametersList,
      });
    },

    toggleQueryPane() {
      this.queryPaneExpanded = !this.queryPaneExpanded;

      const leftPaneColumn = document.getElementById('leftPaneColumn');
      leftPaneColumn.classList.toggle('leftMiniPaneClass');

      if (this.queryPaneExpanded) {
        this.leftPaneColumns = 3;
        this.middlePaneColumns -= 2;
      } else {
        this.leftPaneColumns = 1;
        this.middlePaneColumns -= 2;
      }

      this.$nextTick(() => {
        this.$eventBus.$emit('pane-layout-changed');
      });
    },
  },
};
</script>
