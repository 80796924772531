function newQueryGroupItem(thing, operator = null) {
  const queryGroupItem = {
    type: thing.type,
    status: 'new',
    value: thing.value,
    operator,
  };

  if (operator !== null) {
    queryGroupItem.text = `${operator} ${thing.text}`;
  } else {
    queryGroupItem.text = thing.text;
  }

  return queryGroupItem;
}

function newQueryGroup(items, boost = 1, operator = 'OR') {
  const queryGroup = {
    boost,
    items,
    researchActivityIds: [],
    operator,
  };

  queryGroup.items.indexOf = function mapIndexOf(value) {
    let foundIndex = -1;

    this.some((item, index) => {
      if (item.text === value) {
        foundIndex = index;
        return true;
      }
      return false;
    });

    return foundIndex;
  };

  return queryGroup;
}

export {
  newQueryGroupItem,
  newQueryGroup,
};
