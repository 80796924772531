<template>
  <b-card
    id="capabilityOrganisationsCard"
    no-body
  >
    <div
      v-show="topOrganisations.length"
      class="overflow-auto"
    >
      <br>
      <b-pagination
        v-model="currentOrgPage"
        :total-rows="topOrganisations.length"
        :per-page="perPageOrg"
        aria-controls="capability-organisations-table"
        align="center"
      />

      <p class="text-center">
        Top <b>{{ topOrganisations.length }}</b> from total
        <b>{{ capabilityOrganisations.length }}</b> Organisations
      </p>
      <b-table
        id="capability-organisations-table"
        striped
        selectable
        select-mode="single"
        sort-by="organisation_activity_count"
        :sort-desc="true"
        :items="topOrganisations"
        :fields="fieldsOrg"
        :per-page="perPageOrg"
        :current-page="currentOrgPage"
        small
        @row-clicked="highlightSelectedResearchActivities"
        @row-hovered="highlightHoveredResearchActivities"
        @row-unhovered="unhighlightHoveredResearchActivities"
      >
        <template v-slot:cell(add)="data">
          <b-btn
            variant="light"
            size="sm"
            @click.stop="addOrganisationToQuery(data.item)"
          >
            +
          </b-btn>
        </template>
        <template v-slot:cell(name)="data">
          {{ data.item.name }}
          <b-link
            v-if="data.item.links"
            class="right"
            :href="data.item.links[0]"
            target="_blank"
          >
            URL
          </b-link>
          <div
            v-if="data.item.types"
            class="department-text"
          >
            {{ data.item.types[0] }}
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<style>
table th {
  display: table-cell;
}

.btn-sm {
  padding: 0.05rem 0.1rem;
}

.employment-status-text {
  font-size: 12px;
}

.department-text {
  font-size: 10px;
}

.right {
  float: right;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'CapabilityOrganisations',

  data() {
    return {
      perPageOrg: 15,
      totalPages: 4,
      currentOrgPage: 1,
      // topOrganisations: json,
      fieldsOrg: [
        { key: 'add', label: '', sortable: false },
        { key: 'name', label: 'Name', sortable: false },
        { key: 'organisation_activity_count', label: 'Map', sortable: true },
        // { key: 'total_organisation_activity_count', label: 'All', sortable: true },
      ],

      lastSelectedItem: null,
    };
  },

  computed: {
    ...mapState('capability', ['totalResearchActivitiesSupportedFilters']),
    ...mapGetters('capability', { capabilityOrganisations: 'filteredOrganisations', topOrganisations: 'filteredTopOrganisations' }),
  },

  mounted() {
    this.$eventBus.$on('filter-analysis', (filtername) => {
      if (this.totalResearchActivitiesSupportedFilters.includes(filtername)) {
        this.extractOrganisations();
      }
    });
  },

  methods: {
    ...mapActions('capability', ['extractOrganisations']),

    addOrganisationToQuery(organisation) {
      const thing = { type: 'organisation', value: organisation.rcm_id, text: organisation.name };
      this.$eventBus.$emit('add-thing-to-query', thing, 'CapabilityOrganisations');
    },

    highlightSelectedResearchActivities(item) {
      if (this.lastSelectedItem === item) {
        this.$eventBus.$emit('organisation-deselection');
        this.lastSelectedItem = null;
      } else {
        this.$eventBus.$emit('organisation-selection', item.combinedRcmIds);
        this.lastSelectedItem = item;
      }
    },

    highlightHoveredResearchActivities(item) {
      this.$eventBus.$emit('organisation-hover', item.combinedRcmIds, item, false);
    },

    unhighlightHoveredResearchActivities() {
      this.$eventBus.$emit('organisation-unhover');
    },
  },
};
</script>
