<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark"
    style="background-color: #094183"
  >
    <a class="navbar-brand">
      <img src="/favicon.ico"> <span class="title-text">Research Capability Mapping</span>
    </a>
    <div
      class="button-holder"
    >
      <b-button
        variant="primary"
        class="horizontal-padded nav-button"
        :pressed="mapVisualisationSelected"
        @click="showMapVisualisation"
      >
        Map
      </b-button>
      <b-button
        variant="primary"
        class="horizontal-padded nav-button"
        :pressed="networkVisualisationSelected"
        :disabled="networkDisabled"
        @click="showNetworkVisualisation"
      >
        Network
      </b-button>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <b-navbar-nav class="ml-auto">
      <div
        v-show="capabilityPaneSelected || topicPaneSelected"
        style="text-align: center;"
      >
        <b-button
          size="sm"
          class="horizontal-padded"
          type="submit"
          variant="primary"
          @click="requestSimilarActivitiesWithStatus('more')"
        >
          <b-spinner
            v-show="similarActivitiesInProgress"
            small
            type="grow"
          />
          More
        </b-button>
        <span v-show="topicPaneSelected">
          <span class="title-text">or</span>
          <b-button
            size="sm"
            class="horizontal-padded"
            type="submit"
            variant="primary"
            @click="requestSimilarActivitiesWithStatus('less')"
          >
            <b-spinner
              v-show="similarActivitiesInProgress"
              small
              type="grow"
            />
            Less
          </b-button>
        </span>
        <br>
        <div class="small-text title-text">
          Activities like these
        </div>
      </div>
      &nbsp;&nbsp;&nbsp;
      <b-button
        variant="primary"
        @click="showSavedQueriesOverlay"
      >
        &#128193;
      </b-button>
      &nbsp;
      <b-button
        variant="primary"
        @click="showSaveQueryOverlay"
      >
        &#128190;
      </b-button>
      &nbsp;
      <b-button
        variant="primary"
        @click="showDetailedInformationOverlay"
      >
        &#9432;
      </b-button>
    </b-navbar-nav>
  </nav>
</template>

<style>
.small-text {
  font-size: 0.9em;
  line-height: 15px;
}

.button-holder {
  padding-left: 25px;
}

.nav-button {
  min-width: 105px;
}

.title-text {
  color: white;
}
</style>

<script>
import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';

export default {
  name: 'AppHeader',

  data() {
    return {
      similarStatus: '',
      networkDisabled: true,
    };
  },

  computed: {
    ...mapState('request', ['similarActivitiesInProgress']),
    ...mapState('misc', [
      'capabilityPaneSelected',
      'topicPaneSelected',
      'networkVisualisationSelected',
      'mapVisualisationSelected',
    ]),

    ...mapGetters('capability', {
      capabilityActivityIds: 'filteredResearchActivityIds',
      capabilityResearchers: 'filteredResearchers',
      capabilityOrganisations: 'filteredOrganisations',
    }),

    ...mapState('capability', {
      capabilitySimilarActivityIds: 'similarResearchActivityIds',
      capabilitySimilarActivityLabels: 'similarResearchActivityLabels',
    }),

    ...mapState('topic', {
      topicSimilarActivityIds: 'similarResearchActivityIds',
      topicSimilarActivityLabels: 'similarResearchActivityLabels',
    }),

    ...mapGetters('topic', {
      topicActivityIds: 'filteredResearchActivityIds',
    }),
  },

  watch: {
    /**
     * Watch the capability Activities and enable the network tab
     * when there is data available
     */
    capabilityResearchers() {
      this.networkDisabled = !(this.capabilityResearchers
        && this.capabilityResearchers.length > 0);
    },

    capabilityOrganisations() {
      this.networkDisabled = !(this.capabilityOrganisations
        && this.capabilityOrganisations.length > 0);
    },

    capabilitySimilarActivityIds() {
      if (this.capabilitySimilarActivityIds.length > 0) {
        const thing = {
          type: 'rcmIds',
          text: (this.capabilitySimilarActivityLabels.slice(0, 2).join(', ')).concat('...'),
          value: this.capabilitySimilarActivityIds,
        };

        this.$eventBus.$emit('add-thing-to-query', thing);
      }

      this.similarStatus = '';
    },

    topicSimilarActivityIds() {
      if (this.similarStatus === 'more') {
        if (this.topicSimilarActivityIds.length > 0) {
          const thing = {
            type: 'rcmIds',
            text: (this.topicSimilarActivityLabels.slice(0, 2).join(', ')).concat('...'),
            value: this.topicSimilarActivityIds,
          };

          this.$eventBus.$emit('add-thing-to-query', thing);
        }
      } else if (this.similarStatus === 'less') {
        const topicData = {
          action: 'less',
          labels: this.topicSimilarActivityLabels,
          docs: this.topicActivityIds.concat(this.topicSimilarActivityIds),
        };

        this.$eventBus.$emit('topic-filter', topicData);
      }

      this.similarStatus = '';
    },
  },

  methods: {
    ...mapActions('request', ['requestSimilarActivities']),

    ...mapMutations('misc', [
      'showNetworkVisualisation',
      'showMapVisualisation',
    ]),

    showSavedQueriesOverlay() {
      this.$eventBus.$emit('show-saved-queries-overlay');
    },

    showSaveQueryOverlay() {
      this.$eventBus.$emit('show-save-query-overlay');
    },

    showDetailedInformationOverlay() {
      this.$eventBus.$emit('show-detailed-information-overlay');
    },

    requestSimilarActivitiesWithStatus(status) {
      const activityPayload = {};

      if (this.capabilityPaneSelected) {
        activityPayload.activityIds = this.capabilityActivityIds;
        activityPayload.requestType = 'capability';
      } else if (this.topicPaneSelected) {
        activityPayload.activityIds = this.topicActivityIds;
        activityPayload.requestType = 'topic';
      } else {
        // Dunno what the user did, but we don't care
        return;
      }

      this.similarStatus = status;
      this.requestSimilarActivities(activityPayload);
    },
  },
};
</script>
