<template>
  <div
    id="map-visualisation"
    ref="mapVisualisation"
  >
    <HelpText v-show="dotatlas === null" />
    <div
      id="dotatlas"
      style="width: 100%; height: 100%; position: relative;"
    />
  </div>
</template>

<style>
#map-visualisation {
  position:relative;
  height: 45vw;
}
</style>

<script>
import _ from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';

import HelpText from '@/components/visualisation_pane/HelpText.vue';

import { DotAtlas } from '@carrotsearch/dotatlas';
import { Effects } from '@carrotsearch/dotatlas/effects';
import { AutoResizing } from '@carrotsearch/dotatlas/autoresizing';
import { Lingo4GDocumentMap } from '@carrotsearch/dotatlas/lingo4g';
import { Selection } from '@carrotsearch/dotatlas/selection';

export default {
  name: 'MapVisualisation',
  components: {
    HelpText,
  },

  data() {
    return {
      dotatlas: null,
    };
  },

  computed: {
    ...mapGetters('map', ['filteredMapData']),
    ...mapState('result', ['rcm2l4g', 'l4g2rcm']),
    ...mapState('misc', ['mapVisualisationSelected']),
  },

  watch: {
    filteredMapData(value) {
      if (value && this.$refs.mapVisualisation
        && this.$refs.mapVisualisation.clientHeight > 0
        && this.$refs.mapVisualisation.clientHeight > 0) {
        this.showMap();
      }
    },

    mapVisualisationSelected() {
      if (this.mapVisualisationSelected && this.filteredMapData) {
        this.showMap();
      }
    },
  },

  mounted() {
    this.$eventBus.$on('reset', () => {
      // Remove the map
      this.dotatlas.dispose();

      const container = document.getElementById('dotatlas');

      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      this.dotatlas = null;
    });

    this.$eventBus.$on('pane-layout-changed', () => {
      // Check that the map is actually displayed before reflow
      if (this.$refs.mapVisualisation
          && this.$refs.mapVisualisation.clientHeight > 0
          && this.$refs.mapVisualisation.clientHeight > 0) {
        this.reflowMap();
      }
    });

    this.$eventBus.$on(['topic-selection', 'researcher-selection'], (rcmIds) => this.highlightResearchActivities(this.translateIds(rcmIds), 'select'));

    this.$eventBus.$on(['topic-deselection', 'researcher-deselection'], () => this.unhighlightResearchActivities('select'));

    this.$eventBus.$on(['topic-selection', 'organisation-selection'], (rcmIds) => this.highlightResearchActivities(this.translateIds(rcmIds), 'select'));

    this.$eventBus.$on(['topic-deselection', 'organisation-deselection'], () => this.unhighlightResearchActivities('select'));

    this.$eventBus.$on(['topic-hover', 'researcher-hover'], (rcmIds) => {
      this.highlightResearchActivities(this.translateIds(rcmIds), 'hover');
    });
    this.$eventBus.$on(['topic-unhover', 'researcher-unhover'], () => this.unhighlightResearchActivities('hover'));

    this.$eventBus.$on(['topic-hover', 'organisation-hover'], (rcmIds) => {
      this.highlightResearchActivities(this.translateIds(rcmIds), 'hover');
    });
    this.$eventBus.$on(['topic-unhover', 'organisation-unhover'], () => this.unhighlightResearchActivities('hover'));
  },

  methods: {
    ...mapActions('topic', ['topicSelection']),

    translateIds(rcmIds) {
      return rcmIds.map((rcmId) => this.rcm2l4g.get(Number(rcmId)));
    },

    highlightResearchActivities(lingo4gIds, layer) {
      if (this.dotatlas === null) {
        console.log('Nothing to highlight, no map present'); // eslint-disable-line no-console
        return false;
      }

      const points = this.dotatlas.get('lingo4g:layer:markers').get('points').filter((marker) => lingo4gIds.includes(marker.id));

      let outline;
      let markers;

      if (layer === 'hover') {
        outline = this.dotatlas.get('selection:layer:hoverOutline');
        markers = this.dotatlas.get('selection:layer:hoverMarkers');
      } else if (layer === 'select') {
        outline = this.dotatlas.get('selection:layer:selectionOutline');
        markers = this.dotatlas.get('selection:layer:selectionMarkers');
      } else {
        console.log('Unknown layer selected'); // eslint-disable-line no-console
        return false;
      }

      outline.set('points', points);
      markers.set('points', points);
      this.dotatlas.redraw();

      return true;
    },

    unhighlightResearchActivities(layer) {
      if (this.dotatlas === null) {
        console.log('Nothing to unhighlight, no map present'); // eslint-disable-line no-console
        return false;
      }

      let outline;
      let markers;

      if (layer === 'hover') {
        outline = this.dotatlas.get('selection:layer:hoverOutline');
        markers = this.dotatlas.get('selection:layer:hoverMarkers');
      } else if (layer === 'select') {
        outline = this.dotatlas.get('selection:layer:selectionOutline');
        markers = this.dotatlas.get('selection:layer:selectionMarkers');
      } else {
        console.log('Unknown layer selected'); // eslint-disable-line no-console
        return false;
      }

      outline.set('points', []);
      markers.set('points', []);
      this.dotatlas.redraw();

      return true;
    },

    reflowMap() {
      if (this.dotatlas !== null) {
        this.$nextTick(() => {
          this.dotatlas.resize();
          this.dotatlas.get('lingo4g:layer:labels').update('labelVisibilityScales');
          this.dotatlas.redraw();
        });
      }
    },

    showMap() {
      const vm = this;

      // Functions for dealing with Lingo4G's result internally
      function calculateMapData(lingo4gResult, context) {
        // Build up a documentId => size mapping
        // Build up a documentId => doc_type mapping

        const sizeMapping = new Map();
        const docTypeMapping = new Map();

        let sizeMaximum = 1;

        lingo4gResult.documents.list
          .filter((document) => _.has(document, 'content'))
          .forEach((document) => {
            const contentSize = document.content.filter((content) => (content.name === 'size'));

            if (contentSize.length === 1) {
              const contentSizeValue = contentSize[0].values[0];
              sizeMapping.set(document.id, contentSizeValue);

              if (contentSizeValue > sizeMaximum) {
                sizeMaximum = contentSizeValue;
              }
            }

            const contentDocType = document.content.filter((content) => (content.name === 'doc_type'));

            if (contentDocType.length === 1) {
              const contentDocTypeValue = contentDocType[0].values[0];
              docTypeMapping.set(document.id, contentDocTypeValue);
            }
          });

        context.sizeMapping = sizeMapping; // eslint-disable-line no-param-reassign
        context.sizeMaximum = sizeMaximum; // eslint-disable-line no-param-reassign

        context.docTypeMapping = docTypeMapping; // eslint-disable-line no-param-reassign
      }

      function applyMapData(layers, context) {
        layers.markers.get('points').forEach((point) => {
          const pointSize = context.sizeMapping.get(point.id);
          const markerImpactScore = pointSize ** 0.20;
          const markerTransparency = Math.sqrt(pointSize);

          // Scale of 0 => 1
          point.markerSize = markerImpactScore; // eslint-disable-line no-param-reassign

          // UoM Blue (https://brandhub.unimelb.edu.au/guidelines/colour-palette)
          // Transparency is a scale of 0 => 255
          let color;
          const researchActivityType = context.docTypeMapping.get(point.id);

          if (researchActivityType === 'publication') {
            color = [9, 65, 131, markerTransparency];
          } else if (researchActivityType === 'project') {
            color = [191, 0, 0, markerTransparency];
          } else if (researchActivityType === 'patent') {
            color = [191, 0, 0, markerTransparency];
          } else if (researchActivityType === 'clinical_trial') {
            color = [191, 0, 0, markerTransparency];
          } else {
            console.log('showMap unknown research activity type', researchActivityType); // eslint-disable-line no-console
          }

          point.markerColor = color; // eslint-disable-line no-param-reassign
        });
      }

      // Dealing with a 'click' on the map
      function updateSelection(e) {
        if (e.points.length > 0) {
          const rcmIds = e.points
            .map((point) => vm.l4g2rcm.get(point.id));

          // The 3rd parameter specifies that topic selection is emitted from clicking
          // on a cluster on the map, ie. fromMap=true
          vm.topicSelection({ rcmIds, sourceObject: e.points, source: 'MapVisualisation' });
          vm.$eventBus.$emit('topic-selection', rcmIds, e.points, true);
        } else {
          vm.$eventBus.$emit('topic-deselection');
        }
      }

      // If we have an existing visualisation, dispose of the current visualisation
      if (vm.dotatlas !== null) {
        vm.dotatlas.dispose();
      }

      // Initialize dotAtlas
      const container = document.getElementById('dotatlas');

      // Clear out any child elements (mainly the default img)
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      vm.dotatlas = DotAtlas
        .with(AutoResizing, Effects, Selection, Lingo4GDocumentMap)
        .embed({
          element: container,
          pixelRatio: window.devicePixelRatio || 1,
          'lingo4g:result': this.filteredMapData,
          'lingo4g:customize:context': calculateMapData,
          'lingo4g:customize:layers': applyMapData,
          'selection:onSelectionChanged': (e) => updateSelection(e),
        });
    },
  },
};
</script>
